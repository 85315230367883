import { Map, fromJS, List } from 'immutable';
import { actionTypes } from 'redux-form';
import { v4 } from 'uuid';

import { appTypes } from 'consts';

export const initialState = Map({
  clientVersion: null,
  defaultSyncParams: Map(),
  embedName: '',
  isLoading: false,
  sessionId: v4(),
  selectedOrganizationId: null,
  scripts: List(),
  scriptOutput: null,
  productNameContext: appTypes.PRODUCT_NAMES.PROJECT_SYNC,
  siteadminSearchString: '',
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case appTypes.GET_QUERY_PARAMETERS: {
      return state.set('defaultSyncParams', fromJS(action.payload));
    }

    case appTypes.GET_IS_EMBED: {
      return state.set('embedName', action.payload);
    }

    case actionTypes.DESTROY: {
      // When canceling or submitting the sync form, remove the default sync parameters
      // Keep them for embedMode as the where tab is always hidden
      const isEmbed = !!state.get('embedName');
      const forms = action.meta.form || [];
      if (forms.includes('syncForm') && !isEmbed) {
        return state.set('defaultSyncParams', Map());
      }
      return state;
    }

    case appTypes.GET_APP_CONFIG_REQUEST:
      return state.merge({
        isLoading: true,
      });

    case appTypes.GET_APP_CONFIG_SUCCESS: {
      const { appVersion } = action.payload;

      return state.merge({
        clientVersion: appVersion,
        isLoading: false,
      });
    }

    case appTypes.GET_APP_CONFIG_FAILURE: {
      return state.merge({
        isLoading: false,
      });
    }

    case appTypes.SET_SELECTED_ORGANIZATION: {
      return state.set('selectedOrganizationId', action.payload);
    }

    case appTypes.GET_SCRIPTS_SUCCESS: {
      return state.set('scripts', fromJS(action.payload.scriptDefinitions));
    }

    case appTypes.RUN_SCRIPT_SUCCESS: {
      return state.set('scriptOutput', action.payload.message);
    }

    case appTypes.RUN_SCRIPT_FAILURE: {
      return state.set('scriptOutput', action.error.message);
    }

    case appTypes.CLEAR_SCRIPT_OUTPUT: {
      return state.set('scriptOutput', '');
    }

    case appTypes.SET_PRODUCT_NAME_CONTEXT: {
      return state.set('productNameContext', action.payload.productName);
    }

    case appTypes.UPDATE_EMBED_LOCALLY: {
      return state.set('embedName', action.payload);
    }

    case appTypes.SET_SITEADMIN_SEARCH_STRING_LOCALLY: {
      return state.set('siteadminSearchString', action.payload);
    }

    default: {
      return state;
    }
  }
};

export const getEmbedName = (state) => state.get('embedName');
export const getClientVersion = (state) => state.get('clientVersion');
export const getSessionId = (state) => state.get('sessionId');

export const getDefaultParamProviderId = (state, side) => {
  const config = state.get('defaultSyncParams');
  return side === 'A' ? config?.get('providerId', '') : config?.get('otherProviderId', '');
};

// TODO clean this up used by multisync only
export const getDefaultParamProfileId = (state, side) => {
  const config = state.get('defaultSyncParams');
  return side === 'A' ? config?.get('profileId', '') : null;
};

// TODO clean this up used by multisync only
export const getDefaultParamContainerId = (state, containerSide) => {
  const config = state.get('defaultSyncParams');
  // the _setupRedirect endpoint doesn't return a otherContainerId because we don't need it for now, but maybe one day? Who knows? I don't
  // The line below is equivalent to: return containerSide === 'A' ? config.get('containerId') : undefined.
  // Doing the ternary here instead of in the components calling the reducers as this should be hidden implementation.
  return config.get(containerSide === 'A' ? 'containerId' : 'otherContainerId');
};

export const getDefaultParamAccountId = (state) => {
  const config = state.get('defaultSyncParams');
  return config?.get('accountId', null);
};

export const getIsLoading = (state) => state.get('isLoading');

export const getSelectedOrganizationId = (state) => state.get('selectedOrganizationId');

export const getScripts = (state) => state.get('scripts');

export const getScriptOutput = (state) => state.get('scriptOutput');

export const getSiteadminSearchString = (state) => state.get('siteadminSearchString');

export const getProductNameContext = (state) => state.get('productNameContext');
