import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { errorTypes, trackingTypes } from 'consts';
import { getContainerById, getLinkById } from 'reducers';

export const useSetContainersErrors = (formData, setError, clearErrors) => {
  const { linkId } = useParams();
  const { providerIdentityId: providerIdentityIdA, containerId: containerIdA } = formData.A;
  const { providerIdentityId: providerIdentityIdB, containerId: containerIdB } = formData.B;

  const link = useSelector((state) => getLinkById(state, linkId));
  const containerA = useSelector((state) => getContainerById(state, providerIdentityIdA, containerIdA));
  const containerB = useSelector((state) => getContainerById(state, providerIdentityIdB, containerIdB));

  const containerTypeA = containerA?.getIn(['containerType', 'displayName']);
  const containerTypeB = containerB?.getIn(['containerType', 'displayName']);

  const containerDisplayNameA = containerA?.get('displayName', link.getIn(['A', 'container', 'displayName']));
  const containerDisplayNameB = containerB?.get('displayName', link.getIn(['B', 'container', 'displayName']));

  return useCallback(
    (errors) => {
      const pageName = trackingTypes.MODULE.TOOL_SELECTION;

      ['A', 'B'].forEach((side) => {
        if (!errors[side]) {
          clearErrors(`${pageName}.${side}`);
        } else {
          const { message, name: errorName, type: errorType } = errors[side];
          const containerType = side === 'A' ? containerTypeA : containerTypeB;
          const containerDisplayName = side === 'A' ? containerDisplayNameA : containerDisplayNameB ?? '';

          const isAuthError = [
            errorTypes.API_ERROR_TYPES.AUTHENTICATION,
            errorTypes.API_ERROR_TYPES.UNAUTHORIZED,
          ].includes(errors[side].type);
          const errorFieldKey = isAuthError ? `providerIdentityId` : `containerId`;
          const detailedError = `${
            containerType ? `${containerType} ` : ''
          }${containerDisplayName} encountered an error: ${message}`;
          const error = isAuthError ? message : detailedError;
          setError(`${pageName}.${side}.${errorFieldKey}`, {
            type: 'manual',
            errorName,
            errorType,
            error,
          });
        }
      });
    },
    [containerDisplayNameA, containerDisplayNameB, containerTypeA, containerTypeB, clearErrors, setError],
  );
};
