import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button as MimicsButton, tokens } from '@unitoio/mosaic';

import { workflowActions } from 'actions';
import { featureTypes, trackingTypes } from 'consts';
import { getSelectedOrganizationId, isOrganizationWorkflowLimitReached } from 'reducers';
import { FeatureLimitTooltip } from '~/components/FeatureFlag/FeatureLimitTooltip';

import { trackableHoC } from '../../containers/TrackableHoC/TrackableHoC';

const { FEATURES } = featureTypes;

const CreateWorkflowButtonComponent = ({ trackEvent, trackingActionName = null }) => {
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const isWorkflowLimitReached = useSelector((state) =>
    isOrganizationWorkflowLimitReached(state, selectedOrganizationId),
  );
  return (
    <Box m={[0, 0, 0, tokens.spacing.s2]}>
      <MimicsButton
        variant="primary"
        startIcon="plus"
        disabled={isCreatingWorkflow || isWorkflowLimitReached}
        onClick={() => {
          const actionName = trackingActionName || 'clicked on create a new workflow button';
          const params = {
            action_name: actionName,
          };
          dispatch(workflowActions.createWorkflow(setIsCreatingWorkflow, history, selectedOrganizationId));
          trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME, params);
        }}
      >
        Create workflow
        {isWorkflowLimitReached && ' '}
        {isWorkflowLimitReached && <FeatureLimitTooltip feature={FEATURES.WORKFLOWS} />}
      </MimicsButton>
    </Box>
  );
};

CreateWorkflowButtonComponent.propTypes = {
  trackEvent: PropTypes.func.isRequired,
  trackingActionName: PropTypes.string,
};

export const CreateWorkflowButton = trackableHoC(CreateWorkflowButtonComponent);
