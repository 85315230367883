import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import { Box, Typography, Button, tokens, NewTag, Radio, NewTagColor } from '@unitoio/mosaic';
import { getFlags } from 'reducers';
import { organizationActions } from 'actions';
import { SelectField } from '~/components/SelectField/SelectField';

const StyledForm = styled.form`
  display: flex;
`;

export const FeatureFlagOverride = ({ organizationId }) => {
  const dispatch = useDispatch();

  const formMethods = useForm();
  const { handleSubmit, watch, register, setValue } = formMethods;

  const featureFlags = useSelector((state) => getFlags(state, organizationId));

  const onSubmit = (data) => {
    dispatch(organizationActions.updateFlagsLocally(organizationId, { [data.flag]: data['flag-value'] }));
  };

  const selectedFlag = watch('flag');
  const selectedFlagValue = watch('flag-value');
  const selectedFlagType = typeof featureFlags.get(selectedFlag);
  const options = featureFlags
    .map((value, key) => ({
      value: key,
      label: key,
    }))
    .toList()
    .toJS();

  const handleChangeFlag = (newValue) => {
    setValue('flag-value', featureFlags.get(newValue), { shouldDirty: true });
  };

  const renderByType = () => {
    // TODO : handle funkier featureflag (one of them is a List I think)
    if (selectedFlagType === 'boolean') {
      return (
        <Box justifyContent="flex-start" m={[0, 0, tokens.spacing.s3, 0]}>
          <Radio.Group
            name="flag-value"
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: 'true', value: true },
              { label: 'false', value: false },
            ]}
          />
        </Box>
      );
    }
    if (['string', 'number'].includes(selectedFlagValue)) {
      return (
        <Box m={[0, 0, tokens.spacing.s3, 0]}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...register('flag-value')} />
        </Box>
      );
    }
    return null;
  };
  return (
    <Box m={[tokens.spacing.s4, 0, 0, 0]}>
      <Typography variant="h3">
        Override feature flag <NewTag color={NewTagColor.INFO}>local</NewTag>
      </Typography>

      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Box m={[0, 0, tokens.spacing.s3, 0]}>
              <SelectField
                searchable
                name="flag"
                placeholder="Feature flag"
                options={options}
                onChange={handleChangeFlag}
              />
            </Box>
            {renderByType()}
            <Button disabled={!selectedFlag} type="submit">
              Override
            </Button>
          </div>
        </StyledForm>
      </FormProvider>
    </Box>
  );
};

FeatureFlagOverride.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
