import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Alert,
  NewRow,
  Box,
  Button,
  Icon,
  LoadingPlaceholder,
  Radio,
  tokens,
  Tooltip,
  NewCol,
  Flex,
} from '@unitoio/mosaic';

import { fieldTypes, linkTypes, trackingTypes, routes } from 'consts';
import {
  getLinkContainerBySide,
  getLinkProviderNameBySide,
  getProviderByName,
  getAreProviderCapabilitiesForItemsLoaded,
} from 'reducers';
import { useTrackEvent, useGetItemTypes } from 'hooks';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

import * as formUtils from '../utils/form';
import { PageContainer } from './PageContainer/PageContainer';
import { FlowContainerInfo } from '../components/FlowContainerInfo';
import { PageHeader } from '../components/PageHeader';

import TwoWaySyncImg from '../images/twoWaySync.svg';
import LeftWaySyncImg from '../images/leftWaySync.svg';
import RightWaySyncImg from '../images/rightWaySync.svg';
import { useGetProvidersCanCreateWorkItem } from '../hooks/useGetProvidersCanCreateWorkItem';
import { useGetContainerTypes } from '../hooks/useGetContainerTypes';
import { DuplicateBanner } from '../components/DuplicateBanner';
import { FlowDirectionSidePanel } from './SidePanels/FlowDirectionSidePanel';

const Container = styled(Box)`
  border: ${tokens.spacing.s1} solid ${tokens.colors.content.neutral.n10};
  border-radius: ${tokens.spacing.s4};
  margin-bottom: ${tokens.spacing.s5};
  padding: ${tokens.spacing.s6};
`;

const ImgWrapper = styled.img`
  width: 100%;
`;

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

const DirectionImg = {
  A: LeftWaySyncImg,
  both: TwoWaySyncImg,
  B: RightWaySyncImg,
};

const TooltipContent = ({
  flowDirectionTarget,
  target,
  providerNameA,
  itemTypeA,
  containerTypeA,
  providerNameB,
  itemTypeB,
  containerTypeB,
  linkId,
}) => {
  // TODO: Ask designers if we should bold the container names in the tooltip
  const taskTermProviderA = (
    <ProviderTermsByName
      providerNameA={providerNameA}
      plurality="plural"
      termKey="task"
      textTransform="capitalize"
      pcdv3
      itemTypeA={itemTypeA}
    />
  );
  const containerTermProviderA = (
    <ProviderTermsByName
      providerNameA={providerNameA}
      termKey="container"
      pcdv3
      itemTypeA={itemTypeA}
      containerTypeA={containerTypeA}
    />
  );
  const taskTermProviderB = (
    <ProviderTermsByName
      providerNameB={providerNameB}
      plurality="plural"
      termKey="task"
      textTransform="capitalize"
      pcdv3
      itemTypeB={itemTypeB}
    />
  );
  const containerTermProviderB = (
    <ProviderTermsByName
      providerNameB={providerNameB}
      termKey="container"
      pcdv3
      itemTypeB={itemTypeB}
      containerTypeB={containerTypeB}
    />
  );

  const containerA = useSelector((state) =>
    getLinkContainerBySide(state, { containerSide: fieldTypes.TARGET.A, linkId }),
  );
  const containerB = useSelector((state) =>
    getLinkContainerBySide(state, { containerSide: fieldTypes.TARGET.B, linkId }),
  );

  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));

  const tooltipsContent = {
    B:
      flowDirectionTarget === fieldTypes.TARGET.A ? (
        <div data-testid="b-direction-not-available">
          Syncing information to {providerB.get('displayName')} is not available at the moment
        </div>
      ) : (
        <div data-testid="b-direction-tooltip">
          {taskTermProviderA} in the {providerA.get('displayName')} {containerTermProviderA}{' '}
          <b>{containerA.get('displayName')}</b> will create {taskTermProviderB} in the {providerB.get('displayName')}{' '}
          {containerTermProviderB} <b>{containerB.get('displayName')}</b>. You will be able to change the flow of
          information between them later in the field mapping.
        </div>
      ),
    both:
      flowDirectionTarget === fieldTypes.TARGET.BOTH ? (
        <div data-testid="both-direction-tooltip">
          {taskTermProviderA} in the {providerA.get('displayName')} {containerTermProviderA}{' '}
          <b>{containerA.get('displayName')}</b> and {taskTermProviderB} in the {providerB.get('displayName')}{' '}
          {containerTermProviderB} <b>{containerB.get('displayName')}</b> will sync back and forth. You will be able to
          change the flow of information between them later in the field mapping.
        </div>
      ) : (
        <div data-testid="both-direction-tooltip-not-available">
          Two-way sync is not available with{' '}
          {flowDirectionTarget === fieldTypes.TARGET.A ? providerB.get('displayName') : providerA.get('displayName')} at
          the moment
        </div>
      ),
    A:
      flowDirectionTarget === fieldTypes.TARGET.B ? (
        <div data-testid="a-direction-tooltip-not-available">
          Syncing information to {providerA.get('displayName')} is not available at the moment
        </div>
      ) : (
        <div data-testid="a-direction-tooltip">
          {taskTermProviderB} in the {providerB.get('displayName')} {containerTermProviderB}{' '}
          <b>{containerB.get('displayName')}</b> will create {taskTermProviderA} in the {providerA.get('displayName')}{' '}
          {containerTermProviderA} <b>{containerA.get('displayName')}</b>. You will be able to change the flow of
          information between them later in the field mapping.
        </div>
      ),
  };

  return tooltipsContent[target];
};

TooltipContent.propTypes = {
  flowDirectionTarget: PropTypes.string.isRequired,
  linkId: PropTypes.string.isRequired,
  providerNameA: PropTypes.string.isRequired,
  providerNameB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  containerTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  containerTypeB: PropTypes.string.isRequired,
  target: PropTypes.oneOf(Object.values(fieldTypes.TARGET)).isRequired,
};

function useTrackStartEvent(providerNameA, providerNameB) {
  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);
}

export const FlowDirection = ({ match, loadingState }) => {
  const { watch, handleSubmit, setValue } = useFormContext();
  const { linkId } = match.params;
  const currentLinkState = useWatch({ name: 'state' });
  const isDraftFlow = linkId && currentLinkState === linkTypes.LINK_STATES.DRAFT;

  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [containerTypeA, containerTypeB] = useGetContainerTypes(linkId);

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const providerNameA = useSelector((state) =>
    getLinkProviderNameBySide(state, { containerSide: fieldTypes.TARGET.A, linkId }),
  );
  const providerNameB = useSelector((state) =>
    getLinkProviderNameBySide(state, { containerSide: fieldTypes.TARGET.B, linkId }),
  );
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const providerDisplayNameA = providerA.get('displayName');
  const providerDisplayNameB = providerB.get('displayName');

  const containerA = useSelector((state) =>
    getLinkContainerBySide(state, { containerSide: fieldTypes.TARGET.A, linkId }),
  );
  const containerB = useSelector((state) =>
    getLinkContainerBySide(state, { containerSide: fieldTypes.TARGET.B, linkId }),
  );
  const containerDisplayNameA = containerA.get('displayName');
  const containerDisplayNameB = containerB.get('displayName');

  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');

  const areProvidersCapabilitiesForItemsLoaded = useSelector((state) =>
    getAreProviderCapabilitiesForItemsLoaded(
      state,
      providerA.get('_id'),
      providerB.get('_id'),
      containerIdA,
      containerIdB,
      itemTypeA,
      itemTypeB,
    ),
  );
  const { target: flowDirectionTarget, oneWayToolRestricted } = useGetProvidersCanCreateWorkItem({
    containerIdA,
    containerIdB,
    providerNameA,
    providerNameB,
    itemTypeA,
    itemTypeB,
  });

  const directionValue = watch('syncDirection');

  const termsProviderA = (
    <ProviderTermsByName
      providerNameA={providerNameA}
      plurality="plural"
      termKey="task"
      textTransform="capitalize"
      pcdv3
      itemTypeA={itemTypeA}
    />
  );

  const termsProviderB = (
    <ProviderTermsByName
      providerNameB={providerNameB}
      plurality="plural"
      termKey="task"
      textTransform="capitalize"
      pcdv3
      itemTypeB={itemTypeB}
    />
  );

  const trackEvent = useTrackEvent({ selected_tool_names: `${providerNameA}, ${providerNameB}` });
  useTrackStartEvent(providerNameA, providerNameB);

  const onConfirmClick = useCallback(async () => {
    const oneWayTarget = fieldTypes.TARGET.A ? 'B->A' : 'A->B';
    trackEvent(trackingTypes.SUBMIT, {
      direction: directionValue === fieldTypes.TARGET.BOTH ? 'A<->B' : oneWayTarget,
    });

    await handleSubmit({ redirectPage: routes.FLOW_BUILDER_PAGES.RULES });
  }, [directionValue, handleSubmit, trackEvent]);

  return (
    <PageContainer>
      <PageHeader
        title="Choose your flow direction"
        subtitle={
          <>
            Select which way you would like items to be created. Some tools may only support one direction. You'll be
            able to select which way information updates later.{' '}
            <OpenSidePanelLink
              onClick={() => {
                setIsSidePanelOpen(true);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                  selected_tool_name: `${providerNameA},${providerNameB}`,
                });
              }}
            >
              Need some help deciding on your flow direction?
            </OpenSidePanelLink>
            <FlowDirectionSidePanel
              isOpen={isSidePanelOpen}
              onClose={() => setIsSidePanelOpen(false)}
              linkId={linkId}
            />
          </>
        }
      />

      <DuplicateBanner />

      {flowDirectionTarget !== fieldTypes.TARGET.BOTH && (
        <Box m={[tokens.spacing.s5, 0]} data-testid="one-way-sync-alert">
          <Alert>
            {flowDirectionTarget === fieldTypes.TARGET.B ? providerDisplayNameA : providerDisplayNameB} currently only
            supports one-way flows. {flowDirectionTarget === fieldTypes.TARGET.B ? termsProviderB : termsProviderA} will
            only be created in{' '}
            {flowDirectionTarget === fieldTypes.TARGET.B ? providerDisplayNameB : providerDisplayNameA}{' '}
            {flowDirectionTarget === fieldTypes.TARGET.B ? containerDisplayNameB : containerDisplayNameA}. You can
            select update direction later.
          </Alert>
        </Box>
      )}
      <Container>
        {loadingState === linkTypes.LINK_STATES.LOADING || !areProvidersCapabilitiesForItemsLoaded ? (
          <LoadingPlaceholder width="100%" height="4rem" />
        ) : (
          <NewRow justify="space-between" align="middle">
            <NewCol span={8}>
              <FlowContainerInfo containerSide={fieldTypes.TARGET.A} linkId={linkId} />
            </NewCol>
            <NewCol span={8}>
              <Flex justify="center">
                <Radio.Group
                  buttonStyle="solid"
                  size="middle"
                  disabled={oneWayToolRestricted}
                  optionType="button"
                  value={directionValue}
                  name="syncDirection"
                  onChange={(event) => setValue('syncDirection', event.target.value, { shouldDirty: true })}
                  options={[
                    {
                      value: fieldTypes.TARGET.B,
                      label: (
                        <Tooltip
                          placement="top"
                          content={
                            <TooltipContent
                              flowDirectionTarget={flowDirectionTarget}
                              linkId={linkId}
                              target={fieldTypes.TARGET.B}
                              providerNameA={providerNameA}
                              providerNameB={providerNameB}
                              itemTypeA={itemTypeA}
                              containerTypeA={containerTypeA}
                              itemTypeB={itemTypeB}
                              containerTypeB={containerTypeB}
                            />
                          }
                        >
                          <Icon
                            aria-label={`${containerDisplayNameA} → ${containerDisplayNameB}`}
                            name="long-arrow-right"
                          />
                        </Tooltip>
                      ),
                    },
                    {
                      value: fieldTypes.TARGET.BOTH,
                      label: (
                        <Tooltip
                          placement="top"
                          content={
                            <TooltipContent
                              flowDirectionTarget={flowDirectionTarget}
                              linkId={linkId}
                              target="both"
                              providerNameA={providerNameA}
                              providerNameB={providerNameB}
                              itemTypeA={itemTypeA}
                              containerTypeA={containerTypeA}
                              itemTypeB={itemTypeB}
                              containerTypeB={containerTypeB}
                            />
                          }
                        >
                          <Icon aria-label={`${containerDisplayNameA} ⇄ ${containerDisplayNameB}`} name="exchange" />
                        </Tooltip>
                      ),
                    },
                    {
                      value: fieldTypes.TARGET.A,
                      label: (
                        <Tooltip
                          placement="top"
                          content={
                            <TooltipContent
                              flowDirectionTarget={flowDirectionTarget}
                              linkId={linkId}
                              target={fieldTypes.TARGET.A}
                              providerNameA={providerNameA}
                              providerNameB={providerNameB}
                              itemTypeA={itemTypeA}
                              containerTypeA={containerTypeA}
                              itemTypeB={itemTypeB}
                              containerTypeB={containerTypeB}
                            />
                          }
                        >
                          <Icon
                            aria-label={`${containerDisplayNameA} ← ${containerDisplayNameB}`}
                            name="long-arrow-left"
                          />
                        </Tooltip>
                      ),
                    },
                  ]}
                />
              </Flex>
            </NewCol>
            <NewCol span={8}>
              <FlowContainerInfo containerSide={fieldTypes.TARGET.B} linkId={linkId} />
            </NewCol>
          </NewRow>
        )}
        <Box m={[tokens.spacing.s5, 0, 0]}>
          <ImgWrapper src={DirectionImg[directionValue]} alt="directionSync" />
        </Box>
      </Container>
      {isDraftFlow && (
        <Box justifyContent="flex-end">
          <Button type="submit" loading={loadingState === formUtils.loadingStates.SAVING} onClick={onConfirmClick}>
            Confirm
          </Button>
        </Box>
      )}
    </PageContainer>
  );
};

FlowDirection.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ linkId: PropTypes.string }).isRequired,
  }).isRequired,
};
