import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';

import { Icon, Spinner } from '@unitoio/mosaic';
import { logger } from '@unitoio/sherlock';

import { containerActions, fieldActions, linkActions, providerActions } from 'actions';
import { routes } from 'consts';
import { color } from 'theme';
import { getLatestDiagnostic, isDiagnosticLoading } from 'reducers';
import { Card } from '~/components/Card/Card';
import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { Modal } from '~/components/Modal/Modal';

import { LinkInfo } from './components/LinkInfo';
import { ConnectorInfo } from './components/ConnectorInfo';

export function fetchConnectorInfo(sync, dispatch) {
  const linkId = sync.get('_id');

  ['A', 'B'].forEach((containerSide) => {
    const requiredFields = ['providerIdentity._id', 'providerName', 'container.id', 'itemType'];
    const gettingValuesFromSync = requiredFields.map((path) => sync.getIn([containerSide, ...path.split('.')]));
    const fieldsAreSet = gettingValuesFromSync.every((field) => field !== undefined && field !== null);

    const [providerIdentityId, providerName, containerId, itemType] = gettingValuesFromSync;

    if (!fieldsAreSet) {
      logger.warn(`Missing required fields (side=${containerSide}) to fetch connector info. `, {
        containerId,
        itemType,
        providerIdentityId,
        providerName,
        linkId,
      });
      return;
    }

    dispatch(
      fieldActions.getCustomFields({
        containerId,
        containerSide,
        providerIdentityId,
        itemType,
        getUncached: true,
      }),
    );

    dispatch(
      providerActions.getProviderCapabilitiesForItem(providerName, providerIdentityId, containerId, itemType, linkId),
    );

    if (providerName === 'trello') {
      dispatch(
        containerActions.getContainerPlugins({
          containerId,
          providerIdentityId,
        }),
      ).catch((err) => {
        if (err.code !== 404) {
          throw err;
        }
      });
    }
  });
}

function useFetchSyncDiagnostics(sync) {
  const [taskCount, setTaskCount] = useState({ all: 0, closed: 0, filteredOut: 0 });
  const [isLoadingResources, setIsLoadingResources] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchResources = async () => {
      async function fetchTaskSyncCount() {
        const orgId = sync.getIn(['organization', '_id']) ?? sync.get('organization');
        const result = await dispatch(linkActions.getOrganizationTaskSyncsTaskCount(orgId, [sync.get('_id')]));

        setTaskCount(result.taskCount);
      }

      fetchConnectorInfo(sync, dispatch);

      await fetchTaskSyncCount();

      setIsLoadingResources(false);
    };

    fetchResources();
  }, [dispatch, sync]);

  return [taskCount, isLoadingResources];
}

export function DiagnoseModal({ isOpen, onRequestClose, sync }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(isDiagnosticLoading);
  const latestResult = useSelector(getLatestDiagnostic);

  const [taskCount, isLoadingResources] = useFetchSyncDiagnostics(sync);

  function onClose() {
    resetDiagnostic();
    onRequestClose();
  }

  function resetDiagnostic() {
    dispatch(linkActions.resetDiagnostic());
  }

  const linkId = sync.get('_id');
  const linkName = sync.get('name');

  return (
    <Modal
      height="90%"
      width="90%"
      displayCloseButton
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      type="plainModal"
      size="full"
    >
      {isLoadingResources && (
        <Card>
          <Spinner />
        </Card>
      )}
      <Section style={{ marginBottom: '24px' }}>
        <Card borderless color={color.dark.quiet} padding="1em">
          <Title type="h2">
            Diagnose <Href to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${linkId}`}>{linkName}</Href>
          </Title>

          <LinkInfo sync={sync} taskCount={taskCount} />
        </Card>
      </Section>

      <Section>
        <ConnectorInfo sync={sync} />
      </Section>

      <Section>
        {isLoading && (
          <Card>
            <div className="text-center">
              <Icon name="spinner" size="5x" kind={Icon.KINDS.SOLID} title="loading" pulse />
            </div>
          </Card>
        )}
      </Section>

      <Section>
        <div className="row">
          {['A', 'B'].map((side) => (
            <div key={side} className="col-xs-6">
              <Card borderless color={color.dark.quiet} padding="1em">
                {latestResult?.[side] && (
                  <ReactJson
                    style={{ wordBreak: 'break-all', lineBreak: 'anywhere' }}
                    src={latestResult?.[side]}
                    displayDataTypes={false}
                  />
                )}
              </Card>
            </div>
          ))}
        </div>
      </Section>
    </Modal>
  );
}

DiagnoseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  sync: PropTypes.instanceOf(Map).isRequired,
};
