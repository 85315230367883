import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { UnitoIdentities, WorkspaceMembers } from 'containers';
import { Box } from '~/components/Box/Box';
import { RoutedNavTabs } from '~/components/NavTabs/RoutedNavTabs';

export class PeopleContainer extends Component {
  renderTabTitles = () => {
    const { match } = this.props;

    const TAB_ROUTES = [
      {
        path: `${match.url}/members`,
        tab: 'Workspace members',
      },
      {
        path: `${match.url}/active-users`,
        tab: 'Active users',
      },
    ];

    return <RoutedNavTabs isJustified={false} routes={TAB_ROUTES} tabStyle="underline" isSticky />;
  };

  render() {
    const { match } = this.props;

    return (
      <div className="people-container container-full">
        <Box $m={[0, 0, 2]}>{this.renderTabTitles()}</Box>
        <Switch>
          <Route
            render={(props) => <WorkspaceMembers {...props} organizationId={match.params.organizationId} />}
            path={`${match.path}/members`}
          />
          <Route
            render={(props) => <UnitoIdentities {...props} organizationId={match.params.organizationId} />}
            exact
            path={`${match.path}/active-users`}
          />
          <Redirect from={`${match.url}/`} to={`${match.url}/members`} />
        </Switch>
      </div>
    );
  }
}

PeopleContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
