import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { containerTypes } from 'consts';
import { color, borderRadius } from 'theme';
import { Box } from '~/components/Box/Box';

import { WorkBlockStyled } from './WorkBlockStyled';

const Animate = keyframes`
  0%{
    background-position: -16rem 0
  }
  100%{
    background-position: 16rem 0
  }
`;

const LoadingBackground = styled.div`
  animation: ${Animate} 5s linear infinite forwards;
  background: ${color.content.secondary.disabled};
  background: linear-gradient(
    to right,
    ${color.content.secondary.disabled} 8%,
    #c4cad4 18%,
    ${color.content.secondary.disabled} 33%
  );
  background-size: 16rem 0.7rem;
`;

const IconLoading = styled(LoadingBackground)`
  border-radius: 3px;
  box-sizing: border-box;
  height: 2rem;
  width: 2rem;
  margin: 0 auto;
`;

const ContainerNameLine = styled(LoadingBackground)`
  box-sizing: border-box;
  border-radius: ${borderRadius.large};
  height: 0.7rem;
  width: 5rem;
  margin: 0.5rem 0 0.25rem;
  position: relative;
`;

export const WorkBlockLoading = ({ children }) => (
  <WorkBlockStyled $containerStatus={containerTypes.CONTAINER_STATUSES.LOADING}>
    <Box $m={[3, 0, 0, 0]}>
      <IconLoading />
    </Box>
    <Box $m={[0.5, 1]}>
      <ContainerNameLine />
      <ContainerNameLine />
    </Box>
    {children}
  </WorkBlockStyled>
);

WorkBlockLoading.propTypes = {
  children: PropTypes.node,
};
