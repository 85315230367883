import React from 'react';
import PropTypes from 'prop-types';

import { routes } from 'consts';
import { Href } from '~/components/Href/Href';

import { TableCell } from '../components';

const Cell = ({ value: workflows }) => (
  <TableCell>
    <div className="wide">
      {workflows.map(({ id, name }) => (
        <Href key={id} target="_blank" href={`#${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${id}`}>
          {name}
        </Href>
      ))}
    </div>
  </TableCell>
);

Cell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
export const workflowColumn = {
  header: 'Workflow',
  accessor: 'workflows',
  disableFilters: true,
  disableSortBy: true,
  Cell,
};
