import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import { color } from 'theme';
import { reduxForm, Fields, SubmissionError } from 'redux-form';
import { isMobile } from 'react-device-detect';

import { Box, tokens, Typography, TypographyVariants } from '@unitoio/mosaic';
import { ErrorBoundary } from '@unitoio/sherlock';

import { appHistory } from 'app-history';
import { authActions } from 'actions';
import { appcuesTypes, routes, trackingTypes, authTypes, providerTypes } from 'consts';
import { AppError } from 'containers';
import { useLogger, useTrackEvent, useGetWorkspaceNameFromInvite } from 'hooks';
import { getFeatureFlagValue, getIsAuthenticated, getProvidersThatCanLogin, getProvidersThatCanSignup } from 'reducers';
import * as utils from 'utils';
import unitoLogo from 'shared-images/unito_logo_color_horiz.svg';
import { AppcuesLink } from '~/components/AppcuesLink/AppcuesLink';
import { Alert } from '~/components/Alert/Alert';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { Href } from '~/components/Href/Href';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { Loading } from '~/components/Loading/Loading';

import { LoginFields } from './LoginFields';
import { SignupFields } from './SignupFields';
import { SingleSignOnFields } from './SingleSignOnFields';
import { LoginEmailPasswordButton } from './LoginEmailPasswordButton';

/* eslint-disable */
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${color.light.gray};
  }
`;
/* eslint-enable */

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;
`;

const Content = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
  padding-top: 4vh;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const ModifierFontSize = styled.span`
  font-size: 14px;
`;

const UnitoLogo = styled.img`
  width: 10rem;
  height: auto;
  margin-bottom: 2rem;
`;

const MaxWidth = styled.div`
  max-width: 35rem;
  width: 35rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const validate = (values) => {
  const { fullName } = values;
  // validate fullName for signups if it was provided
  if (!utils.formUtils.isEmpty(fullName)) {
    // SECURITY-FIX: https://app.asana.com/0/374063671590608/1202856509041929/f
    // fullName is prone to being an attack vector for injection
    // since we have to support an international set of characters, it's not straightforward
    // to whitelist characters, instead it's better to exclude some characters
    // after a bit of research this regex seems to be comprehensive enough
    // https://stackoverflow.com/a/70291109
    // Exclude characters: " : * ? < > ^ $ \ | /
    // make check multiline, insensitive and match with full unicode = miu
    // This validation is also replicated on the backend in maestro/src/resources/auth.ts validateCognitoFullName()
    const regex = /^[^":*?<>^$\\|/]+$/imu;
    const hasValidCharacters = regex.test(fullName);
    if (!hasValidCharacters) {
      return {
        fullName: 'Please enter a valid name',
      };
    }
  }
  return {};
};

const BoldEmail = styled.span`
  font-weight: bold;
`;
const AuthenticationComponent = reduxForm({
  form: 'authenticationForm',
  validate,
})(AuthenticationContainerWithErrorBoundary);

const mapStateToProps = (state, ownProps) => {
  const { email } = utils.getSearchParams(ownProps.location.search, true);

  // RF5322 standard: https://www.abstractapi.com/guides/email-address-pattern-validation#rfc-5322-official-standard-regular-expression-to-validate-email-addresses
  const emailRegex =
    /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"(!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/;

  return {
    isAuthenticated: getIsAuthenticated(state),
    providers:
      ownProps.match.params.method === authTypes.AUTH_ACTIONS.LOGIN
        ? getProvidersThatCanLogin(state)
        : getProvidersThatCanSignup(state),
    initialValues: {
      loginEmail: (email || '').match(emailRegex) ? email : '',
    },
  };
};

function useTrackBlockedEvent(location, match, trackEvent) {
  const { reportException, reportWarning } = useLogger();
  const { search } = location;
  const { message: errorMessage, status: errorStatus, authenticationParams, success } = utils.getSearchParams(search);
  const { method } = match.params;

  useEffect(() => {
    // Success is returned by Auth0 when a user is resetting their password or confirming their email. We don't want to track those events.
    if (errorMessage && !success) {
      trackEvent(trackingTypes.BLOCKED, {
        action_taken_from: method,
        failedValidation: true,
        reason: errorMessage,
        selected_tool_name: authenticationParams?.authTool,
      });
      if (errorStatus) {
        if (errorStatus < 500) {
          reportWarning(errorMessage, { identifier: 'useTrackBlockedEvent AuthenticationContainer' });
        } else {
          reportException(errorMessage, { identifier: 'useTrackBlockedEvent AuthenticationContainer' });
        }
      }
    }
  }, [
    authenticationParams?.authTool,
    errorMessage,
    errorStatus,
    method,
    reportException,
    reportWarning,
    trackEvent,
    success,
  ]);
}

function useTrackStartEvent(trackEvent, trackStartEvent) {
  const { reportInfo } = useLogger();
  useEffect(() => {
    if (trackStartEvent) {
      trackEvent(trackingTypes.START);
      reportInfo('START SIGNUP/LOGIN funnel', { funnel: { action: trackingTypes.START } });
    }
  }, [trackEvent, trackStartEvent, reportInfo]);
}

// This hook is to clear the form errors in the case the user had an error logging in and decides to go to the signup page or vice-versa.
function useClearSubmitErrorsWhenChangingMethod(clearSubmitErrors, method) {
  // Redux-Form doesn't implement hooks, therefore there its action creators aren't hook friendly (wrapped in a useCallback)
  // clearSubmitErrors is a simple redux action and can't hold stale reference, hence why it is safe to ignore the dependency
  // array here. See action creator implementation: https://github.com/redux-form/redux-form/blob/d1067cb6f0fb76d76d91462676cd0b2c3927f9db/src/actions.js#L254-L259
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearSubmitErrorsCallback = useCallback(() => clearSubmitErrors(), []);
  useEffect(() => {
    clearSubmitErrorsCallback();
  }, [clearSubmitErrorsCallback, method]);
}

function onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect) {
  return async (formValues, dispatch) => {
    const selectedProvider = providers.get(formValues.provider, Map());
    const authenticationTool = selectedProvider.isEmpty() ? providerTypes.AUTH0 : selectedProvider.get('name');

    if (trackEvent) {
      trackEvent(authenticationTool === providerTypes.AUTH0 ? trackingTypes.ACTION : trackingTypes.SUBMIT, {
        selected_tool_name: authenticationTool,
      });
    }

    if (utils.authUtils.providerNeedsManualConfiguration(selectedProvider)) {
      const queryParams = new URLSearchParams({
        keepInformed: formValues.keepInformed ?? '',
      });
      appHistory.push({
        pathname: `/${method}/${selectedProvider.get('name')}`,
        search: `?${queryParams.toString()}`,
        state: redirect,
      });

      return;
    }

    try {
      const { authenticationUrl } = await dispatch(
        authActions.signin(formValues, { method, authenticationTool, redirect }),
      );
      if (
        authenticationUrl &&
        new URL(authenticationUrl).hash.split('?')[0] === `#/login/${authTypes.EMAIL_AUTH_STATES.RESEND_CONFIRMATION}`
      ) {
        trackEvent(trackingTypes.BLOCKED, {
          reason: 'email not verified',
          selected_tool_name: providerTypes.AUTH0,
        });
        reportWarning('email not verified', { identifier: 'emailNotVerified onSubmit AuthenticationContainer' });
      }

      global.window.location.assign(authenticationUrl);
    } catch (err) {
      trackEvent(trackingTypes.BLOCKED, {
        reason: err.message,
      });
      if (err.code < 500) {
        reportWarning(err.message, { identifier: 'uncaughtError onSubmit AuthenticationContainer' });
      } else {
        reportException(err, { identifier: 'uncaughtError onSubmit AuthenticationContainer' });
      }

      throw new SubmissionError({ _error: { message: err.message, name: err.name } });
    }
  };
}

const getSignUpTitle = (workspaceName) => (
  <Box m={[0, 0, tokens.spacing.s5, 0]}>
    {workspaceName ? (
      <>
        <Typography variant={Typography.variants.H1} align="center">
          Create an account and join
        </Typography>
        <Typography variant={Typography.variants.H1} align="center">
          {workspaceName}
        </Typography>
      </>
    ) : (
      <Typography variant={Typography.variants.H1} align="center">
        Get started with your 14-day free trial!
      </Typography>
    )}
  </Box>
);

const getLoginTitle = (workspaceName) => (
  <Box m={[0, 0, tokens.spacing.s5, 0]}>
    {workspaceName ? (
      <>
        <Typography variant={Typography.variants.H1} align="center">
          Log in and join
        </Typography>
        <Typography variant={Typography.variants.H1} align="center">
          {workspaceName}
        </Typography>
      </>
    ) : (
      <Typography variant={Typography.variants.H1} align="center">
        Welcome back!
      </Typography>
    )}
  </Box>
);

const getSSOTitle = () => (
  <Box m={[0, 0, tokens.spacing.s5, 0]}>
    <Typography variant={Typography.variants.H1} align="center">
      Log in with Single Sign On
    </Typography>
  </Box>
);

const getTitle = ({ isAuthLogin, isAuthSSO, workspaceName, isLoading }) => {
  if (isLoading) {
    return <InlineLoading />;
  }

  if (isAuthSSO) {
    return getSSOTitle();
  }

  if (isAuthLogin) {
    return getLoginTitle(workspaceName);
  }

  return getSignUpTitle(workspaceName);
};

function AuthenticationContainerInner({
  asyncValidating,
  error,
  isAuthenticated,
  location,
  match,
  handleSubmit,
  clearSubmitErrors,
  providers,
  submitting,
  submitSucceeded,
  initialValues,
}) {
  const { method } = match.params;
  const redirect = location.state?.referrer.pathname;
  const dispatch = useDispatch();
  const { reportException, reportWarning } = useLogger();
  const isSSOEnabled = useSelector((state) => getFeatureFlagValue(state, 'sso'));
  const [redirecting, updateRedirecting] = React.useState(false);

  const trackEvent = useTrackEvent({ action_taken_from: method });
  const trackAction = useCallback(
    (actionName, data) => trackEvent(trackingTypes.ACTION, { ...data, action_name: actionName }),
    [trackEvent],
  );

  const [isConfirmEmailResent, setIsConfirmEmailReset] = React.useState(false);

  const resendConfirmationEmail = (email) => {
    dispatch(authActions.resendConfirmationEmail(email));
    setIsConfirmEmailReset(true);
  };

  const canBeRedirectedToAuth0 = !!(match.url.match(/^\/login$/) || match.url.match(/^\/signup$/));

  useTrackStartEvent(trackEvent, !canBeRedirectedToAuth0);
  useTrackBlockedEvent(location, match, trackEvent);
  useClearSubmitErrorsWhenChangingMethod(clearSubmitErrors, match.params.method);
  const { isLoading: isLoadingWorkspaceName, workspaceName } = useGetWorkspaceNameFromInvite();

  const isAuthLogin = match.params.method === authTypes.AUTH_ACTIONS.LOGIN;
  const isAuthSSO = match.params.method === authTypes.AUTH_ACTIONS.SSO && isSSOEnabled;

  const auth0RedirectOnSubmit = useCallback(
    () => handleSubmit(onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect))(),
    [handleSubmit, method, providers, trackEvent, reportException, reportWarning, redirect],
  );

  useEffect(() => {
    if (!isAuthenticated && canBeRedirectedToAuth0 && !redirecting) {
      // Redirect user to Auth0 when it's not using our native OAuth login and signup
      auth0RedirectOnSubmit();
      updateRedirecting(true);
    }
  }, [isAuthenticated, canBeRedirectedToAuth0, redirecting, auth0RedirectOnSubmit]);

  if (redirecting) {
    return <Loading />;
  }

  const getMessageFromQueryParams = () => {
    const { search } = location;

    // Success is returned by Auth0 when a user is resetting their password or confirming their email. We don't want to **retrieve the message**.
    const { message, success } = utils.getSearchParams(search);
    return !success && message;
  };

  const isAuthButtonDisabled = () => !!(submitting || submitSucceeded || asyncValidating);

  const getErrorMessage = () => {
    const queryParamsMessage = getMessageFromQueryParams();
    if (queryParamsMessage && !error) {
      return queryParamsMessage;
    }

    if (!error) {
      return null;
    }

    const errorNameMap = {
      UnconfirmedUser: (message) => (
        <span>
          {message} We need you to first <Href to={routes.ABSOLUTE_PATHS.RESET}>reset your password</Href>.
        </span>
      ),
      MaestroError: (message) => (
        <span>
          {message} <Href href={`https://guide.unito.io/kb-search-results?term=${message}`}>Get help</Href>
        </span>
      ),
    };

    const { name, message } = error || {};

    const renderError = errorNameMap[name] || errorNameMap.MaestroError;
    return renderError(message);
  };

  const { pathname } = location;

  const emailAuthState = pathname.split('/')[2];

  const isEmailAuthState = Object.values(authTypes.EMAIL_AUTH_STATES).includes(emailAuthState);

  const getSuccessMessage = () => {
    if (!isEmailAuthState) {
      return null;
    }

    const mapEmailAuthStateToMessage = {
      [authTypes.EMAIL_AUTH_STATES.CONFIRMED]: () => (
        <Typography variant={TypographyVariants.BODY1}>
          Your email is now confirmed. You can now log in to Unito.
        </Typography>
      ),
      [authTypes.EMAIL_AUTH_STATES.CONFIRM]: (email) => (
        <Typography variant={TypographyVariants.BODY1}>
          Thanks for signing up! Please confirm your email address by clicking the link included in the email we sent{' '}
          {email ? (
            <>
              to: <BoldEmail>{email}</BoldEmail>
            </>
          ) : (
            ' you'
          )}
          .
        </Typography>
      ),
      [authTypes.EMAIL_AUTH_STATES.RESEND_CONFIRMATION]: (email) => (
        <span>
          <Typography variant={TypographyVariants.BODY1}>
            It seems you haven't verified your email address yet. If you can't find the message we sent to{' '}
            <BoldEmail>{email}</BoldEmail>, we can{' '}
          </Typography>
          <Button
            noPadding
            btnStyle="link"
            disabled={isConfirmEmailResent}
            onClick={() => {
              resendConfirmationEmail(email);
              trackAction('clicked resend verification email', { selected_tool_name: providerTypes.AUTH0 });
            }}
          >
            resend the verification email
          </Button>
          .
        </span>
      ),
    };

    return mapEmailAuthStateToMessage[emailAuthState](initialValues.loginEmail);
  };

  if (isAuthenticated) {
    return <Redirect to={routes.ABSOLUTE_PATHS.DASHBOARD} />;
  }

  const errorMessage = getErrorMessage();
  const successMessage = getSuccessMessage();

  const renderAuthenticationFields = () => {
    if (isEmailAuthState) {
      return (
        <LoginEmailPasswordButton
          onSubmit={handleSubmit(onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect))}
        />
      );
    }

    if (isAuthLogin) {
      return (
        <Fields
          names={['provider']}
          component={LoginFields}
          providers={providers}
          disableSubmit={isAuthButtonDisabled()}
          trackLoginAction={trackAction}
          onSubmit={handleSubmit(onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect))}
        />
      );
    }

    if (isAuthSSO) {
      return (
        <Fields
          names={['ssoIdentityProviderId']}
          component={SingleSignOnFields}
          disableSubmit={isAuthButtonDisabled()}
          trackLoginAction={trackAction}
          onSubmit={handleSubmit(
            onSubmit(authTypes.AUTH_ACTIONS.SIGNUP, providers, trackEvent, reportException, reportWarning),
          )}
        />
      );
    }

    // Filter out providers that are not supported for signup
    // This is hardcoded since we don't want to touch PCDs
    const signupProviders = providers.filter((provider) =>
      ['asana', 'googlesheets', 'trello'].includes(provider.get('name')),
    );
    return (
      <Fields
        component={SignupFields}
        disableSubmit={isAuthButtonDisabled()}
        names={['provider']}
        providers={signupProviders}
        trackSignupAction={trackAction}
        onSubmit={handleSubmit(onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect))}
      />
    );
  };

  const renderFooter = () => {
    if (isAuthLogin) {
      return (
        <>
          {isSSOEnabled && (
            <Box m={[0, 0, 0.5, 0]}>
              <Href
                to={routes.ABSOLUTE_PATHS.SSO}
                linkStyle="blue"
                onClick={() => trackAction('clicked login with sso')}
              >
                Login with single sign-on
              </Href>
            </Box>
          )}
          Don't have an account yet{' '}
          <strong>
            <Href to={routes.ABSOLUTE_PATHS.SIGNUP} linkStyle="blue" onClick={() => trackAction('clicked Sign up')}>
              Sign up
            </Href>
          </strong>
        </>
      );
    }

    if (isAuthSSO) {
      return (
        <>
          <Box m={[0, 0, 0.5, 0]}>
            <Href to={routes.ABSOLUTE_PATHS.LOGIN} linkStyle="blue">
              Login using a different method
            </Href>
          </Box>
          <div>
            Interested in SSO for your organization?{' '}
            <AppcuesLink ctaId={appcuesTypes.CTA_IDS.SSO} asHref onClick={() => trackAction('clicked on contact us')}>
              Contact us
            </AppcuesLink>
          </div>
        </>
      );
    }

    return (
      <>
        Already have an account?{' '}
        <strong>
          <Href to={routes.ABSOLUTE_PATHS.LOGIN} linkStyle="blue" onClick={() => trackAction('clicked Log in')}>
            Log in
          </Href>
        </strong>
      </>
    );
  };

  return (
    <Container className="container">
      <GlobalStyle />
      {isLoadingWorkspaceName ? (
        <Loading />
      ) : (
        <Content
          onSubmit={handleSubmit(onSubmit(method, providers, trackEvent, reportException, reportWarning, redirect))}
        >
          <TextCenter>
            <Href href="https://unito.io/" onClick={() => trackAction('clicked on Unito logo')}>
              <UnitoLogo src={unitoLogo} alt="Unito logo" />
            </Href>
            {getTitle({ isAuthLogin, isAuthSSO, workspaceName, isLoadingWorkspaceName })}
          </TextCenter>
          <MaxWidth>
            <Card
              color={color.light.primary}
              padding={isMobile ? tokens.spacing.s6 : `${tokens.spacing.s6} ${tokens.spacing.s8}`}
              boxShadow
            >
              {errorMessage
                ? errorMessage && (
                    <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
                      <Alert level="error">{errorMessage} </Alert>
                    </Box>
                  )
                : successMessage && (
                    <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
                      <Alert level="success">{successMessage}</Alert>
                    </Box>
                  )}
              <Box m={[tokens.spacing.s4, tokens.spacing.s0]}>{renderAuthenticationFields()}</Box>
            </Card>
          </MaxWidth>
          <Box m={[tokens.spacing.s4, tokens.spacing.s0]}>
            <TextCenter>
              <ModifierFontSize>{renderFooter()}</ModifierFontSize>
            </TextCenter>
          </Box>
        </Content>
      )}
    </Container>
  );
}

function AuthenticationContainerWithErrorBoundary(props) {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <AuthenticationContainerInner {...props} />
    </ErrorBoundary>
  );
}

AuthenticationContainerInner.propTypes = {
  asyncValidating: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.shape({
      referrer: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      method: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  providers: PropTypes.instanceOf(Map).isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    loginEmail: PropTypes.string.isRequired,
  }).isRequired,
};

export const AuthenticationContainer = connect(mapStateToProps)(AuthenticationComponent);
