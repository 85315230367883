import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Box, Button, Typography, TypographyVariants, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { formUtils } from 'utils';
import { useQueryParams } from 'hooks';
import { formTypes } from 'consts';
import { TextInput } from '~/components/TextInput/TextInput';

function getErrorMessage(queryErrorMessage) {
  switch (queryErrorMessage) {
    case 'Invalid SAML response received: User is disabled.':
      return 'Your account has not yet been given access to Unito or was disabled. Please contact your administrator for details.';
    default:
      return queryErrorMessage;
  }
}

export const SingleSignOnFields = ({ disableSubmit = false, ssoIdentityProviderId, onSubmit }) => {
  const { errorMessage } = useQueryParams();
  const errorMsg = getErrorMessage(errorMessage);

  return (
    <Fragment>
      {!!errorMsg && (
        <Box m={[0, 0, 1, 0]}>
          <Alert level={NewAlertLevel.ERROR} message={errorMsg} />
        </Box>
      )}

      <Box m={[0, 0, 1, 0]}>
        <Typography align="center" variant={TypographyVariants.SUBTITLE1}>
          Connect with your identity provider
        </Typography>
      </Box>
      <TextInput
        {...ssoIdentityProviderId}
        input={{
          ...ssoIdentityProviderId.input,
        }}
        type="text"
        label="Account id"
      />
      <Button
        block
        disabled={disableSubmit || formUtils.isEmpty(ssoIdentityProviderId.input.value)}
        type="submit"
        size="md"
        onClick={onSubmit}
      >
        Sign in with SSO
      </Button>
    </Fragment>
  );
};

SingleSignOnFields.propTypes = {
  disableSubmit: PropTypes.bool,
  ssoIdentityProviderId: PropTypes.shape(formTypes.fieldPropTypes).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
