import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import {
  Alert,
  AlertLevels,
  AlertSizes,
  Box,
  Icon,
  tokens,
  Modal,
  Typography,
  TypographyVariants,
} from '@unitoio/mosaic';

import { trackingTypes } from 'consts';
import { getLinkProviderNameBySide } from 'reducers';
import { useTrackEvent, useGetItemTypes } from 'hooks';
import { RadioButtonGroup } from '~/components/RadioButtonGroup/RadioButtonGroup';
import { RadioButton } from '~/components/RadioButton/RadioButton';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { useGetTestModeTerm } from '../hooks/useGetTestModeTerm';

export const SelectResyncTypeModal = ({
  isOpen,
  isSaving,
  onCancel = () => null,
  onConfirm = () => null,
  onOpen = () => null,
  onRequestClose = () => null,
}) => {
  const { linkId } = useParams();
  const trackEvent = useTrackEvent({ link: { id: linkId } }, false);
  const { setValue, watch } = useFormContext();
  const isAutoSync = watch('isAutoSync');
  const [lazyResyncSelected, setLazyResyncSelected] = useState(true);
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const providerNameA = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'A', linkId }));
  const providerNameB = useSelector((state) => getLinkProviderNameBySide(state, { containerSide: 'B', linkId }));
  const testModeTerm = useGetTestModeTerm(linkId);
  const confirmLabel = isAutoSync && !lazyResyncSelected ? 'Save & sync' : 'Save';

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.LAZY_RESYNC.START);
      onOpen();
    }
  }, [isOpen, onOpen, trackEvent]);

  const handleOnChange = (value) => {
    setLazyResyncSelected(value);
    setValue('lazyResync', value, { shouldDirty: true });
  };

  const handleOnCancel = () => {
    trackEvent(trackingTypes.LAZY_RESYNC.SUBMIT, { is_lazy_resync: 'discarded changes' });
    setLazyResyncSelected(true);
    onCancel();
  };

  const handleOnConfirm = async () => {
    setValue('lazyResync', lazyResyncSelected, { shouldDirty: true });
    trackEvent(trackingTypes.LAZY_RESYNC.SUBMIT, { is_lazy_resync: lazyResyncSelected });
    onConfirm();
  };

  const handleOnRequestClose = () => {
    trackEvent(trackingTypes.LAZY_RESYNC.ACTION, { action_name: 'clicked on cross to close modal' });
    setLazyResyncSelected(true);
    setValue('lazyResync', true, { shouldDirty: true });
    onRequestClose();
  };

  const termsToDisplay = (
    <ProviderTermsByName
      providerNameA={providerNameA}
      providerNameB={providerNameB}
      termKey="task"
      plurality="plural"
      pcdv3={!!itemTypeA && !!itemTypeB}
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
    />
  );

  const lazyResyncLabel = (
    <Typography variant={TypographyVariants.BODY1}>
      <strong>
        Only {testModeTerm} {termsToDisplay} (recommended)
      </strong>
    </Typography>
  );

  const lazyResyncSubLabel = (
    <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
      Apply changes to any {termsToDisplay} created or modified from this point forward.
    </Typography>
  );

  const fullResyncLabel = (
    <Typography variant={TypographyVariants.BODY1}>
      <strong>All historical {termsToDisplay}</strong>
    </Typography>
  );

  const fullResyncSubLabel = (
    <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
      Apply changes to all historical {termsToDisplay} associated with this flow. This flow might take a bit longer than
      usual to sync.
    </Typography>
  );

  return (
    <div data-testid="select-resync-type-modal">
      <Modal
        cancelLabel="Discard changes"
        confirmLabel={
          <Fragment>
            {confirmLabel} {isSaving && <Icon name="circle-notch" kind={Icon.KINDS.SOLID} title="saving" spin />}
          </Fragment>
        }
        displayCloseButton
        isOpen={isOpen}
        onRequestClose={handleOnRequestClose}
        onCancel={handleOnCancel}
        onConfirm={handleOnConfirm}
        size="lg"
        title="How would you like your changes to be applied?"
        isCancelActionDestructive
      >
        <div data-testid="modal-content">
          <RadioButtonGroup name="syncTypeRadioButtonGroup" onChange={handleOnChange} value={lazyResyncSelected}>
            <RadioButton
              value={true}
              label={lazyResyncLabel}
              color={tokens.colors.content.primary.default}
              subLabel={lazyResyncSubLabel}
            />
            <RadioButton
              value={false}
              label={fullResyncLabel}
              color={tokens.colors.content.primary.default}
              subLabel={fullResyncSubLabel}
            />
          </RadioButtonGroup>
          {!lazyResyncSelected && (
            <Box m={[tokens.spacing.s5, 0, 0, 0]}>
              <Alert level={AlertLevels.WARNING} showIcon showBorder size={AlertSizes.SM}>
                <Typography variant={TypographyVariants.BODY2}>
                  Applying changes historically may take a while depending of your tools' rate limits and may also
                  trigger a lot of notifications as {termsToDisplay} are updated.
                </Typography>
              </Alert>
            </Box>
          )}
        </div>
      </Modal>
    </div>
  );
};

SelectResyncTypeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
};
