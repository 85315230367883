import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import {
  Box,
  Flex,
  Modal,
  Input,
  Toggle,
  Typography,
  TypographyVariants,
  NewAlert as Alert,
  tokens,
  NewAlertLevel,
  notification,
} from '@unitoio/mosaic';

import { ContainerAlerts } from 'containers';
import { getFeatureFlagValue } from 'reducers';
import { featureTypes, routes, trackingTypes, providerTypes } from 'consts';
import { useTrackEvent, useGetItemTypes } from 'hooks';
import { PlanFeature } from '~/components/PlanFeature/PlanFeature';
import { Href } from '~/components/Href/Href';

import { MandatoryDeepFiltersMissingAlert } from '../../components/MandatoryDeepFiltersMissingAlert';
import { MappedFieldsAlerts } from '../../components/mapping/alerts/MappedFieldsAlerts';
import { AlreadyActiveFlowAlert } from '../../components/AlreadyActiveFlowAlert';

import { useMandatoryDeepFiltersMissing } from '../../hooks/useMandatoryDeepFiltersMissing';
import { useProviderItemSupportsMerging } from '../../hooks/useProviderItemSupportsMerging';
import { useGetContainerTypes } from '../../hooks/useGetContainerTypes';
import { useHasTestModeEnabled } from '../../hooks/useHasTestModeEnabled';
import { useGetProviderExtraStep } from '../../hooks/useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from '../../hooks/useHasMissingExtraStepAction';
import { useIsAlreadyActiveFlow } from '../../hooks/useIsAlreadyActiveFlow';
import { useGetTestModeTerm } from '../../hooks/useGetTestModeTerm';

import { useDefaultLinkName } from './hooks/useDefaultLinkName';
import { useFetchContainers } from './hooks/useFetchContainers';

import { LaunchFlowButton } from './LaunchFlowButton';
import { LaunchRestrictedAlert } from './components/LaunchRestrictedAlert';

const AutoSyncLabel = styled((props) => <Box as="label" {...props} />)`
  display: inline;
  vertical-align: middle;
`;

function getIsOutlookCalendarTool(providerName, itemType) {
  return (
    providerName === providerTypes.OUTLOOK_CALENDAR_ITEMTYPE.providerName &&
    itemType === providerTypes.OUTLOOK_CALENDAR_ITEMTYPE.itemType
  );
}

const LaunchFlowInnerForm = ({ onClose, onSubmit, onSubmitError }) => {
  const {
    setValue,
    handleSubmit,
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { linkId, workflowId } = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const trackEvent = useTrackEvent();
  // Generate a default flow name based on the selected containers and direction
  // The form data defines the default value, but the user can decide to customize the flow name at this point
  const defaultFlowName = useWatch({ name: 'name' });
  const [flowName, setFlowName] = useState(defaultFlowName);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const watchIsAutoSync = watch('isAutoSync', true);
  const containerIdA = watch('A.containerId');
  const containerIdB = watch('B.containerId');
  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const [containerTypeA, containerTypeB] = useGetContainerTypes(linkId);
  const providerIdentityIdA = watch('A.providerIdentityId');
  const providerIdentityIdB = watch('B.providerIdentityId');

  const isTestModeEnabled = useHasTestModeEnabled();
  const [containersHaveIssues, isCheckingContainers] = useFetchContainers({
    containerIdA,
    containerIdB,
    containerTypeA,
    containerTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    itemTypeA,
    itemTypeB,
    linkId,
  });

  const providerItemAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerItemBExtraStep = useGetProviderExtraStep(providerNameB);
  const containerARequiresExtraConfig = useHasMissingExtraStepAction(
    providerItemAExtraStep,
    providerNameA,
    providerIdentityIdA,
    containerIdA,
  );
  const containerBRequiresExtraConfig = useHasMissingExtraStepAction(
    providerItemBExtraStep,
    providerNameB,
    providerIdentityIdB,
    containerIdB,
  );

  useDefaultLinkName(setValue, watch);
  const [missingDeepFiltersA, missingDeepFiltersB] = useMandatoryDeepFiltersMissing();

  // previously known as isProviderContactBased
  const providerAItemSupportsMerge = useProviderItemSupportsMerging(providerNameA, itemTypeA);
  const providerBItemSupportsMerge = useProviderItemSupportsMerging(providerNameB, itemTypeB);
  const oneItemSupportsMerge = providerAItemSupportsMerge || providerBItemSupportsMerge;
  const bothItemsSupportMerge = providerAItemSupportsMerge && providerBItemSupportsMerge;

  // Needed for Outlook Calendar specifically, to inform the user we don't support syncing recurring events
  const oneProviderItemIsOutlookCalendar =
    getIsOutlookCalendarTool(providerNameA, itemTypeA) || getIsOutlookCalendarTool(providerNameB, itemTypeB);

  const { isAlreadyActiveSync } = useIsAlreadyActiveFlow(linkId);

  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );

  const testModeTerm = useGetTestModeTerm(linkId, true);

  const disableLaunchFlow =
    containerARequiresExtraConfig ||
    containerBRequiresExtraConfig ||
    containersHaveIssues ||
    isAlreadyActiveSync ||
    isSubmitting ||
    errors[trackingTypes.MODULE.TOOL_SELECTION] ||
    errors[trackingTypes.MODULE.FLOW_DIRECTION] ||
    errors[trackingTypes.MODULE.RULES] ||
    errors[trackingTypes.MODULE.MAPPINGS];

  const handleChangeFlowName = (e) => {
    setFlowName(e.target.value);
    // Update the flow name, but don't dirty to avoid triggering an autosave
    setValue('name', e.target.value);
  };

  const handleOnSubmitClick = async () => {
    clearErrors('convert');
    try {
      trackEvent(trackingTypes.SUBMIT);
      setIsSubmitting(true);
      onSubmit();
      await handleSubmit({ convertDraft: true });
      if (watchIsAutoSync) {
        notification.success({
          message: 'Syncing...',
          description: 'Hang tight while your work is syncing. This may take a couple of minutes',
          placement: 'topRight',
        });
      }

      if (workflowId) {
        history.push(`${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${workflowId}`);
        return;
      }

      history.push(match.url);
    } catch (err) {
      setError('convert', { type: 'manual', message: err.message });
      onSubmitError();
    } finally {
      setIsSubmitting(false);
    }
  };

  const isLoading = isCheckingContainers || isSubmitting;

  return (
    <>
      <PlanFeature name={featureTypes.FEATURES.MAX_AUTO_SYNCS}>
        {(isWithinAutoSyncLimit) => (
          <>
            <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
              <Typography variant={TypographyVariants.BODY1}>Name your flow</Typography>
              <Input onChange={handleChangeFlowName} value={flowName || defaultFlowName} />
            </Box>
            <Box
              m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]}
              flexDirection="row"
              alignItems="baseline"
            >
              <div>
                <Controller
                  control={control}
                  name="isAutoSync"
                  render={({ field: { onChange, value, name } }) => (
                    <Toggle
                      id={name}
                      onClick={() => {
                        onChange(!value);
                      }}
                      value={isWithinAutoSyncLimit ? value : false}
                      disabled={!isWithinAutoSyncLimit}
                      data-testid="isAutoSync"
                    />
                  )}
                />
              </div>
              <AutoSyncLabel m={[0, 0, 0, tokens.spacing.s3]} htmlFor="isAutoSync">
                {watchIsAutoSync && isWithinAutoSyncLimit ? (
                  <Typography variant={TypographyVariants.BODY1}>
                    Auto sync is enabled. Changes <b>will sync in the background.</b>
                  </Typography>
                ) : (
                  <Typography variant={TypographyVariants.BODY1}>
                    Auto sync is disabled. Changes <b>will not</b> sync automatically but can be triggered manually from
                    your flow list.
                  </Typography>
                )}
              </AutoSyncLabel>
            </Box>
            {!isWithinAutoSyncLimit && (
              <Alert
                level={NewAlertLevel.WARNING}
                message={
                  <>
                    You have reached the number of auto sync flows you can create with your plan.{' '}
                    <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/pricing`}>Upgrade your plan</Href> to enable this
                    option and automatically sync changes.
                  </>
                }
              />
            )}
          </>
        )}
      </PlanFeature>

      {(containerBRequiresExtraConfig || containerARequiresExtraConfig) && (
        <Alert
          level={NewAlertLevel.ERROR}
          message={
            <>
              You'll need to finish{' '}
              <Link
                onClick={isAddOnImprovements ? onClose : undefined}
                to={generatePath(match.path, {
                  ...match.params,
                  pageName: isAddOnImprovements
                    ? routes.FLOW_BUILDER_PAGES.GUIDE
                    : routes.FLOW_BUILDER_PAGES.TOOL_SELECTION,
                })}
              >
                setting up the Unito add-on
              </Link>{' '}
              before launching your flow.
            </>
          }
        />
      )}

      {isTestModeEnabled && (
        <Alert
          level={NewAlertLevel.INFO}
          message={
            <>
              This flow will only sync {testModeTerm} created items.{' '}
              <Link to={`${match.url}/rules`}>Change your rules</Link> to sync all items.
            </>
          }
        />
      )}
      {oneProviderItemIsOutlookCalendar && (
        <Alert
          level={NewAlertLevel.INFO}
          message={
            <>
              <b>Recurring events are not yet supported.</b> If your calendar includes a recurring event, only the first
              occurrence will sync.
            </>
          }
        />
      )}
      {
        // Show the manage duplicates modal if creating a flow between a contact based
        // (mergeable) and non contact based (non-mergeable) item only.
        oneItemSupportsMerge && !bothItemsSupportMerge && (
          <Alert
            level={NewAlertLevel.WARNING}
            message={
              <>
                Duplicate contacts will not be merged. This may cause near identical contacts to be created if a
                matching one already exists.
              </>
            }
          />
        )
      }
      {errors.convert && (
        <Flex flex="1" style={{ margin: `${tokens.spacing.s4} 0` }}>
          <Alert level={NewAlertLevel.ERROR} message={errors?.convert?.message} />
        </Flex>
      )}
      {(errors.containerA || errors.containerB) && (
        <Flex flex="1" style={{ margin: `${tokens.spacing.s4} 0` }}>
          <LaunchRestrictedAlert
            containerErrorNameA={errors.containerA?.message}
            containerErrorNameB={errors.containerB?.message}
            containerIdA={containerIdA}
            containerIdB={containerIdB}
            containerTypeA={containerTypeA}
            containerTypeB={containerTypeB}
            providerIdentityIdA={providerIdentityIdA}
            providerIdentityIdB={providerIdentityIdB}
          />
        </Flex>
      )}
      <AlreadyActiveFlowAlert linkId={linkId} />

      {/* START - container alerts */}
      <ContainerAlerts severity="warning" providerIdentityId={providerIdentityIdA} containerId={containerIdA} />
      <ContainerAlerts severity="error" providerIdentityId={providerIdentityIdA} containerId={containerIdA} />
      <ContainerAlerts severity="warning" providerIdentityId={providerIdentityIdB} containerId={containerIdB} />
      <ContainerAlerts severity="error" providerIdentityId={providerIdentityIdB} containerId={containerIdB} />
      {/* END - container alerts */}

      {/* START - Rules Page alerts */}
      {missingDeepFiltersA && <MandatoryDeepFiltersMissingAlert side="A" />}
      {missingDeepFiltersB && <MandatoryDeepFiltersMissingAlert side="B" />}
      {/* END - Rules Page alerts */}

      {/* START - Mappings alerts */}
      <MappedFieldsAlerts linkId={linkId} />

      {errors[trackingTypes.MODULE.MAPPINGS]?.lastAssociationNotCompleted && (
        <Alert
          level={NewAlertLevel.WARNING}
          message={`In order for this flow to sync properly, you will need to complete or remove the field association
                    with the missing field in your field mappings page.`}
        />
      )}
      {/* END - Mappings alerts */}

      <Flex
        flex="1"
        justify="flex-end"
        style={{ margin: `${tokens.spacing.s4} ${tokens.spacing.s0} ${tokens.spacing.s0} ${tokens.spacing.s0}` }}
      >
        <LaunchFlowButton
          onClose={onClose}
          onLaunch={handleOnSubmitClick}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          isLaunchDisabled={!!disableLaunchFlow}
        />
      </Flex>
    </>
  );
};

LaunchFlowInnerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
};

export const LaunchFlow = ({ onCloseModal, isOpen }) => {
  const [canDisplayCloseButton, setCanDisplayCloseButton] = useState(true);
  const onSubmit = () => {
    setCanDisplayCloseButton(false);
  };
  const onError = () => {
    setCanDisplayCloseButton(true);
  };
  return (
    <Modal
      title="Ready to launch your flow?"
      isOpen={isOpen}
      displayCloseButton={canDisplayCloseButton}
      onCancel={onCloseModal}
      size={Modal.sizes.LG}
      hideButtons
      onRequestClose={onCloseModal}
    >
      <LaunchFlowInnerForm onClose={onCloseModal} onSubmit={onSubmit} onSubmitError={onError} />
    </Modal>
  );
};

LaunchFlow.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
