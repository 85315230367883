import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { useQueryParams } from 'hooks';
import { Button } from '~/components/Button/Button';
import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';
import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { AuthButtonsWrapper } from '../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../AuthTitleWrapper';
import { AuthOrderedList } from '../AuthOrderedList';
import { AuthImageWrapper } from '../AuthImageWrapper';
import gitlabOAuthApp1 from './gitlab-oauth-application-1.png';
import oldGitlabOAuthApp1 from './old-gitlab-oauth-application-1.png';

function getAuthCallbackURL() {
  if (['localhost', 'development'].includes(process.env.REACT_APP_ENV)) {
    return 'http://localhost:3000/api/auth/gitlab/callback';
  }

  if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://staging-app.unito.io/api/auth/gitlab/callback';
  }

  return 'https://app.unito.io/api/auth/gitlab/callback';
}

function AuthGitlabOAuthAppStep1Component({ domain, history, newUI, onCancel, switchUI, trackEvent }) {
  const { windowOpenerId } = useQueryParams();
  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (OAuth Application - Step 1 of 2)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Gitlab requires you to setup your <strong>OAuth Application</strong> for Unito.
        </p>
        <AuthImageWrapper className="clearfix">
          <img
            src={newUI ? gitlabOAuthApp1 : oldGitlabOAuthApp1}
            alt="Gitlab OAuth Application Step - Step 1"
            width="555"
            height="100%"
          />
          <Button noPadding size="xs" pullRight btnStyle="link" onClick={switchUI}>
            Not your Gitlab version? Switch instructions for {newUI ? 'v11 or less' : 'v12'}
          </Button>
        </AuthImageWrapper>
        <AuthOrderedList>
          <li>
            Open <Href href={`${domain}/profile/applications`}>Profile Settings {'>'} Applications</Href> to add an
            Application Link
          </li>
          <li>
            In the OAuth Application form, complete these fields and leave the others blank:
            <ul>
              <li>
                Application Name: <CopyToClipboard>Unito Sync</CopyToClipboard>
              </li>
              <li>
                Redirect URI: <CopyToClipboard>{getAuthCallbackURL()}</CopyToClipboard>
              </li>
              <li>
                Scopes: <CopyToClipboard>api</CopyToClipboard>
              </li>
              <li>
                Click <strong>{newUI ? 'Submit' : 'Save application'}</strong>
              </li>
            </ul>
          </li>
        </AuthOrderedList>
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('oauth app onboarding 1 (of 2)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthGitlabOAuthAppStep1Component.propTypes = {
  domain: PropTypes.string.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  newUI: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  switchUI: PropTypes.func.isRequired,
};

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => ({
  domain: formSelector(state, 'domain'),
});

export const AuthGitlabOAuthAppStep1 = connect(mapStateToProps)(AuthGitlabOAuthAppStep1Component);
