import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { Avatar, Box, Button, Link, LoadingPlaceholder, tokens, Typography } from '@unitoio/mosaic';

import { billingTypes, featureTypes, routes, trackingTypes } from 'consts';
import { useTrackEvent } from 'hooks';
import {
  getMaxUsageFeatureToDisplay,
  getOrganizationById,
  getOrganizationPlanProfile,
  getPausedDate,
  isEnterpriseAccount,
  isOnFreeTrial,
  isOnMirrorLegacy,
  isOrganizationAccountCanceled,
  isOrganizationAccountPaused,
  isOrganizationAccountPaying,
  isOrganizationAccountScheduledToPause,
  isOrganizationChurned,
  isOrganizationResold,
  isOrganizationTrialExpired,
  isVipAccount,
} from 'reducers';
import { capitalize } from 'utils';
import { Divider } from '~/components/Divider/Divider';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';
import { getTierFromPlan } from '../../utils';

import { FeatureLimit } from './FeatureLimit';
import { ResumeSubscriptionButton } from '../ResumeSubscriptionButton/ResumeSubscriptionButton';
import CSAvatar from '../../images/cs_avatar.svg';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonBox = styled(Box)`
  margin-left: auto;
`;

const NoUnderlineLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const getPlanName = (organizationStatus, plan) => {
  if (organizationStatus.isResold) {
    return 'Your plan';
  }

  if (organizationStatus.isTrialing || organizationStatus.isTrialExpired) {
    return 'Trial';
  }

  if (organizationStatus.isEnterprise) {
    return 'Enterprise plan';
  }

  if (organizationStatus.isVip) {
    return 'VIP plan';
  }

  if (organizationStatus.isMirrorLegacy) {
    return `Mirror ${capitalize(getTierFromPlan(plan))} plan`;
  }

  const tier = getTierFromPlan(plan);
  if (!tier) {
    return plan.get('nickname');
  }

  return `${capitalize(tier)} plan`;
};

const getFeatureName = (organizationStatus, valueMetricId) => {
  if (organizationStatus.isMirrorLegacy) {
    return featureTypes.FEATURES.MAX_MIRROR_SYNCS;
  }

  return valueMetricId === featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC
    ? featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC
    : featureTypes.FEATURES.MAX_USERS;
};

const getPlanIncludesLine = (organizationStatus, valueMetricId, plan) => {
  if (organizationStatus.isChurned || organizationStatus.isPaused) {
    return (
      <Typography variant={Typography.variants.BODY1}>
        <Bold>No active plan</Bold> at the moment
      </Typography>
    );
  }

  const planName = getPlanName(organizationStatus, plan);
  const including = organizationStatus.isResold ? ' includes ' : ' including ';
  const featureName = getFeatureName(organizationStatus, valueMetricId);

  return (
    <Typography variant={Typography.variants.BODY1}>
      <Bold>{planName}</Bold>
      {including}
      <Bold>
        <FeatureLimit featureName={featureName} />
      </Bold>
    </Typography>
  );
};

const isAboveTeamPlan = (plan) => {
  const tier = getTierFromPlan(plan);
  return [billingTypes.PLAN_TYPES.BUSINESS, billingTypes.PLAN_TYPES.COMPANY, billingTypes.PLAN_TYPES.PRO].includes(
    tier,
  );
};

const getRenewalLine = (organizationStatus, orgPlan, org, pausedDate) => {
  const validUntil = org.get('validUntil');
  const formattedValidUntilDate = moment(validUntil).format('MMM DD, YYYY');
  const billingInterval = orgPlan.get('interval') === billingTypes.PLAN_INTERVALS.YEAR ? 'yearly' : 'monthly';

  if (organizationStatus.isResold) {
    return null;
  }

  let renewalLine = `$${
    billingInterval === 'yearly' ? orgPlan.get('amount') / 100 / 12 : orgPlan.get('amount') / 100
  } per month, billed ${billingInterval}. Plan renews on ${formattedValidUntilDate}`;

  if (organizationStatus.isTrialing) {
    const daysLeft = moment(validUntil).fromNow(true);
    renewalLine = `Trial expires in ${daysLeft}`;
  }

  if (organizationStatus.isTrialExpired) {
    const daysExpired = moment(validUntil).fromNow();
    renewalLine = `Trial expired ${daysExpired}`;
  }

  if (organizationStatus.isPaused) {
    // Multiplying by 1000 because the value is in second and moment uses ms
    renewalLine = `Plan was paused on ${moment(pausedDate * 1000).format(
      'MMM DD, YYYY',
    )} and will be cancelled on ${formattedValidUntilDate}`;
  }

  if (organizationStatus.isEnterprise || organizationStatus.isCancelled || organizationStatus.isVip) {
    renewalLine = `Plan active until ${formattedValidUntilDate}`;
  }

  if (organizationStatus.willPause) {
    renewalLine = `Plan will be paused on ${formattedValidUntilDate}`;
  }

  if (organizationStatus.isChurned) {
    renewalLine = `Plan was cancelled on ${formattedValidUntilDate}`;
  }

  return (
    <Typography variant={Typography.variants.BODY1} color={tokens.colors.content.neutral.n30}>
      {renewalLine}
    </Typography>
  );
};

const getButtonCTA = (organizationStatus, organizationId, trackEvent) => {
  if (organizationStatus.willPause) {
    return <ResumeSubscriptionButton organizationId={organizationId}>Resume subscription</ResumeSubscriptionButton>;
  }

  if (organizationStatus.isCancelled || organizationStatus.isEnterprise || organizationStatus.isVip) {
    return (
      <ChatBubble
        onClick={() =>
          trackEvent(trackingTypes.ACTION, {
            action_name: 'clicked on contact us (plan enterprise)',
          })
        }
        type="default"
      >
        Contact us
      </ChatBubble>
    );
  }
  return (
    <NoUnderlineLink href={`#${routes.ABSOLUTE_PATHS.PROJECT_SYNC_PRICING(organizationId)}`}>
      <Button
        onClick={() =>
          trackEvent(trackingTypes.ACTION, {
            action_name: 'clicked on see pricing',
          })
        }
      >
        See pricing
      </Button>
    </NoUnderlineLink>
  );
};

const shouldDisplayOnboardingCallMessage = (organizationStatus, orgPlan) =>
  isAboveTeamPlan(orgPlan) && organizationStatus.isPaying && !organizationStatus.willPause;

export const PlanBlock = ({ isLoadingBillingOrganization }) => {
  const { organizationId } = useParams();
  const trackEvent = useTrackEvent();
  const orgPlan = useSelector((state) => getOrganizationPlanProfile(state));
  const organization = useSelector((state) => getOrganizationById(state, organizationId));

  const valueMetricId = useSelector((state) => getMaxUsageFeatureToDisplay(state, organizationId)).get('id');

  const isEnterprise = useSelector((state) => isEnterpriseAccount(state, organizationId));
  const isVip = useSelector((state) => isVipAccount(state, organizationId));
  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isCancelled = useSelector((state) => isOrganizationAccountCanceled(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));
  const isChurned = useSelector((state) => isOrganizationChurned(state, organizationId));
  const isResold = useSelector((state) => isOrganizationResold(state, organizationId));
  const isPaused = useSelector((state) => isOrganizationAccountPaused(state, organizationId));
  const willPause = useSelector((state) => isOrganizationAccountScheduledToPause(state, organizationId));
  const isPaying = useSelector((state) => isOrganizationAccountPaying(state, organizationId));
  const isMirrorLegacy = useSelector((state) => isOnMirrorLegacy(state, organizationId));
  const pausedDate = useSelector((state) => getPausedDate(state, organizationId));

  const organizationStatus = {
    isEnterprise,
    isVip,
    isTrialing,
    isCancelled,
    isTrialExpired,
    isChurned,
    isResold,
    isPaused,
    willPause,
    isPaying,
    isMirrorLegacy,
  };

  if (isLoadingBillingOrganization) {
    return <LoadingPlaceholder width="100%" height="6.25rem" borderRadius={tokens.spacing.s4} />;
  }

  return (
    <Box borderRadius={tokens.spacing.s4} borderSize={1} fullWidth p={[tokens.spacing.s5]}>
      <Box flexDirection={Box.flexDirection.ROW}>
        <Box>
          {getPlanIncludesLine(organizationStatus, valueMetricId, orgPlan)}
          {getRenewalLine(organizationStatus, orgPlan, organization, pausedDate)}
        </Box>
        {!isResold && <ButtonBox>{getButtonCTA(organizationStatus, organizationId, trackEvent)}</ButtonBox>}
      </Box>

      {shouldDisplayOnboardingCallMessage(organizationStatus, orgPlan) && (
        <>
          <Box>
            <Divider />
          </Box>
          <Box flexDirection={Box.flexDirection.ROW}>
            <Box>
              <Avatar image={CSAvatar} name="Support" size="lg" />
            </Box>
            <Box m={[tokens.spacing.s2, 0, 0, tokens.spacing.s3]}>
              <Typography variant={Typography.variants.BODY1}>
                Chat about your workflow with one of Unito's experts and bring it to the next level.{' '}
                <Link
                  isExternalLink
                  href="https://meetings.hubspot.com/unitoteam/book-a-call-with-a-workflow-expert-in-app-link"
                  onClick={() =>
                    trackEvent(trackingTypes.ACTION, {
                      action_name: 'clicked on book a call',
                    })
                  }
                >
                  Book a call
                </Link>
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

PlanBlock.propTypes = {
  isLoadingBillingOrganization: PropTypes.bool.isRequired,
};
