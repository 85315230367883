import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Skeleton } from '@unitoio/mosaic';

import { providerIdentityActions } from 'actions';
import { useTrackEvent } from 'hooks';
import { getIsLoadedProviders, getProvidersThatCanConnect, getUserProviderIdentities } from 'reducers';
import { trackingTypes } from 'consts';
import { ProviderList } from '~/components/ProviderList/ProviderList';
import { Href } from '~/components/Href/Href';
import { FatalError } from '~/components/FatalError/FatalError';

export const ConnectorList = () => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const onDisconnectProviderIdentity = (providerIdentityId) =>
    dispatch(providerIdentityActions.disconnectProviderIdentity(providerIdentityId));

  const hasProviderIdentityFatalError = useSelector((state) => state.providerIdentities.get('hasFatalError'));
  const providerList = useSelector((state) => getProvidersThatCanConnect(state));
  const providersLoaded = useSelector((state) => getIsLoadedProviders(state));
  const providerIdentityList = useSelector((state) => getUserProviderIdentities(state));

  const renderProviderList = () => {
    if (hasProviderIdentityFatalError) {
      return <FatalError title="Sorry, we couldn’t load your accounts." />;
    }

    if (!providersLoaded) {
      return <Skeleton />;
    }

    if (providerList.isEmpty()) {
      return null;
    }

    return (
      <ProviderList
        providerList={providerList}
        providerIdentityList={providerIdentityList}
        onDisconnectProviderIdentity={onDisconnectProviderIdentity}
        trackEvent={trackEvent}
      />
    );
  };

  return (
    <>
      {renderProviderList()}
      <Box m={[1, 0, 3]}>
        Looking for another integration?{' '}
        <Href
          onClick={() =>
            trackEvent(trackingTypes.CONNECTORS.ACTION_NAME, {
              action_name: trackingTypes.CONNECTORS.ACTIONS.UPCOMING_INTEGRATIONS,
            })
          }
          href="https://unito.io/connectors/"
        >
          Vote for it.
        </Href>
      </Box>
    </>
  );
};
