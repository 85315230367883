import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PortWidget, DiagramEngine, NodeModel } from '@projectstorm/react-diagrams';

import { Icon, ProviderIcon } from '@unitoio/mosaic';

import { color, fontSize, lineHeight } from 'theme';
import { containerTypes, workflowTypes } from 'consts';
import { getProviderByName } from 'reducers';
import { Box } from '~/components/Box/Box';
import { IconHoverTooltip } from '~/components/IconHoverTooltip/IconHoverTooltip';
import { Avatar } from '~/components/Avatar/Avatar';

import { WorkBlockStyled } from './WorkBlockStyled';
import { WorkBlockPort } from './WorkBlockPort';

const Badge = styled.a`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  position: absolute;
  top: 0.5rem;
  width: 2rem;
`;

const TrashAction = styled(Badge)`
  color: ${color.content.secondary.default};
  right: 0.5rem;

  &:hover {
    background-color: ${color.content.secondary.disabled};
  }
`;

const ContainerStatus = styled(Badge)`
  color: ${(props) =>
    containerTypes.CONTAINER_STATUSES_VALUES[props.$containerStatus]?.color || color.content.neutral.white};
  left: 0.5rem;
  &:hover {
    color: ${(props) =>
      containerTypes.CONTAINER_STATUSES_VALUES[props.$containerStatus]?.color || color.content.neutral.white};
    text-decoration: none;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: help;
`;

// This prevents the Icon from actually emitting a 'click' event which would sometimes clash with the
// wrapping <TrashAction /> (where the onClick is defined) and would throw an error. This error was caught
// during the sanity check related to the React 17 upgrade, but is most probably not related the upgrade.
const IconWrapper = styled(Icon)`
  pointer-events: none;
`;

// Using interpolation https://styled-components.com/docs/advanced#caveat
const StyledBlock = styled(WorkBlockStyled)`
  font-size: ${fontSize.f7};
  line-height: ${lineHeight.lh4};
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  ${TrashAction},
  ${WorkBlockPort} {
    transition:
      opacity 0.2s,
      background-color 0.2s;
    opacity: ${(props) => (props.$isHovered ? 1 : 0)};
  }

  background: ${(props) =>
    containerTypes.CONTAINER_STATUSES_VALUES[props.$containerStatus]?.backgroundColor || color.content.neutral.white};
  border-color: ${(props) =>
    containerTypes.CONTAINER_STATUSES_VALUES[props.$containerStatus]?.color || color.content.secondary.default};
  box-shadow: ${(props) =>
    props.$isHovered ? '0 5px 26px rgba(0, 0, 0, 0.12), 0px 14px 21px rgba(0, 0, 0, 0.14)' : 'none'};
  transition: box-shadow 0.2s;
`;

const ContainerName = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer;
  display: -webkit-box;
  max-width: 7rem;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const ClickThroughBox = styled(Box)`
  pointer-events: none;
  position: relative;
`;

const AvatarContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: -1.3rem;
`;

// Using interpolation https://styled-components.com/docs/advanced#caveat
// Workaround for cyclic import issue https://github.com/styled-components/styled-components/issues/1449
const WorkBlockPortStyled = styled((props) => <WorkBlockPort {...props} />)``;

export const WorkBlock = ({
  containerName,
  containerStatus = containerTypes.CONTAINER_STATUSES.DEFAULT,
  onDeleteClick,
  providerName,
  node,
  engine,
  disabled = false,
  avatarUrl = '',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const providerInfos = useSelector((state) => getProviderByName(state, providerName));
  const providerContainerTerm = providerInfos.getIn(['capabilities', 'terms', 'container', 'singular']);
  const { getTooltip = () => '' } = containerTypes.CONTAINER_STATUSES_VALUES[containerStatus] || {};
  const iconTooltip = providerContainerTerm && getTooltip(providerContainerTerm);
  const ContainerStatusIcon = iconTooltip ? (
    <IconHoverTooltip
      icon={{ name: containerTypes.CONTAINER_STATUSES_VALUES[containerStatus]?.iconName }}
      placement="top"
    >
      {iconTooltip}
    </IconHoverTooltip>
  ) : (
    <StyledIcon name={containerTypes.CONTAINER_STATUSES_VALUES[containerStatus]?.iconName || 'question-circle'} />
  );
  return (
    <StyledBlock
      $containerStatus={containerStatus}
      $isHovered={isHovered}
      onMouseOut={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
    >
      <ClickThroughBox $m={[3, 0, 0, 0]}>
        <Box $m={[0, 0, 0.25, 0]}>
          <ProviderIcon name={providerName} />
          <AvatarContainer>
            <Avatar src={avatarUrl} iconName="user" colorScheme={node.isPlaceholder() ? 'placeholder' : 'dark'} />
          </AvatarContainer>
        </Box>
      </ClickThroughBox>
      <ContainerName title={containerName}>{containerName}</ContainerName>

      <TrashAction
        role="button"
        className="block-of-work-trash"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      >
        <IconWrapper name="trash" kind={Icon.KINDS.SOLID} />
      </TrashAction>
      {containerStatus !== containerTypes.CONTAINER_STATUSES.DEFAULT && (
        <ContainerStatus $containerStatus={containerStatus}>{ContainerStatusIcon}</ContainerStatus>
      )}
      {Object.values(workflowTypes.PORT_POSITIONS).map((position) =>
        position !== workflowTypes.PORT_POSITIONS.CENTER ? (
          <PortWidget
            style={workflowTypes.PORT_CSS_VALUES[position]}
            engine={engine}
            key={position}
            port={node.getPort(position)}
          >
            <WorkBlockPortStyled
              key={position}
              disabled={disabled}
              position={position}
              onClick={() => {}}
              color={containerTypes.CONTAINER_STATUSES_VALUES[containerStatus]?.color}
            />
          </PortWidget>
        ) : (
          <PortWidget
            style={workflowTypes.PORT_CSS_VALUES[position]}
            engine={engine}
            key={position}
            port={node.getPort(position)}
          />
        ),
      )}
    </StyledBlock>
  );
};

WorkBlock.propTypes = {
  containerName: PropTypes.string.isRequired,
  containerStatus: PropTypes.oneOf(Object.values(containerTypes.CONTAINER_STATUSES)),
  onDeleteClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  providerName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  node: PropTypes.instanceOf(NodeModel).isRequired,
  engine: PropTypes.instanceOf(DiagramEngine).isRequired,
};
