import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import Cookies from 'js-cookie';

import { Box, Button, tokens } from '@unitoio/mosaic';

import { authTypes, trackingTypes } from 'consts';
import { useQueryParams, useLogger } from 'hooks';
import { Href } from '~/components/Href/Href';
import { Title } from '~/components/Title/Title';
import { Alert } from '~/components/Alert/Alert';

import { AuthTitleWrapper } from './AuthTitleWrapper';

const STATUS = {
  FAILURE: 'failure',
  SUCCESS: 'success',
};

const AuthButton = styled(Button)`
  margin: 0 ${tokens.spacing.s4};
`;

const SuccessMessage = styled.div`
  text-align: center;
`;

function getSubmitText(status, isWindowOpener) {
  if (status === STATUS.FAILURE) {
    return 'Try again';
  }

  return isWindowOpener ? 'Got it' : 'Start Syncing';
}

export function AuthResult({
  history,
  match: {
    params: { result, providerName: locationProviderName },
  },
  onCancel,
  onSubmit,
  provider,
  trackEvent,
}) {
  const { reportException, reportWarning } = useLogger();
  const { message: errorMessage, providerIdentityId, status: errorStatus, windowOpenerId } = useQueryParams();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const providerTroubleshootingLink = authTypes.TROUBLESHOOTING_LINKS[provider.get('name')];

  useEffect(() => {
    if (errorMessage) {
      trackEvent(trackingTypes.BLOCKED, { reason: errorMessage, failedValidation: true });
      if (errorStatus < 500) {
        reportWarning(errorMessage, { identifier: 'authError useEffect AuthResult' });
      } else {
        reportException(errorMessage, { identifier: 'authError useEffect AuthResult' });
      }
      return;
    }

    const token = Cookies.get(process.env.REACT_APP_UNITO_AUTH_COOKIE_NAME);
    const origin = '*';
    window.opener &&
      window.opener.postMessage(
        {
          type: authTypes.POST_MESSAGE_TYPES.PROVIDER_IDENTITY,
          providerIdentityId,
          windowOpenerId,
          // The token is needed when a power-up opens the auth module
          // in a popup so we can authenticate the user to Unito
          token,
          success: true,
        },
        // FIXME: Potential security issue, we should be able to send the origin as
        // a domain *.unito.io
        origin,
      );
  }, [
    provider,
    errorMessage,
    errorStatus,
    providerIdentityId,
    reportException,
    reportWarning,
    trackEvent,
    windowOpenerId,
  ]);
  const isWindowOpener = !!window.opener || !!windowOpenerId;

  function handleSubmit() {
    setHasSubmitted(true);
    if (result === STATUS.FAILURE) {
      onSubmit();
      return;
    }

    if (isWindowOpener) {
      window.close();
      return;
    }

    history.push('/dashboard');
  }

  const providerDisplayName =
    provider.get('displayName') || (locationProviderName === 'auth0' ? 'email' : locationProviderName);

  return (
    <div className="auth-result">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          {result === STATUS.SUCCESS
            ? `${providerDisplayName} is successfully connected`
            : `The connection with ${providerDisplayName} failed`}
        </Title>
      </AuthTitleWrapper>
      {result === STATUS.FAILURE && errorMessage && (
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Alert level="error">{errorMessage}</Alert>
        </Box>
      )}
      {result === STATUS.SUCCESS ? (
        <SuccessMessage>You’re all set to start creating a flow with {providerDisplayName} and syncing.</SuccessMessage>
      ) : (
        <div>
          Please, make sure that you configured {providerDisplayName} correctly and try again. You can find more details
          in the{' '}
          <Href href={providerTroubleshootingLink || 'https://guide.unito.io/troubleshooting-shared-connector-errors'}>
            troubleshooting
          </Href>{' '}
          article in the Unito Guide.
        </div>
      )}

      <Box m={[tokens.spacing.s7, 0, tokens.spacing.s4, 0]} justifyContent="center" className="clearfix">
        {result === STATUS.FAILURE && (
          <AuthButton variant="subtle" onClick={onCancel}>
            Cancel
          </AuthButton>
        )}
        <AuthButton
          variant="primary"
          onClick={handleSubmit} // eslint-disable-line react/jsx-no-bind
          disabled={hasSubmitted}
          data-test="auth__btn--authentication-success"
        >
          {getSubmitText(result, isWindowOpener)}
        </AuthButton>
      </Box>
    </div>
  );
}

AuthResult.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ result: PropTypes.oneOf(Object.values(STATUS)), providerName: PropTypes.string }),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
  trackEvent: PropTypes.func.isRequired,
};
