import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { hasOrganizationTrelloTeamSetup, isUserConnectedToTrello } from 'reducers';
import { LinkList } from '~/components/LinkList/LinkList';
import { MirrorPowerUpPromotionalState } from '~/components/PromotionalStates/MirrorPowerUpPromotionalState';

import { ContentEmptyState } from './ContentEmptyState';
import { LinkItemsPlaceholder } from './LinkItemsPlaceholder';
import { SyncListSearchInput } from './SyncListSearchInput';
import { TaskSyncEmptyState } from './TaskSyncEmptyState';
import { linkTypes } from '../../consts';

// TODO implement pagination + backend search for this component
export const TaskSyncList = ({
  embedName,
  linkList,
  isLoading,
  selectedOrganizationId,
  trackDashboardAction,
  userId,
  onSetKinds,
}) => {
  const [searchString, setSearchString] = useState('');
  const hasTrelloTeamSetup = useSelector((state) => hasOrganizationTrelloTeamSetup(state));
  const userIsConnectedToTrello = useSelector((state) => isUserConnectedToTrello(state));

  useEffect(() => {
    onSetKinds([linkTypes.KIND.TASK_SYNC]);
  }, [onSetKinds]);

  if (userIsConnectedToTrello && !hasTrelloTeamSetup && linkList.isEmpty()) {
    return <MirrorPowerUpPromotionalState trackEvent={trackDashboardAction} organizationId={selectedOrganizationId} />;
  }

  // TODO implement the logic to make this search backend based when pagination is active.
  const filteredTaskSyncLinkList = linkList.filter((link) =>
    link
      .get('name', '')
      .toLowerCase()
      .includes(searchString?.toLowerCase() ?? ''),
  );

  if (isLoading) {
    return <LinkItemsPlaceholder />;
  }

  if (filteredTaskSyncLinkList.isEmpty() && !searchString) {
    return <TaskSyncEmptyState {...{ embedName, selectedOrganizationId, trackDashboardAction }} />;
  }

  return (
    <>
      <SyncListSearchInput searchString={searchString} onChange={setSearchString} />
      {searchString && filteredTaskSyncLinkList.isEmpty() ? (
        <ContentEmptyState searchString={searchString} />
      ) : (
        <LinkList embedName={embedName} linkList={filteredTaskSyncLinkList} userId={userId} />
      )}
    </>
  );
};

TaskSyncList.propTypes = {
  embedName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  linkList: PropTypes.instanceOf(List).isRequired,
  selectedOrganizationId: PropTypes.string.isRequired,
  trackDashboardAction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  onSetKinds: PropTypes.func.isRequired,
};
