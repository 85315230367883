import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';

import { Box, Button, tokens } from '@unitoio/mosaic';

import { color } from 'theme';
import { formUtils } from 'utils';
import { ProviderIdentityItem } from '~/components/ProviderIdentityItem/ProviderIdentityItem';

const ProviderIdentityList = styled.ul`
  list-style: none;
  margin-top: ${tokens.spacing.s4};
  padding: 0;
`;

const StyledBox = styled(Box)`
  ${(props) => (props.$isDisabled ? 'opacity: 0.6' : `border: 1px solid ${color.brand.containerGray}`)};
`;

const StyledButton = styled(Button)`
  float: right;
  opacity: 0;

  &:hover {
    opacity: ${(props) => (props.$allowAction ? '1' : '0')};
  }
`;

function removeFromFields(fields, providerIdentityId) {
  const mergedProviderIdentityIds = formUtils.reduxFormFieldsGetAll(fields);
  const index = mergedProviderIdentityIds.findIndex((id) => id === providerIdentityId);
  if (index > -1) {
    fields.remove(index);
  }
}

export const Tools = ({ providerIdentities, fields, isMerge }) => {
  const mergedProviderIdentityIds = formUtils.reduxFormFieldsGetAll(fields);

  return (
    <ProviderIdentityList>
      {providerIdentities
        .map((providerIdentity) => {
          const isEnabled = mergedProviderIdentityIds.includes(providerIdentity.get('_id'));
          const allowAction = !isMerge && mergedProviderIdentityIds.length > 1;
          return (
            <StyledBox
              p={[tokens.spacing.s3, tokens.spacing.s0, tokens.spacing.s3, tokens.spacing.s4]}
              borderSize="1"
              m={[tokens.spacing.s3, tokens.spacing.s0]}
              borderColor={tokens.colors.content.neutral.n10}
              borderRadius={tokens.spacing.s3}
              borderStyle="solid"
              key={providerIdentity.get('_id')}
              $isDisabled={!isEnabled}
            >
              <ProviderIdentityItem
                isDisabled={!isMerge}
                providerIdentity={providerIdentity}
                displayType="email"
                needsValidate={false}
              >
                <StyledButton
                  $allowAction={allowAction || !isEnabled}
                  onClick={() =>
                    isEnabled
                      ? removeFromFields(fields, providerIdentity.get('_id'))
                      : fields.push(providerIdentity.get('_id'))
                  }
                  size={Button.sizes.SM}
                  startIcon="link-slash"
                  variant={Button.variants.SUBTLE_DESCTRUCTIVE}
                  disabled={!allowAction}
                >
                  {isEnabled ? 'Remove' : 'Add'}
                </StyledButton>
              </ProviderIdentityItem>
            </StyledBox>
          );
        })
        .toArray()}
    </ProviderIdentityList>
  );
};

Tools.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMerge: PropTypes.bool.isRequired,
  providerIdentities: PropTypes.instanceOf(Map).isRequired,
};
