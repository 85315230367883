import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Map } from 'immutable';

import {
  Avatar,
  Modal,
  ProviderIcon,
  tokens,
  Typography,
  TypographyVariants,
  NewAlert as Alert,
  NewAlertLevel,
} from '@unitoio/mosaic';

import { providerIdentityTypes } from 'consts';
import { AuthWindowOpener } from 'containers';
import { Href } from '~/components/Href/Href';

const Section = styled.div`
  margin-bottom: ${tokens.spacing.s4};
`;

const Item = styled.div`
  display: inline-block;
  margin-right: ${tokens.spacing.s3};
`;

const CapitalizeTypography = styled(Typography)`
  text-transform: capitalize;
`;

const Header = ({ headerValue }) => (
  <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
    {headerValue}
  </Typography>
);
Header.propTypes = {
  headerValue: PropTypes.string.isRequired,
};

const AccountTextWrapper = styled.div`
  display: inline-block;
  position: relative;
  ${(props) => props.$image && `top: -${tokens.spacing.s2};`}
`;

export class AddProviderModal extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    disconnect: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    provider: PropTypes.instanceOf(Map).isRequired,
    providerIdentity: PropTypes.instanceOf(Map).isRequired,
  };

  onDisconnect = () => {
    const { disconnect, providerIdentity } = this.props;
    disconnect(providerIdentity.get('_id'));
  };

  render() {
    const { closeModal, isOpen, provider, providerIdentity } = this.props;

    if (providerIdentity.isEmpty()) {
      return null;
    }

    const providerIdentityId = providerIdentity.get('_id');
    const avatarUrl = providerIdentity.getIn(['profileAvatars', 0]);
    const displayName = providerIdentity.get('profileDisplayName');
    const username = providerIdentity.get('profileUsername');
    const email = providerIdentity.getIn(['profileEmails', 0]);
    const domain = providerIdentity.get('domain');
    const providerIdentityState = providerIdentity.get('state');
    const isDisabled =
      providerIdentityState === providerIdentityTypes.STATE.DISABLED ||
      providerIdentityState === providerIdentityTypes.STATE.INCAPACITATED;

    return (
      <Modal
        confirmLabel="Disconnect"
        isConfirmActionDestructive
        isOpen={isOpen}
        onCancel={closeModal}
        onConfirm={this.onDisconnect}
        onRequestClose={closeModal}
        title="Account information"
      >
        <Section>
          <Header headerValue="Integration" />
          <Item>
            <ProviderIcon name={provider.get('name')} size="default" />
          </Item>
          <Item>
            <CapitalizeTypography>{provider.get('displayName')}</CapitalizeTypography>
          </Item>
        </Section>

        <Section>
          <Header headerValue="Account" />
          <Item>
            <Avatar image={avatarUrl} name={displayName} size="md" />
          </Item>
          <AccountTextWrapper $image={avatarUrl}>
            <Typography variant={TypographyVariants.BODY1}>{displayName}</Typography>
          </AccountTextWrapper>
        </Section>

        {username && (
          <Section>
            <Header headerValue="Username" />
            <Typography variant={TypographyVariants.BODY1}>{username}</Typography>
          </Section>
        )}

        {email && (
          <Section>
            <Header headerValue="Email" />
            <Typography variant={TypographyVariants.BODY1}>{email}</Typography>
          </Section>
        )}

        {domain && (
          <Section>
            <Header headerValue="Domain" />
            <Typography variant={TypographyVariants.BODY1}>{domain}</Typography>
          </Section>
        )}

        {isDisabled && (
          <Alert
            level={NewAlertLevel.WARNING}
            message={
              <>
                We are having trouble accessing this account.{' '}
                <AuthWindowOpener providerIdentityId={providerIdentityId} providerId={provider.get('_id')}>
                  {(openPopupWindow) => (
                    <Href onClick={openPopupWindow} role="button" tabIndex="0" onKeyPress={openPopupWindow}>
                      Click here to try to reconnect it
                    </Href>
                  )}
                </AuthWindowOpener>
                .
              </>
            }
          />
        )}
      </Modal>
    );
  }
}
