import { fromJS, List, Map, OrderedMap } from 'immutable';

import { containerTypes, linkTypes, providerIdentityTypes, unitoIdentityTypes } from 'consts';
import { containerGenerator, mergeContainers, normalizeEntitiesById } from 'utils';

export const initialState = Map({
  containersByProviderIdentityIds: Map(),
  entities: Map(),
  hasFatalError: false,
  isLoaded: false,
  isLoading: false,
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case linkTypes.GET_LINK_SUCCESS: {
      const { containerA, containerB, link } = action.payload;

      let newState = containerA
        ? state.mergeIn(
            ['containersByProviderIdentityIds', link.A.providerIdentity._id, containerA.id],
            fromJS(containerA),
          )
        : state;
      newState = containerB
        ? newState.mergeIn(
            ['containersByProviderIdentityIds', link.B.providerIdentity._id, containerB.id],
            fromJS(containerB),
          )
        : newState;

      const providerIdentities = [link.A.providerIdentity, link.B.providerIdentity].filter((pi) => pi);
      const entities = newState.get('entities').size
        ? newState.get('entities')
        : normalizeEntitiesById(fromJS(providerIdentities));

      return newState.merge({
        entities,
        hasFatalError: false,
      });
    }

    case providerIdentityTypes.DISCONNECT_PROVIDER_IDENTITY_REQUEST:
    case providerIdentityTypes.GET_PROVIDER_IDENTITIES_REQUEST:
      return state.merge({ hasFatalError: false, isLoading: true, isLoaded: false });

    case unitoIdentityTypes.GET_UNITO_IDENTITIES_SUCCESS: {
      const { unitoIdentities } = action.payload;
      const providerIdentities = fromJS(
        unitoIdentities.reduce(
          (accumulator, unitoIdentity) => accumulator.concat(unitoIdentity.providerIdentities),
          [],
        ),
      );
      const entities = normalizeEntitiesById(providerIdentities);
      return state.mergeIn(['entities'], entities);
    }

    case providerIdentityTypes.GET_PROVIDER_IDENTITIES_SUCCESS: {
      const providerIdentities = fromJS(
        action.payload.providerIdentities.map((pId) => ({
          ...pId,
          isValidated: false,
        })),
      );
      const entities = normalizeEntitiesById(providerIdentities);

      return state.mergeDeep({
        entities,
        hasFatalError: false,
        isLoading: false,
        isLoaded: true,
      });
    }

    case providerIdentityTypes.VALIDATE_PROVIDER_IDENTITY_SUCCESS: {
      const providerIdentity = action.payload;
      return state.setIn(['entities', providerIdentity._id], fromJS({ ...providerIdentity, isValidated: true }));
    }

    case providerIdentityTypes.GET_PROVIDER_IDENTITY_SUCCESS: {
      const { providerIdentity } = action.payload;
      return state.setIn(['entities', providerIdentity._id], fromJS(providerIdentity));
    }

    case providerIdentityTypes.DISCONNECT_PROVIDER_IDENTITY_SUCCESS: {
      const { _id: id } = action.payload.providerIdentity;
      return state.merge({
        entities: state.get('entities').delete(id),
      });
    }

    case providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_FAILURE:
    case providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_SUCCESS: {
      return state.merge({ isLoading: false });
    }

    case providerIdentityTypes.GET_ALLOWED_PROVIDER_IDENTITIES_REQUEST: {
      return state.merge({ isLoading: true });
    }

    case providerIdentityTypes.GET_PROVIDER_IDENTITIES_FAILURE: {
      return state.merge({ hasFatalError: true, isLoading: false, isLoaded: false });
    }

    case containerTypes.GET_CONTAINER_SUCCESS: {
      const { providerIdentityId, containerId } = action.meta;
      const { container } = action.payload;

      return state.mergeIn(['containersByProviderIdentityIds', providerIdentityId, containerId], fromJS(container));
    }

    case containerTypes.GET_CONTAINERS_SUCCESS: {
      const {
        meta: { providerIdentityId, searchValue },
        payload: { containers },
      } = action;

      const containersTree = Array.from(containerGenerator(containers));
      let containersById = OrderedMap();
      containersTree.forEach((container) => {
        containersById = containersById.set(container.id, fromJS({ ...container, providerIdentityId }));
      });

      // This is required in the case of a typeahead multisync
      // Otherwise if the user searches for a different project name, it removes previous selected containers
      // from the state and are no longered displayed in the select input
      const newContainers = searchValue
        ? mergeContainers(state.getIn(['containersByProviderIdentityIds', providerIdentityId], Map()), containersById)
        : containersById;

      return state.setIn(['containersByProviderIdentityIds', providerIdentityId], fromJS(newContainers));
    }

    default:
      return state;
  }
};

export const getProviderName = (state, providerIdentityId) =>
  state.getIn(['entities', providerIdentityId, 'providerName']);

export const getById = (state, providerIdentityId) => state.getIn(['entities', providerIdentityId], Map());

export const getProviderIdentityByProfileId = (state, profileId, providerId) =>
  state.get('entities', List()).find((pi) => pi.get('profileId') === profileId && pi.get('providerId') === providerId);

export const getByIds = (state, ids) => state.get('entities').filter((pi) => ids.contains(pi.get('_id')));

export const getUserProviderIdentities = (state, userId) =>
  state.get('entities').filter((pi) => pi.get('users', List()).contains(userId));

export const getUserProviderIdentitiesByProviderName = (state, userId, providerName) =>
  state
    .get('entities')
    .filter((pi) => pi.get('users', List()).contains(userId) && pi.get('providerName') === providerName);

export const getUserProviderNames = (state, userId) => {
  const pis = getUserProviderIdentities(state, userId);
  return pis.map((pi) => pi.get('providerName')).toSet();
};

export const getAllProviderIdentities = (state) => state.get('entities');

export const isLoaded = (state) => state.get('isLoaded');

export const isLoading = (state) => state.get('isLoading');

export const hasError = (state) => state.get('hasFatalError');

export const getContainersByProviderIdentityId = (state, providerIdentityId) =>
  state.getIn(['containersByProviderIdentityIds', providerIdentityId], Map());

export const getContainerById = (state, providerIdentityId, containerId) =>
  state.getIn(['containersByProviderIdentityIds', providerIdentityId, containerId], Map());
