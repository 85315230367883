import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button } from '@unitoio/mosaic';

import { trackingTypes, routes } from 'consts';

import {
  isOrganizationAccountSuspended,
  isOrganizationAccountPaused,
  getSelectedOrganizationId,
  getIsLoadedProviders,
} from 'reducers';
import styled from 'styled-components';
import { Href } from '~/components/Href/Href';
import { IconHoverTooltip } from '~/components/IconHoverTooltip/IconHoverTooltip';

const MimicsButton = styled(Button)`
  margin-bottom: 5px;
`;

export const CreateSyncButton = ({ isEmbed = false, trackDashboardAction = () => null }) => {
  const orgAccountIsSuspended = useSelector((state) => isOrganizationAccountSuspended(state));
  const orgAccountIsPaused = useSelector((state) => isOrganizationAccountPaused(state));
  const areProvidersLoaded = useSelector((state) => !getIsLoadedProviders(state));
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  const history = useHistory();

  const redirectProps = isEmbed
    ? // Open in new tab inside embed mode, redirect in standalone
      { href: `/#${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/pricing` }
    : { to: `${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/pricing` };

  return (
    <MimicsButton
      variant="primary"
      startIcon="plus"
      data-test="dashboard__btn--addsync"
      loading={areProvidersLoaded}
      disabled={orgAccountIsSuspended || orgAccountIsPaused}
      onClick={() => {
        trackDashboardAction(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.ADD_SYNC);
        history.push(routes.ABSOLUTE_PATHS.FLOW_BUILDER_ADD);
      }}
    >
      Create flow
      {(orgAccountIsSuspended || orgAccountIsPaused) && (
        <IconHoverTooltip placement="top">
          Your account is {orgAccountIsSuspended ? 'suspended' : 'paused'}.
          <br />
          <Href {...redirectProps}>Subscribe to a plan</Href> to create new flows.
        </IconHoverTooltip>
      )}
    </MimicsButton>
  );
};

CreateSyncButton.propTypes = {
  trackDashboardAction: PropTypes.func,
  isEmbed: PropTypes.bool,
};
