import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, reset } from 'redux-form';

import { NewTag, NewTagColor } from '@unitoio/mosaic';

import { billingActions } from 'actions';
import { billingTypes, trackingTypes } from 'consts';
import { InlineInputFormField } from '~/components/InlineInputFormField/InlineInputFormField';
import { Modal } from '~/components/Modal/Modal';

class AddCouponFormComponent extends Component {
  static propTypes = {
    currentCouponName: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    helpText: PropTypes.string,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    trackEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentCouponName: undefined,
    helpText: undefined,
  };

  state = {
    isModalOpen: false,
    couponId: null,
  };

  onSubmit = (formData) => {
    const { dispatch, currentCouponName, trackEvent } = this.props;
    const { couponId } = formData;
    trackEvent(trackingTypes.BILLING_EVENTS.ACTION_NAME, {
      action_name: trackingTypes.BILLING_EVENTS.ACTIONS.REDEEM_COUPON,
    });
    if (currentCouponName === couponId) {
      dispatch(reset(billingTypes.COUPON_FORM_NAME));
    } else if (currentCouponName) {
      this.setState({
        isModalOpen: true,
        couponId,
      });
    } else {
      this.addCoupon(couponId);
    }
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      couponId: null,
    });
  };

  replaceCoupon = () => {
    const { couponId } = this.state;
    this.addCoupon(couponId);
    this.closeModal();
  };

  addCoupon = (couponId) => {
    const { dispatch } = this.props;
    dispatch(billingActions.editOrganization({ coupon: couponId }));
    dispatch(reset(billingTypes.COUPON_FORM_NAME));
  };

  renderModal = () => {
    const { currentCouponName } = this.props;
    const { couponId, isModalOpen } = this.state;
    const currentCoupon = currentCouponName || '';
    const newCouponId = couponId || '';
    return (
      <Modal
        confirmLabel="Continue"
        isOpen={isModalOpen}
        onCancel={this.closeModal}
        onConfirm={this.replaceCoupon}
        onRequestClose={this.closeModal}
        title="Replace coupon?"
      >
        Your coupon <NewTag color={NewTagColor.INFO}>{currentCoupon}</NewTag> will be replaced by{' '}
        <NewTag color={NewTagColor.INFO}>{newCouponId}</NewTag>.
      </Modal>
    );
  };

  render() {
    const { handleSubmit, helpText, pristine, submitting } = this.props;

    return (
      <form className="add-coupon-form" onSubmit={handleSubmit(this.onSubmit)}>
        {this.renderModal()}
        <InlineInputFormField
          helpText={helpText}
          submitting={submitting}
          pristine={pristine}
          onSubmit={handleSubmit(this.onSubmit)}
          placeholder="Apply your coupon"
          name="couponId"
          id="billing-container__coupon-input"
        />
      </form>
    );
  }
}

export const AddCouponForm = reduxForm({ form: billingTypes.COUPON_FORM_NAME })(AddCouponFormComponent);
