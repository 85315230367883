import PropTypes from 'prop-types';
import React from 'react';

import { Title } from '~/components/Title/Title';

export function FatalError({ title = 'Something happened', subtitle = 'We should fix this momentarily.' }) {
  return (
    <div className="link-list link-list--empty text-center">
      <Title type="h2">{title}</Title>
      <Title type="h2">{subtitle}</Title>
    </div>
  );
}

FatalError.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
