import { providerTypes, routes, appTypes } from 'consts';
import { fromLocalStorage } from 'utils';

export const getProviders = ({ apiVersion } = { apiVersion: routes.API_VERSION_V1 }) => ({
  types: [
    providerTypes.GET_PROVIDERS_REQUEST,
    providerTypes.GET_PROVIDERS_SUCCESS,
    providerTypes.GET_PROVIDERS_FAILURE,
  ],
  url:
    apiVersion === routes.API_VERSION_V2 && fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY)
      ? routes.API_PATHS.PROVIDERS_V2(fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY))
      : routes.API_PATHS.PROVIDERS(),
});

// Note: we need to return a dispatch function rather than the raw action because this way
// we can catch any errors thrown by the dispatched action in the flow builder hooks.
export const getProviderCapabilities = (providerName, providerIdentityId) => (dispatch) =>
  dispatch({
    types: [
      providerTypes.GET_PROVIDER_CAPABILITIES_REQUEST,
      providerTypes.GET_PROVIDER_CAPABILITIES_SUCCESS,
      providerTypes.GET_PROVIDER_CAPABILITIES_FAILURE,
    ],
    url: routes.API_PATHS.PROVIDER_CAPABILITIES(providerName, providerIdentityId),
    displayError: false,
  });

// Note: we need to return a dispatch function rather than the raw action because this way
// we can catch any errors thrown by the dispatched action in the flow builder hooks.
export const getProviderCapabilitiesForItem =
  (providerName, providerIdentityId, containerId, itemType, linkId) => (dispatch) =>
    dispatch({
      types: [
        providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_REQUEST,
        providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_SUCCESS,
        providerTypes.GET_PROVIDER_CAPABILITIES_FOR_ITEM_FAILURE,
      ],
      url: routes.API_PATHS.PROVIDER_CAPABILITIES_FOR_ITEM(
        providerName,
        providerIdentityId,
        containerId,
        itemType,
        linkId,
      ),
      meta: {
        providerName,
        containerId,
        itemType,
      },
      displayError: false,
    });

export const getProviderConnectUrl = (providerName, linkId) => ({
  types: [
    providerTypes.GET_PROVIDER_CONNECT_URL_REQUEST,
    providerTypes.GET_PROVIDER_CONNECT_URL_SUCCESS,
    providerTypes.GET_PROVIDER_CONNECT_URL_FAILURE,
  ],
  url: routes.API_PATHS.PROVIDER_CONNECT_URL(
    providerName,
    fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY),
    linkId,
  ),
});
