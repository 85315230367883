import React from 'react';
import PropTypes from 'prop-types';

import { NewButton } from '@unitoio/mosaic';
import { errorTypes } from 'consts';

import { BoxedAlert } from '~/components/BoxedAlert/BoxedAlert';

export function SelectedProviderIdentityError({ isDraft, isModernConnector, onReconnect, providerIdentityError }) {
  const { errorType } = providerIdentityError;
  let message;
  switch (errorType) {
    case errorTypes.API_ERROR_TYPES.AUTHENTICATION: {
      const modernConnectorMessage = (
        <div>
          Unito no longer has access to this account. Try to{' '}
          <NewButton type="link" onClick={onReconnect} style={{ padding: '0' }}>
            reconnect
          </NewButton>{' '}
          it {isDraft ? ', or select another account' : ''} to restore access.
        </div>
      );
      const classicConnectorMessage = (
        <div>
          Unito no longer has access to this account. Try to
          <NewButton type="link" onClick={onReconnect} style={{ padding: '0' }}>
            reconnect
          </NewButton>{' '}
          it, or select another account to restore access.
        </div>
      );
      message = isModernConnector ? modernConnectorMessage : classicConnectorMessage;
      break;
    }
    case errorTypes.API_ERROR_TYPES.UNAUTHORIZED: {
      message =
        'This account is not authorized to access this project, please review your tool permissions to resume syncing.';
      break;
    }
    default: {
      message = providerIdentityError.error;
      break;
    }
  }

  return <BoxedAlert message={message} />;
}

SelectedProviderIdentityError.propTypes = {
  isDraft: PropTypes.bool.isRequired,
  isModernConnector: PropTypes.bool.isRequired,
  onReconnect: PropTypes.func.isRequired,
  providerIdentityError: PropTypes.shape({
    error: PropTypes.string.isRequired,
    errorType: PropTypes.string.isRequired,
  }).isRequired,
};
