import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { billingActions } from 'actions';
import { getCustomerInvoices, getOrganizationCustomerId } from 'reducers';
import { routes } from 'consts';
import { toLocaleAmount } from 'utils';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { Href } from '~/components/Href/Href';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { Section } from '~/components/Section/Section';
import { Table } from '~/components/Table/Table';
import { TableBody } from '~/components/Table/TableBody';
import { TableCell } from '~/components/Table/TableCell';
import { TableRow } from '~/components/Table/TableRow';
import { Title } from '~/components/Title/Title';

import noBillingHistory from './no-billing-history.svg';

const renderListOfInvoices = (invoices) => (
  <Table>
    <TableBody>
      {invoices
        .map((invoice) => (
          <TableRow key={invoice.get('id')}>
            <TableCell>{moment.unix(invoice.get('created')).format('DD/MM/YYYY')}</TableCell>
            <TableCell>{toLocaleAmount(invoice.get('total'))}</TableCell>
            <TableCell cellSize={2} align="right">
              {invoice.get('paid') ? (
                <Button type="href" btnStyle="link" size="md" href={invoice.get('hostedInvoiceUrl')}>
                  View invoice
                </Button>
              ) : (
                <Button btnStyle="error" size="sm" disabled>
                  Past due
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))
        .toArray()}
    </TableBody>
  </Table>
);

export function BillingHistory({ organizationId }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const invoices = useSelector((state) => getCustomerInvoices(state, getOrganizationCustomerId(state, organizationId)));

  useEffect(() => {
    const fetchInvoices = async () => {
      await dispatch(billingActions.getOrganizationInvoices(organizationId));
      setLoading(false);
    };
    fetchInvoices();
  }, [dispatch, organizationId]);

  if (isLoading) {
    return <InlineLoading />;
  }

  return (
    <div className="billing-information clearfix">
      <Section className="billing-container__invoices">
        {!invoices.isEmpty() ? (
          <div>{renderListOfInvoices(invoices)}</div>
        ) : (
          <Card className="text-center">
            <img src={noBillingHistory} alt="No billing history" />
            <Title type="h3">You don’t have any invoices yet</Title>
            Once you <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/pricing`}>choose a plan</Href>,
            your invoices will appear here.
          </Card>
        )}
      </Section>
    </div>
  );
}

BillingHistory.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
