import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import styled from 'styled-components';

import { ProviderIcon } from '@unitoio/mosaic';

import { AuthWindowOpener } from 'containers';
import { getVisibleProvidersByFamily } from 'reducers';
import { trackingTypes } from 'consts';
import { Button } from '~/components/Button/Button';
import { trackableHoC } from '../TrackableHoC/TrackableHoC';

const ProviderWrapper = styled.div`
  display: inline-block;
  margin: 17px;

  &:hover {
    cursor: pointer;
  }
`;

class AddProviderAccountComponent extends Component {
  static propTypes = {
    trackEvent: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    providersByFamily: PropTypes.instanceOf(Map),
  };

  static defaultProps = {
    onSuccess: () => null,
    providersByFamily: Map(),
  };

  state = {
    focusedFamily: null,
  };

  componentDidMount() {
    const { trackEvent } = this.props;
    trackEvent(trackingTypes.ADD_CONNECTOR_START);
  }

  onFocusConnector = (connectorName) => () => {
    this.setState({ focusedFamily: connectorName });
  };

  renderAuthButton = (provider) => {
    const { onSuccess, trackEvent } = this.props;

    return (
      <AuthWindowOpener key={provider.get('_id')} onSuccess={onSuccess} providerId={provider.get('_id')}>
        {(openPopupWindow) => (
          <ProviderWrapper
            onClick={() => {
              trackEvent(trackingTypes.ADD_CONNECTOR_SUBMIT, { selected_tool_name: provider.get('name') });
              openPopupWindow();
            }}
          >
            <ProviderIcon displayTooltip={false} name={provider.get('name')} size="large" />
            <div>{provider.get('displayName')}</div>
          </ProviderWrapper>
        )}
      </AuthWindowOpener>
    );
  };

  renderConnectorGroup = (provider) => {
    const connectorName = provider.get('connectorName');

    return (
      <ProviderWrapper key={connectorName} onClick={this.onFocusConnector(provider.get('family'))}>
        <ProviderIcon displayTooltip={false} name={provider.get('name')} size="large" />
        <div>{provider.get('family')}</div>
      </ProviderWrapper>
    );
  };

  renderIcon = (providers) => {
    const firstProvider = providers.first();

    return providers.size === 1 ? this.renderAuthButton(firstProvider) : this.renderConnectorGroup(firstProvider);
  };

  render() {
    const { providersByFamily } = this.props;
    const { focusedFamily } = this.state;

    return (
      <div>
        {!focusedFamily ? (
          providersByFamily
            .sortBy((providers, familyName) => familyName)
            .map((providers) => this.renderIcon(providers))
            .toArray()
        ) : (
          <div>
            <div>
              {providersByFamily
                .get(focusedFamily)
                .sortBy((provider) => provider.get('displayName'))
                .map(this.renderAuthButton)
                .toArray()}
            </div>
            <Button btnStyle="link" onClick={this.onFocusConnector(null)}>
              Back
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  providersByFamily: getVisibleProvidersByFamily(state).filter((providers) => providers.size > 0),
});

export const AddProviderAccount = connect(mapStateToProps)(trackableHoC(AddProviderAccountComponent));
