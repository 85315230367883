import { Map } from 'immutable';
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, tokens } from '@unitoio/mosaic';

import { linkTypes } from 'consts';
import { borderRadius } from 'theme';
import { useGetItemTypes } from 'hooks';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

import { ActivityStreamEmptyStateAutoSync } from './ActivityStreamEmptyStateAutoSync';
import { ActivityStreamInitialSync } from './ActivityStreamEmptyStateInitialSync';
import { ActivityStreamReviewRules } from './ActivityStreamEmptyStateReviewRules';
import { ActivityStreamEmptyStateTestMode } from './ActivityStreamEmptyStateTestMode';

const TwoStepWrapper = styled.div`
  width: 70%;
  border: ${tokens.spacing.s1} solid ${tokens.colors.content.neutral.n10};
  border-radius: ${borderRadius.large};
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: ${tokens.spacing.s3};
  padding: ${tokens.spacing.s4};
`;

const TwoStepTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
  color: ${tokens.colors.content.neutral.n30};
`;

const TwoStepTitleNumber = styled.div`
  width: ${tokens.spacing.s6};
  height: ${tokens.spacing.s6};
  border: ${tokens.spacing.s1} solid ${tokens.colors.content.neutral.n30};
  border-radius: ${borderRadius.round};
  font-weight: ${tokens.fontWeight.fw7};
  text-align: center;
  margin-right: ${tokens.spacing.s3};
`;

function getCopyFor(concept, middleWord, providerIdA, providerIdB, itemTypeA, itemTypeB) {
  return (
    <ProviderTermsByName
      middleWord={middleWord}
      providerNameA={providerIdA}
      providerNameB={providerIdB}
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
      plurality="plural"
      termKey={concept}
      pcdv3
    />
  );
}

export function EmptyActivityStream({ changePage, link, providerIdA, providerIdB, isSearchingByURL = false }) {
  const { linkId } = useParams();
  const [itemTypeA, itemTypeB] = useGetItemTypes(linkId);
  const syncActivity = link.getIn(['syncStatus', 'activity'], linkTypes.LINK_ACTIVITY_STATUS.HEALTHY);
  const isTestMode = link.getIn(['syncSettings', 'earliestCreatedAt'], false);
  const isAutoSync = link.get('isAutoSync', false);
  const getTaskTerm = (withMiddleWord) =>
    getCopyFor('task', withMiddleWord, providerIdA, providerIdB, itemTypeA, itemTypeB);
  const containerTerm = (
    <ProviderTermsByName
      middleWord="and"
      providerNameA={providerIdA}
      providerNameB={providerIdB}
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
      plurality="plural"
      termKey="container"
      pcdv3
    />
  );

  if (syncActivity === linkTypes.LINK_ACTIVITY_STATUS.INITIALIZING) {
    return <ActivityStreamInitialSync containerTerm={containerTerm} taskTerm={getTaskTerm('and')} />;
  }

  if (
    syncActivity === linkTypes.LINK_ACTIVITY_STATUS.SYNCING ||
    syncActivity === linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED
  ) {
    return (
      <>
        <InlineLoading />
        Unito is syncing your {getTaskTerm('and')}
      </>
    );
  }

  if (isSearchingByURL) {
    return (
      <Typography variant={Typography.variants.BODY2} align={Typography.aligns.CENTER}>
        We did not find any recent sync activity for the item at that URL.
      </Typography>
    );
  }

  if (isTestMode && !isAutoSync) {
    return (
      <Fragment>
        <TwoStepWrapper>
          <TwoStepTitleWrapper>
            <TwoStepTitleNumber>1</TwoStepTitleNumber>
            First, create a new work item.
          </TwoStepTitleWrapper>
          <ActivityStreamEmptyStateTestMode link={link} taskTerm={getTaskTerm('or')} withinTwoStep plurality="plural" />
        </TwoStepWrapper>
        <TwoStepWrapper>
          <TwoStepTitleWrapper>
            <TwoStepTitleNumber>2</TwoStepTitleNumber>
            Once you have created a work item.
          </TwoStepTitleWrapper>
          <ActivityStreamEmptyStateAutoSync link={link} withinTwoStep />
        </TwoStepWrapper>
      </Fragment>
    );
  }

  if (isTestMode) {
    return <ActivityStreamEmptyStateTestMode link={link} taskTerm={getTaskTerm('or')} plurality="plural" />;
  }

  if (!isAutoSync) {
    return <ActivityStreamEmptyStateAutoSync link={link} />;
  }

  return <ActivityStreamReviewRules changePage={changePage} />;
}

EmptyActivityStream.propTypes = {
  changePage: PropTypes.func.isRequired,
  link: PropTypes.instanceOf(Map).isRequired,
  providerIdA: PropTypes.string.isRequired,
  providerIdB: PropTypes.string.isRequired,
  isSearchingByURL: PropTypes.bool,
};
