import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box, tokens, Typography } from '@unitoio/mosaic';

import { routes } from 'consts';
import { color, fontWeight } from 'theme';
import {
  getOrganizationHasFlows,
  isOrganizationAccountPaused,
  isOrganizationChurned,
  isOrganizationTrialExpired,
} from 'reducers';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

import accountSuspendedOrPaused from './account_suspended_img.svg';

const EmptyState = styled.section`
  background-color: ${color.light.primary};
  margin-top: 5rem;
`;

const Message = styled.div`
  color: ${color.text.secondary};
  margin-top: 2rem;
  text-align: center;
  background-color: ${color.light.primary};

  h6 {
    font-weight: ${fontWeight.light};
  }
`;

const SuspendedAccountImg = styled.img`
  margin-bottom: 2rem;
`;

const getContent = (orgAccountIsChurned, orgAccountIsTrialExpired, orgAccountIsPaused) => {
  if (orgAccountIsPaused) {
    return (
      <>
        <Typography variant={Typography.variants.H2} align="center">
          Your Unito account is paused
        </Typography>
        <Box m={[0, 0, tokens.spacing.s6]}>
          <Typography align="center" variant={Typography.variants.BODY1}>
            Choose the plan that best suits your workflow and keep your projects in sync.
          </Typography>
        </Box>
      </>
    );
  }

  if (orgAccountIsChurned) {
    return (
      <>
        <Typography variant={Typography.variants.H2} align="center">
          Your Unito subscription has expired
        </Typography>
        <Box m={[0, 0, tokens.spacing.s6]}>
          <Typography align="center" variant={Typography.variants.BODY1}>
            Choose a new plan or reach out to us for help.
          </Typography>
        </Box>
      </>
    );
  }

  if (orgAccountIsTrialExpired) {
    return (
      <>
        <Typography variant={Typography.variants.H2} align="center">
          Your Unito subscription has expired
        </Typography>
        <Box m={[0, 0, tokens.spacing.s6]}>
          <Typography align="center" variant={Typography.variants.BODY1}>
            Subscribe to a plan to continue using Unito, or reach out to us for help.
          </Typography>
        </Box>
      </>
    );
  }

  return null;
};
export function OrgNeedsPayment({ organizationId }) {
  const { embedName } = useParams();
  const target = embedName ? '_blank' : '_self';
  const orgAccountIsChurned = useSelector((state) => isOrganizationChurned(state, organizationId));
  const orgAccountIsTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));
  const orgAccountIsPaused = useSelector((state) => isOrganizationAccountPaused(state, organizationId));
  const statusTerm = orgAccountIsPaused ? 'paused' : 'suspended';
  const hasFlows = useSelector((state) => getOrganizationHasFlows(state, organizationId));

  return (
    <EmptyState>
      <Card className="text-center">
        <Message>
          <div>
            {getContent(orgAccountIsChurned, orgAccountIsTrialExpired, orgAccountIsPaused)}
            <Button
              size="lg"
              target={target}
              to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/pricing`}
              type="href"
            >
              See all plans
            </Button>
          </div>
          <ChatBubble message={`Hey Unito! My account is ${statusTerm} and I need help to know what to do next.`}>
            Need some extra help?
          </ChatBubble>
          <SuspendedAccountImg
            alt={`Account is ${statusTerm}`}
            src={accountSuspendedOrPaused}
            width="292"
            height="auto"
          />
          {hasFlows && (
            <Box m={[tokens.spacing.s4, 0]}>
              <Button btnStyle="subtleLink" size="lg" to={routes.ABSOLUTE_PATHS.DASHBOARD} type="href">
                Back to dashboard
              </Button>
            </Box>
          )}
        </Message>
      </Card>
    </EmptyState>
  );
}

OrgNeedsPayment.propTypes = {
  organizationId: PropTypes.string.isRequired,
};
