import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { providerIdentityActions } from 'actions';
import { RadioButtonGroup } from '~/components/RadioButtonGroup/RadioButtonGroup';
import { RadioButton } from '~/components/RadioButton/RadioButton';

function getExistingInstallations(providerIdentities) {
  return providerIdentities.map((providerIdentity) => (
    <RadioButton
      key={providerIdentity._id}
      value={providerIdentity._id}
      label={
        <span>
          Use Unito on <strong>{providerIdentity.profileUsername}</strong>
        </span>
      }
    />
  ));
}

export const RadioButtonGroupGithubApp = ({ input }) => {
  const dispatch = useDispatch();
  const [githubProviderIdentities, setProviderIdentities] = useState([]);

  useEffect(() => {
    async function fetchAvailableInstallations() {
      const { providerIdentities } = await dispatch(providerIdentityActions.fetchAvailableInstallations('githubapp'));
      setProviderIdentities(providerIdentities);
    }

    fetchAvailableInstallations();
  }, [dispatch]);

  return (
    <RadioButtonGroup name="installation" value={input.value} onChange={input.onChange}>
      {getExistingInstallations(githubProviderIdentities)}
      <RadioButton
        value="newInstallation"
        label={
          <span>
            Install Unito on a <strong>different GitHub account</strong>
          </span>
        }
      />
    </RadioButtonGroup>
  );
};

RadioButtonGroupGithubApp.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
};
