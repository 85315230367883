import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon, message } from '@unitoio/mosaic';

import { containerActions, providerContainerActions, workflowActions } from 'actions';
import { ContainerAlerts } from 'containers';
import {
  getContainerIdsByProviderInstanceId,
  getProviderById,
  getProviderCapabilitiesByIdV3,
  getProviderIdentityById,
  getUserProviderIdentities,
} from 'reducers';
import { trackingTypes } from 'consts';
import { capitalize } from 'utils';
import { Button } from '~/components/Button/Button';
import { ContainersSelectRevamp } from '~/components/ContainersSelectRevamp/ContainersSelectRevamp';
import { Box } from '~/components/Box/Box';
import { ToolAndAccountSelect } from '~/components/ToolAndAccountSelect/ToolAndAccountSelect';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const FlexBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WorkBlockConfigurationForm = ({ onError, onSubmit, trackEvent, workflowId = null, providerId = null }) => {
  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: {
      toolAndAccount: { providerId, providerIdentityId: null },
      container: {
        value: null,
      },
    },
  });
  const toolAndAccountWatch = watch('toolAndAccount');
  const container = watch('container');
  const dispatch = useDispatch();
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const providerIdentity = useSelector((state) =>
    getProviderIdentityById(state, toolAndAccountWatch.providerIdentityId),
  );
  const provider = useSelector((state) => getProviderById(state, { providerId: toolAndAccountWatch.providerId }));
  const providerCapabilities = useSelector((state) =>
    getProviderCapabilitiesByIdV3(state, toolAndAccountWatch.providerId),
  );
  const firstContainerType = !providerCapabilities.isEmpty() ? providerCapabilities.get('containers').first() : null;
  const defaultItemType = !providerCapabilities.isEmpty()
    ? providerCapabilities.getIn(['item', 'names', 'native'])
    : null;
  const defaultContainerType = firstContainerType?.getIn(['names', 'native']);
  const canCreateContainer = firstContainerType?.get('canCreate', false) ?? false;
  const alreadySelectedContainers = useSelector((state) =>
    getContainerIdsByProviderInstanceId(state, providerIdentity.get('providerInstanceId')),
  );

  /**
   * For Skeleton
   */
  const selectedProvider = useSelector((state) =>
    getProviderById(state, { providerId: toolAndAccountWatch.providerId }),
  );
  // repeated, need to know if the user doesn't have provider identities for the selected provider for rendering purposes
  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const filteredUserProviderIdentities = userProviderIdentities.filter(
    (pi) => pi.get('providerId') === toolAndAccountWatch.providerId,
  );

  const trackEventWF = useCallback(
    (eventName, properties) =>
      trackEvent(eventName, {
        ...properties,
        workflow_id: workflowId,
        selected_tool_name: provider.get('name'),
      }),
    [trackEvent, workflowId, provider],
  );

  function handleOnClickCreateContainer() {
    trackEventWF(trackingTypes.ADD_BLOCK_ACTION, { action_name: 'clicked on create a new project' });
    setIsNewProjectModalOpen(true);
  }

  const handleOnSubmit = handleSubmit(async (formData) => {
    const {
      toolAndAccount: { providerIdentityId },
      container: containerId,
    } = formData;
    const toolName = providerIdentity.get('providerName');
    if (providerIdentityId && containerId && defaultContainerType) {
      try {
        const { container: containerValidation } = await dispatch(
          containerActions.getContainerById({
            providerIdentityId,
            containerId,
            containerType: defaultContainerType,
            itemType: defaultItemType,
          }),
        );

        const containerErrors = containerValidation.errors ?? [];
        const containerWarnings = containerValidation.warnings ?? [];
        if (containerWarnings.length > 0 || containerErrors.length > 0) {
          message.error({
            content: [...containerErrors, ...containerWarnings][0],
          });
          return;
        }
        trackEventWF(trackingTypes.ADD_BLOCK_SUBMIT, {
          selected_tool_name: toolName,
        });
        const { providerContainer } = await dispatch(
          providerContainerActions.createProviderContainer(
            providerIdentityId,
            containerId,
            defaultContainerType,
            defaultItemType,
          ),
        );
        const { block, created } = await dispatch(
          workflowActions.addBlock({
            workflowId,
            providerIdentityId,
            providerContainerId: providerContainer._id,
            itemType: defaultItemType,
            containerType: defaultContainerType,
          }),
        );
        await onSubmit({
          created,
          containerId,
          blockId: block._id,
          itemType: defaultItemType,
          containerType: defaultContainerType,
          providerIdentityId,
          providerContainerId: providerContainer._id,
          toolName,
        });
      } catch {
        onError();
      }
    }
  });

  const trackEventWFAction = useCallback(
    (trackingProps) => trackEventWF(trackingTypes.ADD_BLOCK_ACTION, trackingProps),
    [trackEventWF],
  );
  const trackEventWFBlocked = useCallback(
    (trackingProps) => trackEventWF(trackingTypes.ADD_BLOCK_BLOCKED, trackingProps),
    [trackEventWF],
  );

  useEffect(() => {
    if (filteredUserProviderIdentities.isEmpty() && !!toolAndAccountWatch.providerId) {
      trackEventWF(trackingTypes.ADD_CONNECTOR_START, { selected_tool: 'new' });
    }
  }, [trackEventWF, filteredUserProviderIdentities, toolAndAccountWatch.providerId]);

  return (
    <FormContainer>
      <Controller
        render={({ field: controllerProps }) => (
          <ToolAndAccountSelect
            providerIdentityId={{
              input: {
                onChange: (selectedProviderIdentityId) => {
                  controllerProps.onChange({
                    providerId: getValues('toolAndAccount').providerId,
                    providerIdentityId: selectedProviderIdentityId,
                  });
                  trackEvent(trackingTypes.ADD_BLOCK_ACTION, {
                    selected_tool_name: selectedProvider.get('name'),
                    selected_tool: 'existing',
                    action_name: 'selected existing account',
                  });
                },
                name: 'providerIdentityId',
                value: controllerProps.value.providerIdentityId,
              },
              meta: {},
            }}
            providerId={{
              input: {
                onChange: (selectedProviderId) => {
                  controllerProps.onChange({
                    providerId: selectedProviderId,
                    providerIdentityId: null,
                  });
                },
                name: 'providerId',
                value: controllerProps.value.providerId,
              },
              meta: {},
            }}
            container={container?.value}
            containerType={defaultContainerType}
            itemType={defaultItemType}
          />
        )}
        control={control}
        name="toolAndAccount"
      />
      {toolAndAccountWatch.providerIdentityId && (
        <Box $m={[0.75, 0]}>
          <FlexBlock>
            {capitalize(defaultContainerType)}
            {canCreateContainer && (
              // eslint-disable-next-line react/jsx-no-bind
              <Button btnStyle="link" className="pull-right" onClick={handleOnClickCreateContainer} noPadding>
                + Create {defaultContainerType}
              </Button>
            )}
          </FlexBlock>
          <ContainersSelectRevamp
            control={control}
            controlForm={{ setValue, trigger }}
            errors={errors.container}
            getProviderIdentityId={() => toolAndAccountWatch.providerIdentityId}
            isNewProjectModalOpen={isNewProjectModalOpen}
            closeNewProjectModal={() => setIsNewProjectModalOpen(false)}
            name="container"
            provider={provider}
            itemType={defaultItemType}
            containerType={defaultContainerType}
            valuesToDisable={alreadySelectedContainers.map((containerId) =>
              Map({ id: containerId, disabledText: 'Already in workflow' }),
            )}
            trackActionEvent={trackEventWFAction}
            trackBlockedEvent={trackEventWFBlocked}
          />
          <ContainerAlerts
            providerIdentityId={toolAndAccountWatch.providerIdentityId}
            containerId={container?.value}
            severity="warning"
          />
          <ContainerAlerts
            providerIdentityId={toolAndAccountWatch.providerIdentityId}
            containerId={container?.value}
            severity="error"
          />
        </Box>
      )}
      {toolAndAccountWatch.providerIdentityId && (
        <Box $m={[1.5, 0]}>
          <Button
            onClick={handleOnSubmit}
            disabled={!container || isSubmitting || !!errors.container}
            pullRight
            type="button"
          >
            {isSubmitting && <Icon name="spinner" kind={Icon.KINDS.SOLID} title="submitting" pulse />} Add block
          </Button>
        </Box>
      )}
    </FormContainer>
  );
};

WorkBlockConfigurationForm.propTypes = {
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  workflowId: PropTypes.string,
  providerId: PropTypes.string,
};
