import React from 'react';
import PropTypes from 'prop-types';

import { useQueryParams } from 'hooks';
import { Button } from '~/components/Button/Button';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';

import { JiraCloudTokenStep1 } from './JiraCloudTokenStep1';
import { JiraServerTokenStep1 } from './JiraServerTokenStep1';

import { AuthButtonsWrapper } from '../../../AuthButtonsWrapper';
import { AuthTitleWrapper } from '../../../AuthTitleWrapper';

export function AuthJiraApiTokenStep1({ providerName, history, onCancel, trackEvent }) {
  const { windowOpenerId } = useQueryParams();

  return (
    <div className="auth-instruction-step">
      <AuthTitleWrapper>
        <Title type="h2" align="center">
          (API Token - Step 1 of 2)
        </Title>
      </AuthTitleWrapper>
      <Section>
        <p>
          Before continuing, you'll need to generate an <strong>API key</strong> to allow Unito to properly connect to
          Jira Data Center.
        </p>
        {providerName === 'jiracloud' ? <JiraCloudTokenStep1 /> : <JiraServerTokenStep1 />}
        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            to={`step2?windowOpenerId=${windowOpenerId}`}
            onClick={() => trackEvent('api token onboarding 1 (of 2)')}
            type="href"
            pullRight
          >
            Continue
          </Button>
          <Button btnStyle="link" onClick={() => history.goBack()} pullRight>
            Back
          </Button>
          <Button reverse btnStyle="dark" onClick={onCancel}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </Section>
    </div>
  );
}

AuthJiraApiTokenStep1.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  providerName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};
