import React from 'react';
import styled from 'styled-components';

import { Box, Button, tokens, Typography, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { useTrackEvent } from 'hooks';
import { trackingTypes } from 'consts';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const RefreshButton = styled(Button)`
  float: right;
  background: transparent;
`;

const ButtonBox = styled(Box)`
  width: 30%;
`;

export function BillingOverviewErrorState() {
  const trackEvent = useTrackEvent();

  return (
    <Alert
      level={NewAlertLevel.WARNING}
      message={
        <Box flexDirection={Box.flexDirection.ROW}>
          <Box fullWidth>
            <Box>
              <Typography variant={Typography.variants.BODY1}>
                {' '}
                <Bold>There was an error while getting your plan or usage information</Bold>
              </Typography>
            </Box>
            <Box>
              <Typography variant={Typography.variants.BODY1}>
                Please reload the page or <ChatBubble type="text">Reach out to Unito</ChatBubble>
              </Typography>
            </Box>
          </Box>
          <ButtonBox>
            <RefreshButton
              variant="outlined"
              onClick={() => {
                trackEvent(trackingTypes.ACTION, {
                  action_name: 'clicked on reload page (error displaying plan or usage info)',
                });
                window.location.reload();
              }}
            >
              {' '}
              Reload page
            </RefreshButton>
          </ButtonBox>
        </Box>
      }
    />
  );
}
