import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Map } from 'immutable';

import { Icon } from '@unitoio/mosaic';

import { color, fontSize } from 'theme';
import { linkTypes } from 'consts';
import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { Modal } from '~/components/Modal/Modal';

const Toolbar = styled.div`
  align-items: center;
  background: ${color.content.neutral.white};
  border-top: 1px solid ${color.content.neutral.disabled};
  bottom: 0;
  display: flex;
  height: 4.5rem;
  justify-content: flex-end;
  padding: 0 6rem 0 1rem;
  position: absolute;
  width: 100%;
`;

const WorkflowSavingStatus = styled.div`
  color: ${color.content.neutral.placeholder};
  font-size: ${fontSize.f7};
`;

const Legend = styled.ul`
  margin-left: 2rem;
  margin-top: 1rem;
`;

const LegendItem = styled.li`
  margin-bottom: 1rem;
`;

const legendStatuses = Object.entries(linkTypes.LINK_STATUS_VALUES)
  .reduce((acc, [key, value]) => (!value.legend ? acc : [...acc, { ...value, key }]), [])
  .sort((first, second) => first.legendRank > second.legendRank);

export const WorkflowToolbar = ({ workflow, isSavingWorkflow, zoomToFit }) => {
  const updatedAt = moment(workflow.get('updatedAt')).fromNow();
  const [isLegendOpen, setLegendOpen] = useState(false);

  return (
    <Toolbar>
      <Box $m={[0, 'auto', 0, 0]}>
        <Button btnStyle="subtleLink" onClick={zoomToFit}>
          <Box $m={[0, 0.5, 0, 0]} as="span">
            <Icon name="search" kind={Icon.KINDS.SOLID} />
          </Box>
          Zoom to fit
        </Button>
        <Button btnStyle="subtleLink" onClick={() => setLegendOpen(true)}>
          <Box $m={[0, 0.5, 0, 0]} as="span">
            <Icon name="question-circle" kind={Icon.KINDS.SOLID} />
          </Box>
          Legend
        </Button>
        <Modal
          isOpen={isLegendOpen}
          onRequestClose={() => setLegendOpen(false)}
          displayCloseButton
          title="Legend"
          type="plainModal"
          position="bottomLeft"
        >
          <Legend className="fa-ul">
            {legendStatuses.map((linkStatus) => (
              <LegendItem key={linkStatus.key}>
                <Icon name={linkStatus.iconName} color={linkStatus.iconColor} spin={linkStatus.spin} listItem />
                {linkStatus.legend}
              </LegendItem>
            ))}
          </Legend>
        </Modal>
      </Box>

      <WorkflowSavingStatus>
        <Box $m={[0, 1.5, 0, 0]}>
          <Box $m={[0, 0.5, 0, 0]} as="span">
            <Icon name={isSavingWorkflow ? 'spinner' : 'check'} spin={isSavingWorkflow} />
          </Box>
          {isSavingWorkflow ? 'Saving layout' : `Layout saved ${updatedAt}`}
        </Box>
      </WorkflowSavingStatus>
    </Toolbar>
  );
};

WorkflowToolbar.propTypes = {
  isSavingWorkflow: PropTypes.bool.isRequired,
  workflow: PropTypes.instanceOf(Map).isRequired,
  zoomToFit: PropTypes.func.isRequired,
};
