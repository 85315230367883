import { EventTypes } from 'redux-segment';

import { notification } from '@unitoio/mosaic';

import { appTypes, routes } from 'consts';
import { getOrganizationById, getNonExpiredOrFirstOrganizationId, getUserId, getUserEmail } from 'reducers';
import { fromLocalStorage } from 'utils';
import { trackingActions } from 'actions';

export const getAppConfig = () => async (dispatch) => {
  const response = await dispatch({
    types: [appTypes.GET_APP_CONFIG_REQUEST, appTypes.GET_APP_CONFIG_SUCCESS, appTypes.GET_APP_CONFIG_FAILURE],
    isProtectedUrl: false,
    url: routes.API_PATHS.GET_APP_CONFIG,
  });

  /* eslint-disable no-console */
  if (process.env.NODE_ENV !== 'test') {
    console.group('%c.:: VERSIONS ::.', 'color: blue; font-size: 13px');
    console.log(`%c - Console v${response.appVersion}`, 'color: blue; font-size: 13px');
    console.log(`%c - Connectors v${response.connectorsVersion}`, 'color: blue; font-size: 13px');
    console.log(`%c - Ucommon v${response.ucommonVersion}`, 'color: blue; font-size: 13px');
    console.log(`%c - Build ${process.env.REACT_APP_ENV}`, 'color: blue; font-size: 13px');
    console.groupEnd();
  }
  /* eslint-enable no-console */

  return response;
};

export const getQueryParameters = (data) => ({
  type: appTypes.GET_QUERY_PARAMETERS,
  payload: data,
});

export const getIsEmbed = (pathname) => {
  const [, embed, providerName] = pathname.split('/');
  const embedName = embed === 'embed' ? providerName : '';

  return {
    type: appTypes.GET_IS_EMBED,
    payload: embedName,
  };
};

export const updateEmbedLocally = (embedName) => ({
  type: appTypes.UPDATE_EMBED_LOCALLY,
  payload: embedName,
});

export const setSelectedOrganizationId = (selectedOrganizationId) => (dispatch, getState) => {
  const orgIdFromLocalStorage = fromLocalStorage.loadStateByKey(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY);

  let isOrgInLocalStorageValid = !!orgIdFromLocalStorage;
  if (orgIdFromLocalStorage) {
    const localStorageOrg = getOrganizationById(getState(), orgIdFromLocalStorage);
    isOrgInLocalStorageValid = !localStorageOrg.isEmpty();

    if (!isOrgInLocalStorageValid) {
      try {
        fromLocalStorage.clearState(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY);
      } catch (err) {
        notification.warning({
          message: 'Local storage error',
          description: "Unito needs to access your browser's local storage for you to be able to switch organizations",
          placement: 'topLeft',
          duration: 0,
        });
      }
    }
  }

  // set the global organization context either to the one found in localStorage or to the first fetched organization
  let organizationId;
  if (selectedOrganizationId) {
    organizationId = selectedOrganizationId;
  } else if (isOrgInLocalStorageValid) {
    organizationId = orgIdFromLocalStorage;
  } else {
    organizationId = getNonExpiredOrFirstOrganizationId(getState());
  }

  try {
    fromLocalStorage.saveState(appTypes.LOCAL_STORAGE_ORGANIZATION_KEY, organizationId);
  } catch (err) {
    notification.warning({
      message: 'Local storage error',
      description: "Unito needs to access your browser's local storage for you to be able to switch organizations",
      placement: 'topLeft',
      duration: 0,
    });
  }

  const response = dispatch({
    type: appTypes.SET_SELECTED_ORGANIZATION,
    payload: organizationId,
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: getUserId(getState()),
          traits: {
            email: getUserEmail(getState()),
            most_recent_workspace_id: organizationId,
          },
        },
      },
    },
  });
  dispatch(
    trackingActions.trackEvent('HUBSPOT_FORCE_IDENTIFY_USER_WSID', {
      userId: getUserId(getState()),
      email: getUserEmail(getState()),
      organizationId,
    }),
  );
  return response;
};

export const getScripts = () => ({
  types: [appTypes.GET_SCRIPTS_REQUEST, appTypes.GET_SCRIPTS_SUCCESS, appTypes.GET_SCRIPTS_FAILURE],
  method: routes.METHODS.GET,
  url: routes.API_PATHS.GET_SCRIPTS,
});

export const setSiteadminSearchString = (siteAdminSearchString) => ({
  type: appTypes.SET_SITEADMIN_SEARCH_STRING_LOCALLY,
  payload: siteAdminSearchString,
});

export const runScript = (scriptId, kwargs, wetRun) => ({
  types: [appTypes.RUN_SCRIPT_REQUEST, appTypes.RUN_SCRIPT_SUCCESS, appTypes.RUN_SCRIPT_FAILURE],
  method: routes.METHODS.POST,
  url: routes.API_PATHS.RUN_SCRIPT(scriptId),
  payload: {
    kwargs,
    dryRun: !wetRun,
  },
});

export const clearScriptOutput = () => ({ type: appTypes.CLEAR_SCRIPT_OUTPUT });

export const setProductNameContext = (productName = appTypes.PRODUCT_NAMES.PROJECT_SYNC) => ({
  type: appTypes.SET_PRODUCT_NAME_CONTEXT,
  payload: { productName },
});
