import React from 'react';
import { useSelector } from 'react-redux';

import { getFeatureFlagValue, getSelectedOrganizationId } from 'reducers';
import { useTrackEvent } from 'hooks';
import { trackingTypes, routes, featureTypes } from 'consts';
import { NavbarHeader } from '~/components/NavbarStacked/NavbarHeader';
import { NavbarItem } from '~/components/NavbarStacked/NavbarItem';
import { NavbarStacked } from '~/components/NavbarStacked/NavbarStacked';

export function ProfileReportsMenu() {
  const trackEvent = useTrackEvent();
  const reports = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.EMBED_LOOKER_REPORTS));
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  if (!reports?.size) {
    return null;
  }

  return (
    <NavbarStacked>
      <NavbarHeader>Reports</NavbarHeader>
      {reports.map((report) => (
        <NavbarItem
          key={report.get('id')}
          to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${selectedOrganizationId}/reports/${report.get('id')}`}
          onClick={() =>
            trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
              action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.REPORTS_SECTION,
              report: report.get('name').toLowerCase(),
            })
          }
        >
          {report.get('name')}
        </NavbarItem>
      ))}
    </NavbarStacked>
  );
}
