import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map, List } from 'immutable';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import { trackingTypes } from 'consts';
import { Button } from '~/components/Button/Button';
import { RadioButtonGroup } from '~/components/RadioButtonGroup/RadioButtonGroup';
import { RadioButton } from '~/components/RadioButton/RadioButton';
import { LinkRadioLabel } from '~/components/LinkItem/LinkRadioLabel';
import { Box } from '~/components/Box/Box';

import { trackableHoC } from '../../containers/TrackableHoC/TrackableHoC';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const ButtonBar = styled(Box)`
  border-radius: ${tokens.spacing.s0} ${tokens.spacing.s0} ${tokens.spacing.s4} ${tokens.spacing.s4};
  border-top: 1px solid ${tokens.colors.content.neutral.n10};
  display: flex;
  justify-content: flex-end;
`;

const FlowList = styled(Box)`
  min-height: 20rem;
  max-height: 27rem;
  overflow-y: auto;
`;

const FlowSelectionComponent = ({
  providerA,
  providerB,
  control,
  disabled = false,
  isSubmitting = false,
  existingSimilarLinks,
  trackEvent,
  handleOnSubmit,
}) => {
  const renderFlowList = ({ field: { onChange, value } }) => (
    <RadioButtonGroup name="link" value={value} onChange={onChange}>
      {Object.values(existingSimilarLinks.toJS()).map((link) => (
        <RadioButton
          key={link._id}
          onClick={() => {
            trackEvent(trackingTypes.WORKFLOW_FLOW_DUPLICATE.FLOW_ACTION, {
              action_name: trackingTypes.WORKFLOW_FLOW_DUPLICATE.ACTIONS.SELECTED_FLOW,
              selected_tool_names: `${providerA.get('name')},${providerB.get('name')}`,
              link: {
                name: link.name,
              },
            });
          }}
          isBoxed
          label={<LinkRadioLabel link={fromJS(link)} />}
          value={link._id}
        />
      ))}
    </RadioButtonGroup>
  );

  return (
    <Fragment>
      <FlowList $m={[tokens.spacing.s0, tokens.spacing.s0, 'auto']}>
        <Bold>Pick the flow to start from :</Bold>
        <Controller render={renderFlowList} name="linkId" control={control} />
      </FlowList>
      <ButtonBar
        $p={[tokens.spacing.s4, tokens.spacing.s0, tokens.spacing.s0]}
        $m={[tokens.spacing.s7, tokens.spacing.s0, tokens.spacing.s0]}
      >
        <Button disabled={disabled} onClick={handleOnSubmit} name="submitting">
          {isSubmitting && <Icon name="spinner" kind={Icon.KINDS.SOLID} pulse />} Select flow
        </Button>
      </ButtonBar>
    </Fragment>
  );
};

FlowSelectionComponent.propTypes = {
  providerA: PropTypes.instanceOf(Map).isRequired,
  providerB: PropTypes.instanceOf(Map).isRequired,
  existingSimilarLinks: PropTypes.instanceOf(List).isRequired,
  control: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }).isRequired,
  trackEvent: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export const FlowSelection = trackableHoC(FlowSelectionComponent);
