import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { routes } from 'consts';
import classnames from 'classnames';

import { LinkItem } from '~/components/LinkItem/LinkItem';
import './LinkList.scss';

export function LinkList({ linkList }) {
  const linkListClasses = classnames('link-list', {
    'link-list--empty': linkList.isEmpty(),
  });

  return (
    <div className={linkListClasses}>
      {linkList
        .valueSeq()
        .map((sync) => {
          const editSyncUrl = `${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${sync.get('_id')}`;

          return (
            <LinkItem
              key={sync.get('_id')}
              sync={sync}
              editSyncUrl={editSyncUrl}
              providerNameA={sync.getIn(['A', 'providerName'])}
              providerNameB={sync.getIn(['B', 'providerName'])}
              showActions
            />
          );
        })
        .toArray()}
    </div>
  );
}

LinkList.propTypes = {
  linkList: PropTypes.instanceOf(List).isRequired,
};
