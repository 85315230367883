import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { formValueSelector, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { Icon } from '@unitoio/mosaic';

import { authUtils } from 'utils';
import { Button } from '~/components/Button/Button';
import { Collapse } from '~/components/Collapse/Collapse';
import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { TextAreaInput } from '~/components/TextAreaInput/TextAreaInput';
import { TextInput } from '~/components/TextInput/TextInput';
import { Title } from '~/components/Title/Title';
import { AuthorizationMethods } from './AuthorizationMethods';
import { AuthButtonsWrapper } from './AuthButtonsWrapper';

class AuthConfigureComponent extends Component {
  static propTypes = {
    domain: PropTypes.string,
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    provider: PropTypes.instanceOf(Map).isRequired,
    requiresSetup: PropTypes.bool,
    visibleAuthorizationMethods: PropTypes.instanceOf(Map).isRequired,
    authorizationMethod: PropTypes.string.isRequired,
    testConnectionAndAuthenticate: PropTypes.func.isRequired,
    windowOpenerId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    domain: '',
    requiresSetup: false,
  };

  state = {
    isOpenConnectionParams: true,
  };

  shouldShowInstructions = () => {
    const { authorizationMethod, provider, requiresSetup } = this.props;

    const fields = authUtils.getProviderAuthorizationMethodFields(provider, authorizationMethod);
    const hasSetupInstructions = authUtils.authorizationMethodHasSetupInstructions(provider, authorizationMethod);

    return requiresSetup && (!fields.isEmpty() || hasSetupInstructions);
  };

  render() {
    const {
      domain,
      match,
      provider,
      requiresSetup,
      testConnectionAndAuthenticate,
      visibleAuthorizationMethods,
      windowOpenerId,
    } = this.props;
    const { isOpenConnectionParams } = this.state;
    const connectionParameters = authUtils.getProviderConnectionParameters(provider);
    const hasConnectionParameters = !connectionParameters.isEmpty();

    if (hasConnectionParameters && !domain) {
      return <Redirect to=".." />;
    }

    // If there's only 1 authorization method, we go straight to instructions because <AuthorizationMethods />.
    // hides itself in that case, and the user will just get an empty "Connect to Airtable ---- Cancel -- OK page."
    // Kind of pointless.
    if (visibleAuthorizationMethods.size === 1 && !hasConnectionParameters) {
      return <Redirect to={`${match.url}/instructions?windowOpenerId=${windowOpenerId}`} />;
    }

    const showInstructions = this.shouldShowInstructions();
    const connectionParameterFields = authUtils
      .getProviderConnectionParameterFields(provider)
      .filter((field) => field.get('fieldName') !== 'domain');
    return (
      <div className="auth-configure">
        {!connectionParameterFields.isEmpty() && (
          <Section>
            <Title type="h3">
              Advanced settings
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                btnStyle="subtleLink"
                onClick={() => this.setState((state) => ({ isOpenConnectionParams: !state.isOpenConnectionParams }))}
                pullRight
                name="show details"
              >
                <Icon name="chevron-down" kind={Icon.KINDS.SOLID} />
              </Button>
            </Title>
            <p style={{ marginBottom: '2rem' }}>{connectionParameters.get('help')}</p>
            <Collapse isCollapsed={!isOpenConnectionParams}>
              {connectionParameterFields
                .filter((field) => field.get('fieldName') !== 'domain')
                .map((field) => {
                  const isTextArea = field.get('type') === 'string' && field.get('multiline');
                  return (
                    <Field
                      key={field.get('fieldName')}
                      name={field.get('fieldName')}
                      component={isTextArea ? TextAreaInput : TextInput}
                      props={{
                        label: (
                          <Title type="h4">
                            {field.get('label')} {field.get('required') ? <small>(Required)</small> : null}
                          </Title>
                        ),
                        helpText: (
                          <>
                            {field.get('help')}.{' '}
                            {field.get('unitoDocUrl') ? (
                              <Href href={field.get('unitoDocUrl')}>More information</Href>
                            ) : null}
                          </>
                        ),
                        placeholder: field.get('placeholder'),
                      }}
                    />
                  );
                })
                .toArray()}
            </Collapse>
            <hr />
          </Section>
        )}
        {!visibleAuthorizationMethods.isEmpty() && (
          <Field
            name="authorizationMethod"
            component={AuthorizationMethods}
            props={{
              requiresSetup,
              authorizationMethods: visibleAuthorizationMethods,
              domain,
            }}
          />
        )}

        <AuthButtonsWrapper className="clearfix">
          <Button
            btnStyle="dark"
            onClick={!showInstructions ? testConnectionAndAuthenticate : undefined}
            pullRight
            to={showInstructions ? `${match.url}/instructions?windowOpenerId=${windowOpenerId}` : undefined}
            type={showInstructions ? 'href' : undefined}
          >
            Continue
          </Button>
          <Button reverse btnStyle="dark" onClick={() => window.close()}>
            Cancel
          </Button>
        </AuthButtonsWrapper>
      </div>
    );
  }
}

const formSelector = formValueSelector('authentication');

const mapStateToProps = (state) => ({
  domain: formSelector(state, 'domain'),
  authorizationMethod: formSelector(state, 'authorizationMethod'),
});

export const AuthConfigure = connect(mapStateToProps)(AuthConfigureComponent);
