import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { SearchInput, Select } from '@unitoio/mosaic';

import { activityLogsActions, trackingActions } from 'actions';
import {
  getActivityLogs,
  getIsLoadingActivityLogs,
  getLastActivityLogsFetchTimestamp,
  getSelectedOrganizationId,
  getFeatureFlagValue,
} from 'reducers';
import { useDataTable, useDebounce, useInterval } from 'hooks';
import { borderRadius, color } from 'theme';
import { trackingTypes } from 'consts';
import { Box } from '~/components/Box/Box';
import { Button } from '~/components/Button/Button';
import { ActivityLogsDataTable } from '~/components/ActivityLogsDataTable/ActivityLogsDataTable';
import { ActivityLogsPaginator } from '~/components/ActivityLogsDataTable/ActivityLogsPaginator';
import { Href } from '~/components/Href/Href';
import { Title } from '~/components/Title/Title';

import { StreamingSelector } from './StreamingSelector';
import {
  activityLogsColumns,
  activityStatusOptions,
  activityTypesOptions,
  pageSizeOptions,
  timeOptions,
  dataTableSort,
} from './activityLogsConstants';

const ActivityLogsPage = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4.625rem);

  .title {
    margin: 0;
  }
`;

const TopBlockWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TopBlock = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 55%;
  align-items: center;
  justify-content: space-between;
`;

const TableBlock = styled.div`
  flex-grow: 1;
  overflow: scroll;
  border: 1px solid ${color.background.global.gray};
  border-radius: ${borderRadius.large};
`;

const FiltersBlock = styled.section`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-wrap: wrap;

  & > * {
    margin-right: 0.5rem;
  }
`;

const TextFilter = styled.div`
  width: 370px;
`;

const SelectFilter = styled.div`
  width: ${(props) => (props.$isActivityType ? '14rem' : '11rem')};
`;

const ClearAllButton = styled.div`
  button {
    padding-left: 0;
  }
`;

const PaginatorBlock = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  > &:last-child {
    width: 10.3rem;
  }
`;

const MORE_DATA_TEXT = 'Can’t find what you are looking for?';

export function ActivityLogs() {
  const maxSearchLimit = useSelector((state) => getFeatureFlagValue(state, 'activity-logs-max-search-duration'));
  const filteredTimeOptions = timeOptions.filter((option) => option.value <= maxSearchLimit);

  const [activityTypes, setActivityTypes] = useState([]);
  const [activityStatus, setActivityStatus] = useState(null);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0].value);

  const [selectedTime, setSelectedTime] = useState(filteredTimeOptions[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(0);
  const [isStreaming, setIsStreaming] = useState(false);
  const organizationId = useSelector(getSelectedOrganizationId);
  const lastFetchTimestamp = useSelector(getLastActivityLogsFetchTimestamp);
  const isLoading = useSelector(getIsLoadingActivityLogs);
  const activityLogsData = useSelector(getActivityLogs);

  const tableData = activityLogsData.get('activityLogs').toJS();
  const recordsCount = activityLogsData.get('activityLogsCount');

  const dispatch = useDispatch();
  const debouncedDispatch = useDebounce(dispatch, 500);

  // This is weird you might ask. Why are we relying on a state based loading flag, if we have 'isLoading' in the store?
  // Moreover, how come we're setting localIsLoading based on isLoading?
  // The answer is that isLoading is updated when we start fetching a request, and that is a debounced action. This means
  // around 500ms are going to pass between a user pressing a pagination button, and us launching the fetch action.
  // This also means the user is going to only see the loader 500ms after clicking, which gives the impression of a sluggish
  // application.
  // To counteract this we use a state based loading flag that depends on the store's isLoading. If the store changes,
  // we'll update localIsLoading.
  // We'll also set it to true instantly every time an action that should cause a fetch happens regardless of debouncing,
  // to give the impression that a request has already launched when in fact it did not.
  // It gives the illusion that something is happening.
  const [localIsLoading, setStatedLoading] = useState(isLoading);

  const getSinceInMs = () => Date.now() - selectedTime;

  useEffect(() => {
    setStatedLoading(isLoading);
  }, [isLoading, setStatedLoading]);

  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const queryParamSearch = queryParams.get('search') || '';
  useEffect(() => {
    setSearchValue(queryParamSearch);
  }, [queryParamSearch, setSearchValue]);

  function fetchActivityLogs(
    sinceInMs,
    selectedPage,
    selectedPageSize,
    selectedSearchValue,
    selectedActivityStatus,
    selectedActivityTypes,
    shouldClearResults = true,
  ) {
    setStatedLoading(true);
    const draveurSort = dataTableSort.map((tableSortInfo) => ({
      field: tableSortInfo.id,
      direction: tableSortInfo.desc ? 'desc' : 'asc',
    }));

    const draveurFilters = {};
    if (selectedSearchValue) {
      draveurFilters.generalSearch = selectedSearchValue;
    }

    switch (selectedActivityStatus) {
      case 'failed':
        draveurFilters.publisherErrorRetryable = false;
        break;
      case 'retrying':
        draveurFilters.publisherErrorRetryable = true;
        break;
      case 'success':
        draveurFilters.publisherErrorRetryable = null;
        break;
      default: // Do nothing
    }

    // If a user selects 2 statuses and removes them, then this will be an empty array rather than undefined.
    if (selectedActivityTypes.length > 0) {
      draveurFilters.activityTypes = selectedActivityTypes;
    }

    debouncedDispatch(
      activityLogsActions.fetchActivityLogs(
        organizationId,
        selectedPage,
        selectedPageSize,
        draveurFilters,
        draveurSort,
        sinceInMs,
        shouldClearResults,
      ),
    );
  }

  useInterval(
    () => {
      if (!isLoading) {
        // We'll ask for last documents since the last fetch minus 15 seconds in order to rule out possible activity logs
        // that might we might have missed in between two live stream modes.
        // Duplicate documents will be filtered out by the activity logs reducer.
        fetchActivityLogs(
          lastFetchTimestamp - 15 * 1000,
          null,
          null,
          searchValue,
          activityStatus,
          activityTypes,
          false,
        );
      }
    },
    // Setting the interval to null turns it off.
    isStreaming ? 5000 : null,
  );

  const dataTableData = useDataTable({
    dataTableSort,
    recordsCount,
    columns: activityLogsColumns,
    data: tableData,
    fetchData: dataTableChanged,
    currentPage: page,
    pageSize,
  });

  function clearFilters() {
    setSearchValue('');
    setActivityStatus(null);
    setActivityTypes([]);

    handleNewQueryEvent(() => {}, {
      searchValue: '',
      activityStatus: null,
      activityTypes: [],
    });
  }

  function dataTableChanged(selectedPage, selectedPageSize) {
    // Used because Draveur expects a different format for the sort information.
    const pageSizeOption = pageSizeOptions.find((option) => option.value === selectedPageSize);

    setPage(selectedPage);
    setPageSize(pageSizeOption.value);

    if (!isStreaming) {
      fetchActivityLogs(getSinceInMs(), selectedPage, pageSizeOption.value, searchValue, activityStatus, activityTypes);
    }
  }

  function handleNewQueryEvent(stateUpdater, updatedValues) {
    stateUpdater();
    const queryPageSize = updatedValues.pageSize || pageSize;
    const querySearchValue = updatedValues.searchValue !== undefined ? updatedValues.searchValue : searchValue;
    const queryActivityStatus =
      updatedValues.activityStatus !== undefined ? updatedValues.activityStatus : activityStatus;
    const queryActivityTypes = updatedValues.activityTypes || activityTypes;
    const sinceInMs = updatedValues.sinceInMs || getSinceInMs();

    fetchActivityLogs(sinceInMs, 0, queryPageSize, querySearchValue, queryActivityStatus, queryActivityTypes);
  }

  async function onStreamingChanged(newIsStreaming) {
    setPage(0);
    await fetchActivityLogs(getSinceInMs(), 0, pageSize, searchValue, activityStatus, activityTypes);
    setIsStreaming(newIsStreaming);
  }

  function trackMoreData() {
    dispatch(
      trackingActions.trackEvent(trackingTypes.DIAGNOSTIC.ACTION, {
        action_name: `clicked on ${MORE_DATA_TEXT.toLocaleLowerCase()}`,
      }),
    );
  }

  const TableEmptyState = (
    <Box $m={[2, 0]}>
      <Title type="h3">No results.</Title>
      <p>There are no changes matching the current filters.</p>
    </Box>
  );

  return (
    <ActivityLogsPage $m={[0, 1]}>
      <TopBlockWrapper>
        <TopBlock $m={[1.5, 1, 1.5, 0]}>
          <Title>Activity Stream</Title>
          <Href
            onClick={() => trackMoreData()}
            target="_blank"
            href="https://form.typeform.com/to/tdEbSfnU?typeform-source=unito1.typeform.com#id={{id}}&email={{email}}"
          >
            {MORE_DATA_TEXT}
          </Href>
        </TopBlock>
      </TopBlockWrapper>
      <FiltersBlock>
        <Filters>
          <TextFilter>
            <SearchInput
              placeholder="Search by url, block of work, flow, workflow..."
              value={searchValue}
              onChange={(e) =>
                handleNewQueryEvent(() => setSearchValue(e.target.value), { searchValue: e.target.value })
              }
            />
          </TextFilter>
          <SelectFilter>
            <Select
              options={filteredTimeOptions}
              value={selectedTime}
              placeholder="Select time interval"
              onChange={(newTime) => {
                handleNewQueryEvent(() => setSelectedTime(newTime), {
                  sinceInMs: Date.now() - newTime,
                });
              }}
              size="md"
            />
          </SelectFilter>
          <SelectFilter $isActivityType>
            <Select
              placeholder="Select activity type(s)"
              options={activityTypesOptions}
              multiSelect
              onChange={(newActivityTypes) => {
                handleNewQueryEvent(() => setActivityTypes(newActivityTypes), { activityTypes: newActivityTypes });
              }}
              value={activityTypes}
              size="md"
            />
          </SelectFilter>
          <SelectFilter>
            <Select
              placeholder="Select a status"
              options={activityStatusOptions}
              onChange={(newActivityStatus) => {
                handleNewQueryEvent(() => setActivityStatus(newActivityStatus), {
                  activityStatus: newActivityStatus,
                });
              }}
              value={activityStatus}
              size="md"
            />
          </SelectFilter>

          {(activityTypes.length || activityStatus !== null || searchValue) && (
            <ClearAllButton>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Button noPadding onClick={clearFilters} btnStyle="subtleLink" textAlign="left">
                Clear all
              </Button>
            </ClearAllButton>
          )}
        </Filters>
        <StreamingSelector
          isStreaming={isStreaming}
          lastUpdateTimestamp={lastFetchTimestamp}
          onStreamingChanged={onStreamingChanged} // eslint-disable-line react/jsx-no-bind
        />
      </FiltersBlock>
      <TableBlock>
        <ActivityLogsDataTable
          dataTableData={dataTableData}
          hasData={tableData.length !== 0}
          isLoading={localIsLoading && !isStreaming}
          emptyStateComponent={TableEmptyState}
        />
      </TableBlock>
      {!isStreaming && (
        <PaginatorBlock $m={[1.5, 0]}>
          <ActivityLogsPaginator dataTableData={dataTableData} />
          <Select
            options={pageSizeOptions}
            onChange={(newPageSize) => {
              handleNewQueryEvent(() => setPageSize(newPageSize), { pageSize: newPageSize });
            }}
            value={pageSize}
            placeholder={pageSizeOptions[0].label}
            size="md"
          />
        </PaginatorBlock>
      )}
    </ActivityLogsPage>
  );
}
