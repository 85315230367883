import React from 'react';
import { createPortal } from 'react-dom';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import { Icon } from '@unitoio/mosaic';

import { color, borderRadius } from 'theme';
import { useMediaQuery } from 'hooks';
import { Button } from '~/components/Button/Button';
import { Title } from '~/components/Title/Title';
import { Box } from '~/components/Box/Box';

// Taken from https://reactjs.org/docs/events.html
const DEFAULT_EVENT_LISTENERS = [
  // Clipboard
  'onCopy',
  'onCut',
  'onPaste',
  // Composition - seldom used?
  // 'onCompositionEnd', 'onCompositionStart', 'onCompositionUpdate',
  // Keyboard
  'onKeyDown',
  'onKeyPress',
  'onKeyUp',
  // Focus - doesn't bubble, blocking them will prevent native listeners from working
  // 'onFocus', 'onBlur',
  // Form
  'onChange',
  'onInput',
  'onInvalid',
  'onSubmit',
  // Mouse
  'onClick',
  'onContextMenu',
  'onDoubleClick',
  'onMouseDown',
  'onMouseUp',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseOut',
  'onMouseOver',
  // Drag & drop
  'onDrag',
  'onDragEnd',
  'onDragEnter',
  'onDragExit',
  'onDragLeave',
  'onDragOver',
  'onDragStart',
  'onDrop',
  // Pointer
  'onPointerDown',
  'onPointerMove',
  'onPointerUp',
  'onPointerCancel',
  'onPointerEnter',
  'onPointerLeave',
  'onPointerOver',
  'onPointerOut',
  // Selection
  'onSelect',
  // Touch
  'onTouchCancel',
  'onTouchEnd',
  'onTouchMove',
  'onTouchStart',
  // UI
  'onScroll',
  // Wheel
  'onWheel',
  // Media
  'onAbort',
  'onCanPlay',
  'onCanPlayThrough',
  'onDurationChange',
  'onEmptied',
  'onEncrypted',
  'onEnded',
  'onError',
  'onLoadedData',
  'onLoadedMetadata',
  'onLoadStart',
  'onPause',
  'onPlay',
  'onPlaying',
  'onProgress',
  'onRateChange',
  'onSeeked',
  'onSeeking',
  'onStalled',
  'onSuspend',
  'onTimeUpdate',
  'onVolumeChange',
  'onWaiting',
  // Image
  'onLoad',
  'onError',
  // Animation
  'onAnimationStart',
  'onAnimationEnd',
  'onAnimationIteration',
  // Transition
  'onTransitionEnd',
  // Other
  'onToggle',
];

const EVENT_TYPE_WHITELIST = {
  CustomEvent: true,
  Event: true,
  FocusEvent: true,
  KeyboardEvent: true,
  MouseEvent: true,
  PointerEvent: true,
  TouchEvent: true,
  WheelEvent: true,
};

const EVENT_PROP_OVERRIDES = {
  eventPhase: Event.BUBBLING_PHASE,
  currentTarget: window,
};

/**
 * Stops the propagation of a React event within its component hierarchy while
 * also dispatching a clone of it to `window`.
 *
 * Event types not present in EVENT_TYPE_WHITELIST can't be cloned, and will
 * instead be allowed through.
 *
 * @param {React.SyntheticEvent} event - React event
 * @return {Event|null} Cloned native event dispatched to `window`
 */
function stopAndRedispatchEventOnWindow(event) {
  const { nativeEvent } = event;

  const eventType = nativeEvent.constructor.name || {}.toString.call(nativeEvent.constructor).slice(8, -1);

  if (!EVENT_TYPE_WHITELIST[eventType]) {
    return null;
  }

  event.stopPropagation();

  // Clone original native event
  let windowEvent;
  try {
    windowEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);
  } catch (error) {
    // Legacy initialization of events for IE
    // IE prevents the read-only property `target` from being set via
    // `defineProperty`. This makes it seemingly impossible to clone events in IE11.

    // eslint-disable-next-line no-console
    console.error(`Unable to clone ${eventType}(${event.type})`, error);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const prop in nativeEvent) {
    if (prop !== 'isTrusted' && typeof nativeEvent[prop] !== 'function') {
      Object.defineProperty(windowEvent, prop, {
        writable: false,
        value: EVENT_PROP_OVERRIDES[prop] || nativeEvent[prop],
      });
    }
  }

  requestAnimationFrame(() => {
    window.dispatchEvent(windowEvent);
  });

  return windowEvent;
}

/**
 * Portal which by default doesn't bubble events triggered within it to React
 * parent components.
 *
 * Adds blocking event handlers for most SyntheticEvents until
 * native support for event bubbling prevention is added.
 *
 * See https://github.com/facebook/react/issues/11387
 */
const blockedProps = DEFAULT_EVENT_LISTENERS.reduce(
  (acc, eventName) => ({
    ...acc,
    [eventName]: stopAndRedispatchEventOnWindow,
  }),
  {},
);

const StyledModal = styled(Box)`
  background-color: ${color.dark.secondary};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500;
`;

const SIZE_CONTROL_HEIGHT_BY_MODAL_SIZE = {
  full: {
    marginTop: '-2.125rem',
    marginLeftRight: '8.25rem',
    maxHeight: 'calc(100vh - 4.25rem)',
    minWidth: '87.5rem',
  },
  small: {
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeftRight: 'auto',
    maxWidth: '40rem',
    overflowY: 'visible',
  },
  medium: {
    marginLeftRight: 'auto',
    marginTop: 'calc((100vh - 44rem)/2 - 4.25rem)', // always centered vertically
    maxWidth: '50rem',
    maxHeight: '45rem',
  },
};

const ModalBody = styled.div.attrs((props) => SIZE_CONTROL_HEIGHT_BY_MODAL_SIZE[props.$size])`
  background-color: ${color.content.neutral.white};
  border-radius: ${borderRadius.large};
  display: flex;
  max-height: ${(props) => props.maxHeight};
  margin: ${(props) => props.marginTop || 0} ${(props) => props.marginLeftRight} 4.25rem
    ${(props) => props.marginLeftRight};
  max-width: ${(props) => props.maxWidth};
  overflow-y: ${(props) => props.overflowY};
  transform: ${(props) => props.transform};
  top: ${(props) => props.top};
  position: relative;
  flex-direction: column;
  opacity: ${(props) => (props.$state === 'entered' ? 1 : 0)};
`;

const CloseModalButton = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
`;

const MoreOptionsButton = styled(Box)`
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
`;

const ModalHeader = styled(Box)`
  ${(props) => props.$hasOptions && `border-bottom: 1px solid ${color.content.neutral.disabled}`};
  position: relative;

  .title {
    margin: 0;
  }
`;

const ModalContent = styled(Box)`
  overflow-y: ${(props) => (props.$size === 'full' ? 'auto' : 'visible')};
  height: ${(props) => (props.$size === 'small' ? '100%' : 'calc(100% - 5.25rem)')};
  flex-direction: column;
  display: flex;
`;

const modalRoot = document.getElementById('root');
const Modal = React.memo(
  ({
    children,
    onClose = () => null,
    render,
    modalOptions = null,
    path,
    closeOnClickOutside = true,
    title,
    titleAlign = 'left',
    size = 'full',
  }) => {
    const isEmbeddedInSmallFrame = useMediaQuery(['(max-height: 600px)'], [true], false);
    const history = useHistory();
    // The route to go back to once the modal is closed (via close, x button or back history)
    const parentMatch = useRouteMatch();

    function handleOnClose() {
      onClose();
      history.replace(parentMatch.url);
    }

    function handleOnClick(e) {
      e.stopPropagation();
      if (closeOnClickOutside) {
        handleOnClose();
      }
    }

    const paddingTopWrapper = isEmbeddedInSmallFrame ? 0 : 4.5;
    const paddingTopModal = isEmbeddedInSmallFrame ? 0 : 1.5;

    const ModalElement = (modalRouteProps) => (
      // eslint-disable-next-line react/jsx-no-bind
      <StyledModal {...blockedProps} onClick={handleOnClick} $p={[paddingTopWrapper, 0, 0]}>
        <Transition in timeout={200} appear mountOnEnter unmountOnExit>
          {(state) => (
            <ModalBody $size={size} $state={state} onClick={(e) => e.stopPropagation()}>
              <ModalHeader $hasOptions={!!modalOptions} $p={[1.5, 3, 1, 3]}>
                {(title || modalOptions) && (
                  <Title type="h2" align={titleAlign}>
                    {title}
                    {modalOptions && <MoreOptionsButton $m={[0, 1]}>{modalOptions}</MoreOptionsButton>}
                  </Title>
                )}
                <CloseModalButton>
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <Button onClick={handleOnClose} btnStyle="subtleLink" name="close">
                    <Icon name="times" kind={Icon.KINDS.SOLID} />
                  </Button>
                </CloseModalButton>
              </ModalHeader>
              <div id="routed-modal-root" />
              <ModalContent $size={size} $p={[paddingTopModal, 3, 0]} $m={[0, 0, 1.5]}>
                {children || render(modalRouteProps, handleOnClose)}
              </ModalContent>
            </ModalBody>
          )}
        </Transition>
      </StyledModal>
    );

    return <Route path={path} render={(routeProps) => createPortal(ModalElement(routeProps), modalRoot)} />;
  },
);

Modal.propTypes = {
  children: PropTypes.node,
  closeOnClickOutside: PropTypes.bool,
  modalOptions: PropTypes.element,
  onClose: PropTypes.func,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  render: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(SIZE_CONTROL_HEIGHT_BY_MODAL_SIZE)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleAlign: PropTypes.oneOf(['left', 'right', 'center']),
};

export { Modal as RoutedModal };
