import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';

import { useMediaQuery } from 'hooks';
import { Box } from '~/components/Box/Box';
import { Input } from '~/components/Input/Input';
import { ProviderIconButton } from '~/components/ProviderIconButton/ProviderIconButton';

const FlexContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  max-height: ${(props) => `${props.$maxHeight}rem`};
  overflow-y: auto;
`;

FlexContainer.propTypes = {
  maxHeight: PropTypes.number.isRequired,
};

const BoxCenter = styled(Box)`
  text-align: center;
`;

function useFilterProviders(providers, searchString) {
  const [filteredProviders, setFilteredProviders] = useState(providers);
  useEffect(() => {
    if (!searchString) {
      return setFilteredProviders(providers);
    }

    const providersList = providers.filter((provider) =>
      provider.get('displayName').toLowerCase().includes(searchString.toLowerCase()),
    );
    return setFilteredProviders(providersList);
  }, [providers, searchString]);

  return filteredProviders;
}

export const SelectProviders = ({
  providers,
  onSelect,
  onSearchTermEntered = () => {},
  searchEnabled = true,
  disableSubmit = false,
}) => {
  const [searchString, setSearchString] = useState('');
  const providersToRender = useFilterProviders(providers, searchString);

  // The iframe for the embedded playground uses a bounded height of 600 px
  // the media query helps displaying the SelectProviders a bit more appropriately
  // in the playground.
  const maxHeightRem = useMediaQuery(['(max-height: 600px)'], [15]) ?? 25;
  return (
    <div>
      {searchEnabled && (
        <Box $m={[0, 5.5, 1.5]}>
          <Input
            id="searchProvider"
            className="form-control"
            placeholder="Search for a tool..."
            maxLength={60}
            onChange={(event) => {
              if (searchString === '') {
                onSearchTermEntered();
              }
              setSearchString(event.target.value);
            }}
          />
        </Box>
      )}
      <FlexContainer $m={[0, -3, 0, 0]} $p={[0, 2, 0, 0]} $maxHeight={maxHeightRem}>
        {providersToRender
          .sortBy((provider) => provider.get('displayName'))
          .map((provider) => (
            <BoxCenter key={provider.get('_id')} $m={[0, 0]}>
              <ProviderIconButton
                onClick={() => onSelect(provider)}
                providerName={provider.get('name')}
                disabled={disableSubmit}
              />
              <Box $p={[0, 0.5, 0.75]}>{provider.get('displayName')}</Box>
            </BoxCenter>
          ))
          .toArray()}
      </FlexContainer>
    </div>
  );
};

SelectProviders.propTypes = {
  providers: PropTypes.instanceOf(Map).isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearchTermEntered: PropTypes.func,
  searchEnabled: PropTypes.bool,
  disableSubmit: PropTypes.bool,
};
