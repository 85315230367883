import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import moment from 'moment';

import { NewTag, NewTagColor } from '@unitoio/mosaic';

import {
  getEmbedName,
  getSelectedOrganizationId,
  getIsAuthenticated,
  isOnFreeTrial,
  isOrganizationAccountSuspended,
  isOrganizationTrialExpired,
  isOrganizationDelinquent,
  isOrganizationAccountPaused,
  getOrganizationById,
  getFeatureFlagValue,
} from 'reducers';
import { routes, trackingTypes } from 'consts';
import { Button } from '~/components/Button/Button';
import { Href } from '~/components/Href/Href';

class AccountStatusBadgeComponent extends Component {
  static propTypes = {
    accountIsDelinquent: PropTypes.bool.isRequired,
    accountIsPaused: PropTypes.bool.isRequired,
    accountIsSuspended: PropTypes.bool.isRequired,
    displayTrial: PropTypes.bool.isRequired,
    embedName: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    organization: PropTypes.instanceOf(Map).isRequired,
    trialIsExpired: PropTypes.bool.isRequired,
    trackEvent: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['button', 'label', 'link']),
    isTrialNavHidden: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    type: 'label',
    embedName: undefined,
    isAuthenticated: false,
  };

  getBadgeText = () => {
    const { accountIsDelinquent, accountIsSuspended, accountIsPaused, displayTrial, trialIsExpired, embedName } =
      this.props;

    if (displayTrial && !trialIsExpired) {
      // display a shorter label in embed to save a bit of space
      return embedName ? 'Trial' : 'Free trial';
    }

    if (trialIsExpired) {
      return 'Trial expired';
    }

    if (accountIsPaused) {
      return 'Subscription paused';
    }

    if (accountIsSuspended) {
      return 'Account suspended';
    }

    if (accountIsDelinquent) {
      return 'Last payment failed';
    }

    return null;
  };

  getIsTrialing = () => {
    const { displayTrial, trialIsExpired } = this.props;

    return displayTrial && !trialIsExpired;
  };

  getLabelType = () => {
    const { accountIsPaused } = this.props;

    if (this.getIsTrialing()) {
      return NewTagColor.POSITIVE;
    }

    if (accountIsPaused) {
      return NewTagColor.INFO;
    }

    return NewTagColor.ERROR;
  };

  render() {
    const { isAuthenticated, organization, trackEvent, type, isTrialNavHidden } = this.props;

    const badgeText = this.getBadgeText();

    const pricingPath = `${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organization.get('_id')}/pricing`;

    if (!isAuthenticated || !badgeText) {
      return null;
    }

    const isTrialing = this.getIsTrialing();
    const labelType = this.getLabelType();
    const daysLeft = moment(organization.get('validUntil')).fromNow(true);

    if (isTrialNavHidden && Number(daysLeft.slice(0, 2) > 13)) {
      return null;
    }

    if (type === 'button') {
      return (
        <Button
          type="href"
          onClick={() => {
            trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.PLAN_LABEL);
          }}
          to={pricingPath}
        >
          {badgeText} {isTrialing && `- ${daysLeft} left`}
        </Button>
      );
    }

    if (type === 'link') {
      return (
        <Href
          linkStyle={labelType === 'error' ? 'red' : 'darkBlue'}
          className="trial-badge"
          onClick={() => {
            trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.PLAN_LABEL);
          }}
          to={pricingPath}
        >
          <strong>{badgeText}</strong> {isTrialing && ` - ${daysLeft} left`}
        </Href>
      );
    }

    return (
      <Href
        size="md"
        type="href"
        onClick={() => {
          trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTIONS.PLAN_LABEL);
        }}
        to={pricingPath}
      >
        <NewTag color={labelType}>
          {badgeText} {isTrialing && ` - ${daysLeft} left`}
        </NewTag>
      </Href>
    );
  }
}

const mapStateToProps = (state) => {
  const organizationId = getSelectedOrganizationId(state);

  return {
    accountIsDelinquent: isOrganizationDelinquent(state, organizationId),
    accountIsSuspended: isOrganizationAccountSuspended(state, organizationId),
    accountIsPaused: isOrganizationAccountPaused(state, organizationId),
    displayTrial: isOnFreeTrial(state, organizationId),
    isAuthenticated: getIsAuthenticated(state),
    embedName: getEmbedName(state),
    isTrialNavHidden: getFeatureFlagValue(state, 'hide-free-trial-in-nav', organizationId),
    trialIsExpired: isOrganizationTrialExpired(state, organizationId),
    organization: getOrganizationById(state, organizationId),
  };
};

export const AccountStatusBadge = connect(mapStateToProps)(AccountStatusBadgeComponent);
