import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { Redirect, Route, Switch, withRouter, matchPath } from 'react-router-dom';

import { initDatadogRum, setDatadogUser } from '@unitoio/sherlock';

import { appActions, authActions, organizationActions, providerActions, fieldActions, websocketActions } from 'actions';
import {
  ActivateFlow,
  AuthenticationContainer,
  AuthSetup,
  Dashboard,
  NoWorkspacesPage,
  EmbedLoginContainer,
  ResetPasswordContainer,
  WorkItemStatus,
  WorkspaceDeletedLogoutPage,
  UnauthenticatedHeaderContainer,
} from 'containers';
import { authTypes, routes, trackingTypes } from 'consts';
import { getCurrentAbilities, getIsAuthenticated, getUserId, getSignupIntentProduct, getSignupSource } from 'reducers';
import { getSearchParams } from 'utils';
import { AbilityContext, TrackingFunnelContext } from 'contexts';
import { NotFound } from '~/components/NotFound/NotFound';
import { Loading } from '~/components/Loading/Loading';
import { Header } from '~/components/Header/Header';

class AppComponent extends Component {
  static propTypes = {
    ability: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string,
      }).isRequired,
    }).isRequired,
    initialFetch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    parseLocation: PropTypes.func.isRequired,
    rehydrateAuthState: PropTypes.func.isRequired,
    dispatchSignupEvent: PropTypes.func.isRequired,
    userId: PropTypes.string,
    signupIntentProduct: PropTypes.string,
    signupSource: PropTypes.string,
    disconnectWebSocket: PropTypes.func.isRequired,
    fetchProviders: PropTypes.func.isRequired,
    fetchCapabilitiesFieldTypes: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userId: null,
    signupIntentProduct: null,
    signupSource: null,
  };

  static wasUserInvited = () => !!Cookies.get('invitationId');

  state = {
    errorOnMount: null,
    isLoading: true,
  };

  async componentDidMount() {
    const {
      initialFetch,
      location,
      parseLocation,
      rehydrateAuthState,
      dispatchSignupEvent,
      fetchProviders,
      fetchCapabilitiesFieldTypes,
    } = this.props;
    const [, embed, providerName] = location.pathname.split('/');
    const isEmbed = embed === 'embed';

    try {
      parseLocation();

      initDatadogRum();

      this.setState({ isLoading: true });

      await rehydrateAuthState();
      await initialFetch();

      fetchProviders();
      fetchCapabilitiesFieldTypes();

      this.setState({ isLoading: false });

      dispatchSignupEvent();

      // Clear cookie to avoid issue #1401
      if (isEmbed && providerName === 'wrike') {
        window.addEventListener('beforeunload', this.clearCookie);
      }

      if (BroadcastChannel) {
        // Reload page when user logs out from another tab
        const bcUserEvents = new BroadcastChannel('UserEvents');
        bcUserEvents.onmessage = (event) => {
          if (event?.data?.action === 'logout') {
            bcUserEvents.close();
            window.location.reload();
          }
        };
      }
    } catch (err) {
      this.setState({ errorOnMount: err });
    }
  }

  componentDidUpdate(prevProps) {
    const { userId, signupSource, signupIntentProduct, isAuthenticated, disconnectWebSocket } = this.props;

    if (
      userId &&
      (userId !== prevProps.userId ||
        signupSource !== prevProps.signupSource ||
        signupIntentProduct !== prevProps.signupIntentProduct)
    ) {
      this.setDatadogRumData();
    }

    if (prevProps.isAuthenticated && !isAuthenticated) {
      disconnectWebSocket();
    }
  }

  setDatadogRumData = () => {
    const { userId } = this.props;
    setDatadogUser(userId);
  };

  clearCookie = () => {
    Cookies.remove(process.env.REACT_APP_UNITO_AUTH_COOKIE_NAME, {
      domain: process.env.NODE_ENV === 'production' ? '.unito.io' : undefined,
    });
    window.removeEventListener('beforeunload', this.clearCookie);
  };

  render() {
    const { ability, history, location, isAuthenticated } = this.props;
    const { errorOnMount, isLoading } = this.state;

    if (errorOnMount) {
      throw errorOnMount;
    }

    if (isLoading) {
      return <Loading />;
    }

    const nonAuthRedirectUrl = AppComponent.wasUserInvited()
      ? authTypes.AUTH_ACTIONS.SIGNUP
      : authTypes.AUTH_ACTIONS.LOGIN;

    return (
      <AbilityContext.Provider value={ability}>
        <div className="content">
          {isAuthenticated ? (
            <Switch>
              {/* This route is opened in a iframe in the Google Sheets addon. */}
              <Route exact path="/one-click-activate-flow" render={(routeProps) => <ActivateFlow {...routeProps} />} />
              <Route
                exact
                path="/expose-token"
                render={() => {
                  window.location.assign(`${routes.BASE_API}/${routes.API_PATHS.EXPOSE_TOKEN}`);
                }}
              />
              <Route
                exact
                path="/no-workspaces"
                render={() => (
                  // eslint-disable-next-line react/jsx-no-constructed-context-values
                  <TrackingFunnelContext.Provider value={{ name: trackingTypes.PAGE.NO_WORKSPACE }}>
                    <NoWorkspacesPage />
                  </TrackingFunnelContext.Provider>
                )}
              />
              <Route
                path={['/embed/:embedName/dashboard', '/dashboard']}
                render={(routeProps) => {
                  const match = matchPath(routeProps.location.pathname, {
                    path: '/embed/:embedName/dashboard/organizations/:organizationId',
                    exact: false,
                    strict: false,
                  });
                  return <Dashboard {...routeProps} organizationId={match?.params?.organizationId} />;
                }}
              />
              <Redirect
                exact
                from="/embed/:embedName/(login|signup)"
                to={`/embed/:embedName/dashboard${history.location.search || ''}`}
              />
              <Redirect exact from="/(login|signup|sso)" to={`/dashboard${history.location.search || ''}`} />
              <Redirect exact from="/" to={`/dashboard${history.location.search || ''}`} />
              <Redirect exact from="/items/:itemId/status" to={`/dashboard${location.pathname}`} />
              <Route
                // This is when coming from the integration page
                path="/:method(login|signup|connect)/:providerName"
                render={(routeProps) => (
                  // eslint-disable-next-line react/jsx-no-constructed-context-values
                  <TrackingFunnelContext.Provider value={{ name: trackingTypes.JOURNEY.AUTH }}>
                    <AuthSetup {...routeProps} />
                  </TrackingFunnelContext.Provider>
                )}
              />
              <Route
                render={() => (
                  <div>
                    {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
                    <TrackingFunnelContext.Provider value={{ name: trackingTypes.FUNNELS.HEADER }}>
                      <Header />
                      <NotFound goBackLink={routes.ABSOLUTE_PATHS.DASHBOARD} goBackText="Back to dashboard" />
                    </TrackingFunnelContext.Provider>
                  </div>
                )}
              />
            </Switch>
          ) : (
            <>
              {/* rendering the header */}
              <Switch>
                <Route exact path="/items/:itemId/status" component={UnauthenticatedHeaderContainer} />
              </Switch>

              <Switch>
                <Route exact path="/workspace-closed" render={() => <WorkspaceDeletedLogoutPage />} />
                {/* This route is opened in a iframe in the Google Sheets addon. */}
                <Route
                  exact
                  path="/one-click-activate-flow"
                  render={(routeProps) => <ActivateFlow {...routeProps} />}
                />
                <Route exact path="/items/:itemId/status" component={WorkItemStatus} />
                <Route
                  exact
                  path="/embed/:embedName/login"
                  render={(routeProps) => (
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    <TrackingFunnelContext.Provider value={{ name: trackingTypes.JOURNEY.SIGNUP }}>
                      <EmbedLoginContainer {...routeProps} />
                    </TrackingFunnelContext.Provider>
                  )}
                />
                <Route
                  path="/:method(login|signup|sso)"
                  render={({ match, ...rest }) => (
                    <Switch>
                      <Route
                        {...rest}
                        exact
                        path={[
                          match.path,
                          `${match.path}/`,
                          `${match.path}/oauth`,
                          `${match.path}/${authTypes.EMAIL_AUTH_STATES.CONFIRM}`,
                          `${match.path}/${authTypes.EMAIL_AUTH_STATES.CONFIRMED}`,
                          `${match.path}/${authTypes.EMAIL_AUTH_STATES.RESEND_CONFIRMATION}`,
                        ]}
                        render={(routeProps) => (
                          <TrackingFunnelContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{ name: trackingTypes.JOURNEY[match.params.method.toUpperCase()] }}
                          >
                            <AuthenticationContainer {...routeProps} />
                          </TrackingFunnelContext.Provider>
                        )}
                      />
                      <Route
                        {...rest}
                        // This is when coming from the login/signup page
                        path={[`${match.path}/:providerName`, `${match.path}/:providerName/`]}
                        render={(routeProps) => (
                          <TrackingFunnelContext.Provider
                            // eslint-disable-next-line react/jsx-no-constructed-context-values
                            value={{ name: trackingTypes.JOURNEY[match.params.method.toUpperCase()] }}
                          >
                            <AuthSetup {...routeProps} />
                          </TrackingFunnelContext.Provider>
                        )}
                      />
                    </Switch>
                  )}
                />
                <Route
                  exact
                  path={['/:action(reset|changePassword)', '/:action(reset|changePassword)/:validationId']}
                  render={(routeProps) => (
                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                    <TrackingFunnelContext.Provider value={{ name: trackingTypes.JOURNEY.LOGIN }}>
                      <ResetPasswordContainer {...routeProps} />
                    </TrackingFunnelContext.Provider>
                  )}
                />
                <Redirect
                  to={{
                    pathname: `/${nonAuthRedirectUrl}`,
                    search: history.location.search,
                    state: { referrer: location },
                  }}
                />
              </Switch>
            </>
          )}
        </div>
      </AbilityContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  ability: getCurrentAbilities(state),
  isAuthenticated: getIsAuthenticated(state),
  userId: getUserId(state),
  signupIntentProduct: getSignupIntentProduct(state),
  signupSource: getSignupSource(state),
});

const mapDispatchToProps = (dispatch, { location }) => ({
  rehydrateAuthState: () => dispatch(authActions.rehydrateAuthState()),
  dispatchSignupEvent: () => dispatch(authActions.dispatchSignupEvent()),
  initialFetch: () => Promise.all([dispatch(organizationActions.getFlags()), dispatch(appActions.getAppConfig())]),
  fetchCapabilitiesFieldTypes: () => dispatch(fieldActions.getCapabilitiesFieldTypes()),
  fetchProviders: () => dispatch(providerActions.getProviders()),
  parseLocation: () => {
    dispatch(appActions.getIsEmbed(location.pathname));

    const queryParams = getSearchParams(location.search);
    dispatch(appActions.getQueryParameters(queryParams));
  },
  disconnectWebSocket: () => dispatch(websocketActions.disconnect()),
});

export const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent));
