import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon, NewTag, NewTagColor, Tooltip } from '@unitoio/mosaic';

import { providerIdentityTypes } from 'consts';
import { AuthWindowOpener } from 'containers';
import { Box } from '~/components/Box/Box';
import { ProviderTerms } from '~/components/ProviderTerms/ProviderTerms';

const LabelWrapper = styled.div`
  display: flex;
`;

// TODO: extract this in ProviderIdentityItem when ProviderIdentityItem is solely used by ToolAndAccountSelect/ProviderIdentitiesSelect.jsx
export function StateLabel({ isProviderIdentityAllowed, providerIdentity }) {
  if (!isProviderIdentityAllowed) {
    return (
      <span className="pull-right">
        <Tooltip
          content={
            <span>
              This account doesn't have access to the{' '}
              <ProviderTerms providerIdA={providerIdentity.get('providerId')} termKey="container" /> you selected.
            </span>
          }
        >
          <NewTag>No access</NewTag>
        </Tooltip>
      </span>
    );
  }

  return (
    <AuthWindowOpener providerId={providerIdentity.get('providerId')} providerIdentityId={providerIdentity.get('_id')}>
      {(openAuthWindow) => (
        <LabelWrapper
          className="pull-right"
          onClick={openAuthWindow}
          onKeyPress={openAuthWindow}
          role="button"
          tabIndex="0"
        >
          <Tooltip
            content={
              <span>
                This account no longer has access to your{' '}
                <ProviderTerms providerIdA={providerIdentity.get('providerId')} termKey="container" />. Please try to{' '}
                reconnect it or select another account.
              </span>
            }
          >
            <NewTag color={NewTagColor.DESTRUCTIVE}>{providerIdentityTypes.STATE.DISABLED}</NewTag>
          </Tooltip>
          <Box $m={[0, 0, 0, 0.5]}>
            <Icon name="rotate" kind={Icon.KINDS.SOLID} title="refresh" />
          </Box>
        </LabelWrapper>
      )}
    </AuthWindowOpener>
  );
}

StateLabel.propTypes = {
  isProviderIdentityAllowed: PropTypes.bool.isRequired,
  providerIdentity: PropTypes.instanceOf(Map).isRequired,
};
